<template>
    <v-row>
        <v-col class="ordinary--text">
            <v-row no-gutters>
                <p class="package-item-title">
                    {{ packageItem.name }}
                </p>
            </v-row>
            <div v-html="packageItem.description" class="description"></div>

            <v-divider class="my-3" />

            <v-row>
                <v-col cols="12" :sm="isBuyable ? 12 : 6">
                    <v-col>
                        <v-col>
                            {{ packageDescription }}
                        </v-col>

                        <v-col v-if="!isBuyable">
                            <p class="mb-0">
                                {{ expiresOn }}<br />
                                {{ purchasedOn }}
                            </p>
                        </v-col>

                        <!-- List of Package Details -->
                        <PackageInfoFormDetail
                            v-for="packageDetail in packageDetails"
                            :key="packageDetail.title"
                            :title="packageDetail.title"
                            :content="packageDetail.content"
                        />

                        <!-- Share Packages -->
                        <v-col
                            v-if="packageItem.isPackageShareable"
                            class="primary--text"
                        >
                            {{
                                $t('message.shareLimit', {
                                    numShareable:
                                        packageItem.numPackageShareable,
                                })
                            }}
                        </v-col>

                        <v-divider
                            v-if="
                                this.packageItem.isPackageShareable &&
                                !this.isBuyable
                            "
                            class="hidden-sm-and-up mt-5"
                        />
                    </v-col>
                </v-col>

                <v-divider
                    v-if="
                        this.packageItem.isPackageShareable && !this.isBuyable
                    "
                    vertical
                    class="hidden-xs-only my-7"
                />

                <v-col
                    v-if="
                        this.packageItem.isPackageShareable && !this.isBuyable
                    "
                >
                    <v-col>
                        <v-row class="mt-1 ml-1">
                            <v-icon large color="primary"
                                >mdi-account-multiple-outline</v-icon
                            >
                            <p class="primary--text mt-3 ml-2">
                                {{ $t('message.sharedWith') }}
                            </p>
                        </v-row>

                        <!-- List of users shared -->
                        <v-col v-if="users.length != 0" class="pa-0">
                            <v-row
                                class="mt-2"
                                v-for="(user, i) in users"
                                :key="i"
                            >
                                <PackageSharedCard
                                    :username="users[i].username"
                                    :name="users[i].fullName"
                                    :numberClassesUsed="users[i].classesLeft"
                                    :numberClassesShared="
                                        users[i].numberClasses
                                    "
                                />
                            </v-row>
                        </v-col>

                        <v-col v-else-if="isLoading">
                            <v-row justify="center">
                                <v-progress-circular
                                    color="primary"
                                    class="mt-2"
                                    indeterminate
                                />
                            </v-row>
                        </v-col>

                        <v-col v-else>
                            <p class="mt-4">
                                {{ $t('message.shareHint') }}
                            </p>
                        </v-col>

                        <!-- Share Package Button -->
                        <v-tooltip bottom :disabled="!disabled">
                            <template v-slot:activator="{ on }">
                                <div
                                    v-on="on"
                                    class="d-inline-block"
                                    :style="{ width: '100%' }"
                                >
                                    <v-btn
                                        color="primary"
                                        class="mt-6"
                                        width="100%"
                                        :disabled="disabled"
                                        @click="openDialog"
                                        >{{ $t('message.sharePackage') }}</v-btn
                                    >
                                </div>
                            </template>
                            <span>{{ reason() }}</span>
                        </v-tooltip>

                        <!-- Share Package Dialog -->
                        <v-dialog v-model="dialog" width="600px">
                            <v-card>
                                <v-card-title>
                                    <v-col class="pb-0">
                                        <v-row class="justify-end">
                                            <v-icon @click="closeDialog"
                                                >mdi-close</v-icon
                                            >
                                        </v-row>
                                    </v-col>
                                </v-card-title>
                                <v-card-text>
                                    <PackageShareWithCard
                                        :key="key"
                                        :packageItem="packageItem"
                                        @sharePackage="sharePackage"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import PackageInfoFormDetail from '@/components/packages/PackageInfoFormDetail'
import PackageSharedCard from '@/components/packages/PackageSharedCard'
import PackageShareWithCard from '@/components/packages/PackageShareWithCard'
import { dateStringFromTimestamp } from '@/util/dateformat'
import { SHARE_PACKAGE } from '@/analytics/events'
import { getSharedMembers } from '../../util/packages/getSharedMembers'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import { mapGetters } from 'vuex'

export default {
    components: {
        PackageInfoFormDetail,
        PackageSharedCard,
        PackageShareWithCard,
    },
    props: {
        packageItem: Object,
        isBuyable: Boolean,
    },
    data() {
        return {
            key: 0,
            disabled:
                this.packageItem.classesLeft === 0 ||
                this.packageItem.numPackageShareable === 0,
            isLoading: true,
            dialog: false,
            users: [],
        }
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        renewDurationType() {
            const packageItem = this.packageItem
            const recurring = packageItem.renewDurationType
            if (!recurring) {
                return ''
            }
            const renewalDurationTypeLen = recurring.length
            const lastRenewalDurationLetter =
                recurring[renewalDurationTypeLen - 1]
            if (lastRenewalDurationLetter === 's') {
                return recurring.substring(0, renewalDurationTypeLen - 1)
            } else {
                return recurring
            }
        },
        packageDescription() {
            const packageItem = this.packageItem
            const recurring = packageItem.renewDuration
            if (this.isBuyable) {
                const criteriaMessage =
                    packageItem.expiryImmediateCriterion === 'expiry-immediate'
                        ? this.$t('message.purchase')
                        : this.$t('message.firstClass')
                if (recurring) {
                    const numCycles = packageItem.numCycles
                    const hasCycles = packageItem.numCycles !== undefined
                    const renewalDuration = `${packageItem.renewDuration} ${this.renewDurationType}`
                    const stringValue = hasCycles
                        ? this.$t('message.packageRenewWithCycles', {
                              renewalDuration: renewalDuration,
                              numCycles: numCycles,
                              renewDurationType: this.renewDurationType,
                          })
                        : this.$t('message.packageRenewWithoutCycles', {
                              renewalDuration: renewalDuration,
                          })
                    return `${packageItem.classNumbers} ${this.$t(
                        'message.credits'
                    )} - ${stringValue}`
                } else {
                    return `${packageItem.classNumbers} ${this.$t(
                        'message.credits'
                    )} - ${this.$t('message.packageValidFor', {
                        duration:
                            packageItem.durationOriginal ??
                            packageItem.duration,
                        durationType: packageItem.durationType,
                        criteriaMessage: criteriaMessage,
                    })}`
                }
            }
            return `${packageItem.classesLeft} / ${
                packageItem.numberClasses
            } ${this.$t('message.classesLeft')}`
        },
        expiresOn() {
            const packageItem = this.packageItem
            if (this.isBuyable) {
                return ''
            }
            return this.$t('message.expiresOnWithDate', {
                date: dateStringFromTimestamp(
                    packageItem.expiresOn,
                    this.studio.timezone
                ),
            })
        },
        purchasedOn() {
            const packageItem = this.packageItem
            if (this.isBuyable) {
                return ''
            }
            return this.$t('message.purchasedOnWithDate', {
                date: dateStringFromTimestamp(
                    packageItem.datePurchased,
                    this.studio.timezone
                ),
            })
        },
        classRestrictionsKey() {
            return 'classes'
        },
        locationRestrictionsKey() {
            return 'locations'
        },
        dayRestrictionsKey() {
            return 'days'
        },
        classesRestrictions() {
            return this.parseRestrictions(
                'hasClassRestriction',
                this.classRestrictionsKey,
                this.$t('message.classesAll')
            )
        },
        locationsRestrictions() {
            return this.parseRestrictions(
                'hasLocationRestriction',
                this.locationRestrictionsKey,
                this.$t('message.locationsAll')
            )
        },
        daysRestriction() {
            return this.parseRestrictions(
                'hasTimeRange',
                this.dayRestrictionsKey,
                this.$t('message.daysAll')
            )
        },
        timingsRestriction() {
            const packageItem = this.packageItem
            if (packageItem.hasTimeRange) {
                return `${packageItem.startTime} - ${packageItem.endTime}`
            }
            return this.$t('message.timeAll')
        },
        packageDetails() {
            return [
                {
                    title: this.$t('message.classesEligible'),
                    content: this.classesRestrictions,
                },
                {
                    title: this.$t('message.locationsEligible'),
                    content: this.locationsRestrictions,
                },
                {
                    title: this.$t('message.daysEligible'),
                    content: this.daysRestriction,
                },
                {
                    title: this.$t('message.timeEligible'),
                    content: this.timingsRestriction,
                },
            ]
        },
    },
    async created() {
        const packageItem = this.packageItem
        this.users = await getSharedMembers(packageItem.id)
        for (const user of this.users) {
            if (user.userId === packageItem.userId) {
                user.username = user.username + ' (Owner)'
            }
        }
    },
    methods: {
        reason() {
            if (
                this.packageItem.classesLeft === 0 &&
                this.packageItem.numPackageShareable === 0
            ) {
                return this.$t('message.shareLimitReachedAndNoClassesLeft')
            } else if (this.packageItem.classesLeft === 0) {
                return this.$t('message.noClassesLeftToShare')
            } else if (this.packageItem.numPackageShareable === 0) {
                return this.$t('message.shareLimitReached')
            } else {
                return ''
            }
        },
        parseRestrictions(existFieldName, fieldName, defaultReturn) {
            const packageItem = this.packageItem
            if (
                packageItem[existFieldName] &&
                Array.isArray(packageItem[fieldName])
            ) {
                return packageItem[fieldName].join(', ')
            }
            return defaultReturn
        },
        closeDialog() {
            this.dialog = false
            this.key += 1
        },
        openDialog() {
            this.$gtag.event(SHARE_PACKAGE)
            this.dialog = true
        },
        async sharePackage() {
            this.$emit('sharePackage')
            this.users = await getSharedMembers(this.packageItem.id)
            for (const user of this.users) {
                if (user.userId === this.packageItem.userId) {
                    user.username = user.username + ' (Owner)'
                }
            }
            this.isLoading = false
        },
    },
}
</script>

<style lang="scss">
.description {
    img {
        max-width: 100%;
        height: auto;
    }
}
</style>
