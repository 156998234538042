<template>
    <v-col>
        <v-row justify="center">
            <v-btn-toggle
                v-model="selectedPackageType"
                color="primary"
                group
                mandatory
                class="mb-8"
            >
                <v-btn value="oneTime">{{ $t('message.packages') }}</v-btn>
                <v-btn value="recurring"
                    >{{ $t('message.packagesRecurring') }}
                    <v-tooltip v-model="tooltip" top class="ml-8">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                @click="tooltip = !tooltip"
                                >mdi-help-circle-outline</v-icon
                            >
                        </template>
                        <span>{{
                            $t('message.packagesRecurringDescription')
                        }}</span>
                    </v-tooltip>
                </v-btn>
            </v-btn-toggle>
        </v-row>
        <v-col v-if="selectedPackageType === 'oneTime'">
            <v-row class="text--secondary" v-if="packages.length === 0">
                {{ $t('message.packagesNotAvailable') }}
            </v-row>
            <PackageTab
                key="oneTimeTab"
                :isBuyable="isBuyable"
                :categories="categories"
                :packages="packages"
                @sharePackage="sharePackage"
            />
        </v-col>

        <v-col v-else>
            <v-row
                class="text--secondary"
                v-if="recurringPackages.length === 0"
            >
                {{ $t('message.packagesRecurringNotAvailable') }}
            </v-row>
            <PackageTab
                key="recurringTab"
                :isBuyable="isBuyable"
                :categories="categories"
                :packages="recurringPackages"
            />
        </v-col>
    </v-col>
</template>
<script>
import { mapGetters } from 'vuex'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import PackageTab from '@/components/packages/PackageTab'
import { getPackageCategories } from '@/util/cloudFunctions/packagesFunctions'
export default {
    components: {
        PackageTab,
    },
    props: {
        packages: Array,
        recurringPackages: Array,
        isBuyable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            selectedPackageType: 'oneTime',
            tooltip: false,
            categories: [],
        }
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
    },
    methods: {
        sharePackage() {
            this.$emit('sharePackage')
        },
        cancelStatus(isSuccess, error) {
            this.$emit('cancelStatus', isSuccess, error)
        },
    },
    async created() {
        this.categories = await getPackageCategories(this.studio.id)
    },
}
</script>
