<template>
    <div>
        <!-- Login Button -->
        <v-btn
            :block="block"
            class="auth-btn"
            color="primary"
            v-show="!hideButton"
            @click="openDialog"
        >
            {{ $t('message.login') }}
        </v-btn>

        <v-dialog v-model="dialog" width="600px">
            <!-- Login Form -->
            <v-card>
                <v-card-title>
                    <v-col>
                        <v-row class="justify-end">
                            <v-icon @click="closeDialog">mdi-close</v-icon>
                        </v-row>
                        <v-row class="justify-center">
                            <h4 class="ml-2">{{ $t('message.login') }}</h4>
                        </v-row>
                    </v-col>
                </v-card-title>
                <v-card-text>
                    <LoginForm
                        :key="loginFormKey"
                        @success="closeDialog"
                        @signUp="navigateToSignUpPage"
                    />
                </v-card-text>
            </v-card>

            <!-- Sign Up -->
            <SignUpButton ref="SignUp" :hideButton="true" />
        </v-dialog>
    </div>
</template>

<script>
import LoginForm from '@/components/auth/LoginForm'
import SignUpButton from '@/components/auth/SignUpButton'
export default {
    props: ['block', 'hideButton'],
    components: {
        LoginForm,
        SignUpButton,
    },
    data() {
        return {
            dialog: false,
            loginFormKey: false,
            signUpFormKey: false,
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false
            this.toggleKeys()
        },
        openDialog() {
            this.$emit('openDialog')
            this.dialog = true
        },
        navigateToSignUpPage() {
            this.dialog = false
            this.$refs.SignUp.navigateToSignUpPage()
        },
        toggleKeys() {
            this.loginFormKey = !this.loginFormKey
        },
    },
}
</script>
