<template>
    <Timetable
        :classes="classes"
        :value="date"
        :isFetchingData="isFetchingData"
        :errorInFetching="errorInFetching"
        @input="onDateChange"
    />
</template>
<script>
import Timetable from '@/components/browseclasses/timetable/Timetable.vue'
import { groupClassesByDate } from '@/util/classes/classFormat'
import { getClassesFromStudioId } from '@/util/cloudFunctions/classesFunctions'
import { getDaysAfterDateString } from '@/util/datetimehelpers'

export default {
    components: {
        Timetable,
    },
    props: {
        studio: Object,
        classFilter: Array,
        instructorFilter: Array,
        locationFilter: Array,
        date: String,
    },
    data() {
        return {
            classes: [],
            isFetchingData: true,
            errorInFetching: false,
        }
    },
    async created() {
        await this.setClasses()
    },
    watch: {
        classFilter: {
            handler: async function () {
                await this.setClasses()
            },
            deep: true,
        },
        instructorFilter: {
            handler: async function () {
                await this.setClasses()
            },
            deep: true,
        },
        locationFilter: {
            handler: async function () {
                await this.setClasses()
            },
            deep: true,
        },
        date: {
            handler: async function () {
                await this.setClasses()
            },
        },
    },
    methods: {
        async setClasses() {
            this.isFetchingData = true
            const endDate = getDaysAfterDateString(
                this.date,
                this.studio.timezone,
                7
            )
            const input = {
                studioId: this.studio.id,
                classFilter: this.classFilter,
                instructorFilter: this.instructorFilter,
                locationFilter: this.locationFilter,
                startDate: this.date,
                endDate,
            }
            const result = await getClassesFromStudioId(input)
            if (!result.success) {
                this.errorInFetching = true
                this.isFetchingData = false
                return
            }
            this.classes = groupClassesByDate(result.docs, this.studio.timezone)
            this.isFetchingData = false
        },
        onDateChange(date) {
            this.$emit('update:date', date)
        },
    },
}
</script>
