<template>
    <div>
        <v-container class="d-flex flex-wrap justify-center flex-column">
            <v-row justify="center" align-content="center" align-items="center">
                <v-col cols="12" class="py-0">
                    <div class="numCreditsContainer">
                        <span class="text-h5">
                            {{
                                $t('message.numCredits', {
                                    numCredits: numCredits,
                                })
                            }}
                        </span>
                        <span class="font-weight-light">
                            {{ $t('message.perSpot') }}
                        </span>
                    </div>
                </v-col>

                <v-row class="mx-0 mt-3">
                    <v-col cols="12" :md="hideSpots ? '6' : '12'">
                        <v-select
                            :label="$t('message.choosePackage')"
                            :items="allMemberships"
                            item-text="name"
                            item-value="id"
                            return-object
                            v-model="selectedPackage"
                            required
                            attach
                            @input="onSelectPackage"
                            solo
                        >
                            <template v-slot:item="data">
                                <div class="custom-selection">
                                    {{ parsePackageName(data.item) }}
                                </div>
                            </template>
                            <template v-slot:selection="data">
                                <div class="custom-selection">
                                    {{ data.item.name }}
                                </div>
                            </template>
                        </v-select>
                        <h4 class="mb-5" v-if="packageHint">
                            {{ packageHint }}
                        </h4>
                    </v-col>
                    <v-col
                        cols="12"
                        :md="hideSpots ? '6' : '12'"
                        v-if="!isWaitlistClass && hideSpots && maxBookings > 1"
                    >
                        <div class="d-flex flex-row align-center">
                            <v-select
                                v-model="numSpots"
                                @input="onSelectNumSpots"
                                :items="numSpotsOptions"
                                solo
                                :style="{ maxWidth: '100px' }"
                            >
                                <template slot="selection" slot-scope="data">
                                    <v-icon class="mr-2"> mdi-account </v-icon>
                                    {{ data.item }}
                                </template>
                            </v-select>
                        </div>
                    </v-col>
                </v-row>
            </v-row>

            <v-btn
                text
                v-if="shouldShowBookClassBtn"
                x-large
                class="primary btn-style"
                @click="submit"
                :loading="loadButton"
                >{{ $t('message.bookClass') }}</v-btn
            >
            <v-btn
                text
                v-if="shouldShowAddToWaitlistBtn"
                x-large
                class="secondary btn-style ordinary--text"
                @click="waitlist"
                :loading="loadButton"
                >{{ $t('message.addToWaitlist') }}</v-btn
            >
            <v-btn
                text
                v-if="classIsDisabled"
                class="btn-style-disabled"
                disabled
                :loading="loadButton"
                >{{ $t('message.bookClass') }}</v-btn
            >
            <h5
                v-if="classIsDisabled && isUserAuth"
                class="refund-text text--secondary"
            >
                {{ reason }}
            </h5>
            <h5
                v-if="isUserAuth && (classIsWaitlist || classIsBookable)"
                class="refund-text text--secondary"
            >
                {{ refundMessage }}
            </h5>
            <p
                v-if="!isUserAuth"
                class="login-text font-weight-bold text--secondary text-decoration-underline"
                @click="login"
            >
                {{ $t('message.loginToPurchase') }}
            </p>
        </v-container>
        <Login ref="Login" :hideButton="true" />
    </div>
</template>

<script>
import Login from '@/components/auth/Login'
import {
    dateStringFromTimestamp,
    timeStringFromTimestamp,
} from '@/util/dateformat.js'
import {
    STUDIO_MODULE_NAME,
    AUTHENTICATION_MODULE_NAME,
} from '@/store/moduleNames'
import { mapGetters } from 'vuex'

// Analytics
import { SELECT_PACKAGE } from '@/analytics/events'

export default {
    components: {
        Login,
    },
    props: {
        classObject: Object,
        isBookable: Boolean,
        reason: String,
        normalMemberships: Array,
        recurringMemberships: Array,
        myMemberships: Array,
        maxBookings: Number,
        isWaitlistClass: Boolean,
        loadButton: Boolean, // to show loader when fetching data
        isClassCancellable: Boolean,
    },
    data() {
        return {
            selectedPackage: null,
            numSpots: 1,
            error: '',
        }
    },
    computed: {
        numCredits() {
            return this.classObject.numCredits ?? 1
        },
        hideSpots() {
            return this.classObject.hideSpots === true
        },
        numSpotsOptions() {
            const maxBookings = this.maxBookings ?? 1
            return Array.from({ length: maxBookings }, (_, index) => index + 1)
        },
        ...mapGetters({
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        //Purple button with "Book Class"
        classIsBookable() {
            return this.isBookable
        },
        //Yellow button with "Waitlist Class"
        classIsWaitlist() {
            return !this.isBookable && this.reason === 'Class is full'
        },
        classIsDisabled() {
            return (
                !this.isUserAuth ||
                (!this.isBookable && this.reason !== 'Class is full')
            )
        },
        shouldShowBookClassBtn() {
            return this.isUserAuth && this.classIsBookable
        },
        shouldShowAddToWaitlistBtn() {
            return (
                this.isUserAuth &&
                this.classIsWaitlist &&
                !this.isClassCancellable // means user already booked a spot or more
            )
        },
        allMemberships() {
            const memberships = []
            if (this.isUserAuth && this.myMemberships.length > 0) {
                memberships.push(
                    { header: this.$t('message.myPackages') },
                    ...this.myMemberships
                )
            }
            if (this.normalMemberships.length > 0) {
                memberships.push(
                    { header: this.$t('message.packages') },
                    ...this.normalMemberships
                )
            }
            if (this.recurringMemberships.length > 0) {
                memberships.push(
                    { header: this.$t('message.packagesRecurring') },
                    ...this.recurringMemberships
                )
            }
            return memberships
        },
        packageHint() {
            const packageItem = this.selectedPackage

            if (!packageItem) {
                return ''
            }

            if (this.isMyPackage(packageItem)) {
                return this.$t('message.classesRemaining', {
                    classesLeft: packageItem.classesLeft,
                    numberClasses: packageItem.numberClasses,
                })
            }
            const currency = this.studio.currency ?? '$'
            return this.$t('message.packagePurchaseMessage', {
                price: packageItem.price,
                currency: currency,
            })
        },
        refundMessage() {
            return this.$t('message.refundMessage', {
                refundPolicy: this.studio.refundPolicy,
            })
        },
        dateClass() {
            return dateStringFromTimestamp(
                this.classObject.startTimestamp,
                this.studio.timezone
            )
        },
        startTimeClass() {
            return timeStringFromTimestamp(
                this.classObject.startTimestamp,
                this.studio.timezone
            )
        },
        endTimeClass() {
            return timeStringFromTimestamp(
                this.classObject.endTimestamp,
                this.studio.timezone
            )
        },
    },
    watch: {
        isUserAuth: {
            handler() {
                if (this.isMyPackage(this.selectedPackage)) {
                    this.selectedPackage = null
                }
                this.onSelectPackage()
            },
        },
    },
    methods: {
        submit() {
            const isWaitlist = false
            this.$emit('click', isWaitlist)
        },
        waitlist() {
            const isWaitlist = true
            this.$emit('click', isWaitlist)
        },
        isMyPackage(packageItem) {
            return this.myMemberships.includes(packageItem)
        },
        onSelectPackage() {
            const isMyPackage = this.isMyPackage(this.selectedPackage)
            this.$gtag.event(SELECT_PACKAGE)
            this.$emit('input', this.selectedPackage, isMyPackage)
        },
        onSelectNumSpots() {
            this.$emit('onNumSpots', this.numSpots)
        },
        parsePackageName(membership) {
            const { name, expiresOn, classesLeft, numberClasses } = membership
            if (
                expiresOn &&
                classesLeft !== undefined &&
                numberClasses !== undefined
            ) {
                // in case classesLeft or numberClasses is 0
                const dateStr = dateStringFromTimestamp(
                    expiresOn,
                    this.studio.timezone
                )
                return this.$t('message.packageExpiryWithCount', {
                    name: name,
                    dateStr: dateStr,
                    classesLeft: classesLeft,
                    numberClasses: numberClasses,
                })
            }
            return name
        },
        login() {
            this.$refs.Login.openDialog()
        },
    },
}
</script>

<style scoped>
.custom-selection {
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
    overflow-wrap: break-word;
}

.btn-style {
    width: inherit;
    height: 80px !important;
    margin-bottom: 10px;
    margin-top: 10px;
}

.btn-style-disabled {
    width: inherit;
    height: 80px !important;
    margin-bottom: 10px;
    margin-top: 10px;
    border: 1px solid rgb(196, 195, 195);
}

.refund-text {
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    margin-top: 10px;
    /* or 167% */

    display: flex;
    align-items: center;
}

.login-text {
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;

    display: flex;
    align-items: center;
    cursor: pointer;
}

.date-time {
    font-size: 18px;
}

.cost-message {
    margin-top: -20px;
}
</style>
