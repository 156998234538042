<template>
    <v-btn
        :block="block"
        :text="text"
        :loading="loading"
        color="primary"
        v-show="!hideButton"
        @click="signOut"
    >
        {{ $t('message.logout') }}
    </v-btn>
</template>
<script>
import { auth, signOut } from '@/firebase'
import { mapMutations } from 'vuex'
import { ALERT_MODULE_NAME } from '@/store/moduleNames'
export default {
    props: ['block', 'hideButton', 'text'],
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        ...mapMutations({
            clearAlertState: `${ALERT_MODULE_NAME}/clearAlertState`,
        }),
        async signOut() {
            this.loading = true
            await signOut(auth)
            this.clearAlertState()
            this.$emit('success')
        },
    },
}
</script>
