<template>
    <div>
        <v-container class="d-flex flex-wrap justify-center flex-column">
            <v-expand-transition>
                <!-- For cancelling booked spots  -->
                <CancelBooking
                    v-if="isClassCancellable"
                    largeBtnStyle
                    :subItems="userBookedSpots"
                    :isSpotsHidden="hideSpots"
                    @success="() => $emit('success')"
                    @cancelFeePaymentError="
                        (err) => $emit('cancelFeePaymentError', err)
                    "
                    :loadingData="loadButton"
                />

                <!-- For removing from waitlist -->
                <RemoveClass
                    v-if="isWaitlistCancellable"
                    largeBtnStyle
                    :subItem="userWaitlist"
                    @success="() => $emit('success')"
                    :loadingData="loadButton"
                />
            </v-expand-transition>
        </v-container>
    </div>
</template>

<script>
import CancelBooking from '../myclasses/CancelBooking.vue'
import RemoveClass from '../myclasses/RemoveClass.vue'

export default {
    components: {
        CancelBooking,
        RemoveClass,
    },
    props: {
        hideSpots: Boolean,
        loadButton: Boolean,
        userBookedSpots: Array,
        userWaitlist: Object,
        isClassCancellable: Boolean,
        isWaitlistCancellable: Boolean,
    },
}
</script>
