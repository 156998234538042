export const mutationsAuth = {
    setLoading(state, payload) {
        state.loading = payload
    },
    setBrandedApp(state, payload) {
        state.isBrandedApp = payload
    },
    setUser(state, payload) {
        state.user = payload
    },
    setCollectUserDetails(state, payload) {
        state.collectUserDetails = payload
    },
}
