import momentTimezone from 'moment-timezone'

function convertTimestampToDate(timestamp) {
    return new Date(timestamp._seconds * 1000)
}

function dateToString(date) {
    date.setTime(date.getTime() - date.getTimezoneOffset() * 60000)
    return date.toISOString().split('T')[0]
}

function dateToStringWithTimezone(date, timezone) {
    return momentTimezone(date).tz(timezone).format('YYYY-MM-DD')
}

function getNextDay(date) {
    return momentTimezone(date).add(1, 'days').format('YYYY-MM-DD')
}

/**
 * Gets the day "Monday", "Tuesday", etc from the timestamp, in the timezone provided.
 * @param {Object} timestamp
 * @param {String} timezone
 * @returns
 */
function getDayOfDate(timestamp, timezone) {
    const date = convertTimestampToDate(timestamp)
    return momentTimezone(date).tz(timezone).format('dddd')
}

/**
 * Checks if the timestamp is between the start and end time in the given timezone.
 * @param {Object} timestamp
 * @param {String} startTime time in HH:mm format, so can be 06:00 and 23:00
 * @param {String} endTime time in HH:mm format, so can be 06:00 and 23:00
 * @param {String} timezone
 * @returns
 */
function isTimestampBetweenTime(timestamp, startTime, endTime, timezone) {
    const date = convertTimestampToDate(timestamp)
    const momentDate = momentTimezone(date).tz(timezone)
    const splitStartTime = startTime.split(':')
    const splitEndTime = endTime.split(':')
    const startDate = momentDate
        .clone()
        .set({ h: splitStartTime[0], m: splitStartTime[1] })
    const endDate = momentDate
        .clone()
        .set({ h: splitEndTime[0], m: splitEndTime[1] })
    return (
        momentDate.isSameOrAfter(startDate) &&
        momentDate.isSameOrBefore(endDate)
    )
}

/**
 * Returns true if date is before now, false otherwise.
 * @param {Date} date
 */
function isBeforeNow(date) {
    return date < new Date()
}

function isDate1BeforeOrEqualDate2(date1, date2) {
    return new Date(date1) <= new Date(date2)
}

function getDaysAfterDateString(date, timezone, days) {
    return momentTimezone(date)
        .tz(timezone)
        .add(days, 'days')
        .format('YYYY-MM-DD')
}

export {
    getDayOfDate,
    isTimestampBetweenTime,
    dateToString,
    dateToStringWithTimezone,
    getNextDay,
    isBeforeNow,
    isDate1BeforeOrEqualDate2,
    getDaysAfterDateString,
}
