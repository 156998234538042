// Purchase
const PURCHASE = 'purchase'
const PURCHASE_ERROR = 'purchase_error'
const WEBAPP_PURCHASE = 'webapp_purchase'
const WEBAPP_PURCHASE_ERROR = 'webapp_purchase_error'

// Purchase course

const PURCHASE_COURSE = 'purchase_course'
const WEBAPP_PURCHASE_COURSE = 'webapp_purchase_course'
const PURCHASE_COURSE_ERROR = 'purchase_course_error'
const WEBAPP_PURCHASE_COURSE_ERROR = 'webapp_purchase_course_error'

// Purchase
const PURCHASE_MERCHANDISE = 'purchase_merchandise'
const PURCHASE_MERCHANDISE_ERROR = 'purchase_merchandise_error'
const WEBAPP_PURCHASE_MERCHANDISE = 'webapp_purchase_merchandise'
const WEBAPP_PURCHASE_MERCHANDISE_ERROR = 'webapp_purchase_merchandise_error'

// Book Classes
const BOOK_CLASS = 'book_class'
const BOOK_CLASS_ERROR = 'book_class_error'
const WEBAPP_BOOK_CLASS = 'webapp_book_class'
const WEBAPP_BOOK_CLASS_ERROR = 'webapp_book_class_error'

// Waitlist Classes
const WAITLIST_CLASS = 'waitlist_class'
const WAITLIST_CLASS_ERROR = 'waitlist_class_error'
const WEBAPP_WAITLIST_CLASS = 'webapp_waitlist_class'
const WEBAPP_WAITLIST_CLASS_ERROR = 'webapp_waitlist_class_error'

// General
const LOGIN = 'webapp_login'
const LOGIN_ERROR = 'webapp_login_error'
const FACEBOOK_LOGIN = 'webapp_facebook_login'
const FACEBOOK_LOGIN_ERROR = 'webapp_login_error'
const SIGNUP = 'webapp_signup'
const SIGNUP_ERROR = 'webapp_signup_error'
const SIGNUP_FACEBOOK = 'webapp_signup_facebook'
const SIGNUP_FACEBOOK_ERROR = 'webapp_signup_facebook_error'
const CHANGE_PASSWORD = 'webapp_change_password'
const CHANGE_PASSWORD_ERROR = 'webapp_change_password_error'
const FORGET_PASSWORD = 'webapp_forget_password'
const FORGET_PASSWORD_ERROR = 'webapp_forget_password_error'

// Footer
const CLICK_QR_CODE = 'webapp_click_qr_code'
const CLICK_VIBEFAM_LINK = 'webapp_click_vibefam_link'
const CLICK_FACEBOOK_LINK = 'webapp_click_facebook_link'
const CLICK_INSTAGRAM_LINK = 'webapp_click_instagram_link'
const CLICK_YOUTUBE_LINK = 'webapp_click_youtube_link'
const CLICK_LINKEDIN_LINK = 'webapp_click_linkedin_link'

// Home
const CLICK_STUDIO_CARD = 'webapp_click_studio_card'

// Classes
const CLICK_NAVIGATE_TO_CLASS = 'webapp_click_navigate_to_class'

// Checkout
const BEGIN_CHECKOUT = 'webapp_begin_checkout'
const CANCEL_CHECKOUT = 'webapp_cancel_checkout'
const FINISH_CHECKOUT = 'webapp_cancel_checkout'

// Book Class
const SELECT_SPOT = 'webapp_select_spot'
const SELECT_PACKAGE = 'webapp_select_package'

// Buy Package
const CLICK_VIEW_MORE = 'webapp_click_view_more'

// Share Package
const SHARE_PACKAGE = 'webapp_share_package'

// My Classes
const CLICK_UPCOMING_CLASSES = 'webapp_click_upcoming_classes'
const CLICK_WAITLIST = 'webapp_click_waitlist'
const CLICK_PAST_CLASSES = 'webapp_click_past_classes'
const CANCEL_CLASS = 'webapp_cancel_class'
const CANCEL_CLASS_ERROR = 'webapp_cancel_class_error'
const CANCEL_WAITLIST = 'webapp_cancel_waitlist'
const CANCEL_WAITLIST_ERROR = 'webapp_cancel_waitlist_error'

// Setup Payment Method
const SETUP_SUCCESS = 'setup_success'

// Click Vibefam Logo
const CLICK_VIBEFAM_LOGO = 'click_vibefam_logo'

// Click Powered by Vibefam
const CLICK_POWERED_BY_VIBEFAM = 'click_powered_by_vibefam'

export {
    LOGIN,
    LOGIN_ERROR,
    FACEBOOK_LOGIN,
    FACEBOOK_LOGIN_ERROR,
    SIGNUP,
    SIGNUP_ERROR,
    SIGNUP_FACEBOOK,
    SIGNUP_FACEBOOK_ERROR,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_ERROR,
    FORGET_PASSWORD,
    FORGET_PASSWORD_ERROR,
    CLICK_QR_CODE,
    CLICK_VIBEFAM_LINK,
    CLICK_FACEBOOK_LINK,
    CLICK_INSTAGRAM_LINK,
    CLICK_YOUTUBE_LINK,
    CLICK_LINKEDIN_LINK,
    CLICK_STUDIO_CARD,
    CLICK_NAVIGATE_TO_CLASS,
    BEGIN_CHECKOUT,
    CANCEL_CHECKOUT,
    FINISH_CHECKOUT,
    PURCHASE,
    PURCHASE_COURSE,
    WEBAPP_PURCHASE_COURSE,
    PURCHASE_ERROR,
    PURCHASE_COURSE_ERROR,
    WEBAPP_PURCHASE_COURSE_ERROR,
    BOOK_CLASS,
    BOOK_CLASS_ERROR,
    WAITLIST_CLASS,
    WAITLIST_CLASS_ERROR,
    SELECT_SPOT,
    SELECT_PACKAGE,
    CLICK_VIEW_MORE,
    SHARE_PACKAGE,
    CLICK_UPCOMING_CLASSES,
    CLICK_WAITLIST,
    CLICK_PAST_CLASSES,
    CANCEL_CLASS,
    CANCEL_CLASS_ERROR,
    CANCEL_WAITLIST,
    CANCEL_WAITLIST_ERROR,
    WEBAPP_PURCHASE,
    WEBAPP_PURCHASE_ERROR,
    WEBAPP_BOOK_CLASS,
    WEBAPP_BOOK_CLASS_ERROR,
    WEBAPP_WAITLIST_CLASS,
    WEBAPP_WAITLIST_CLASS_ERROR,
    PURCHASE_MERCHANDISE,
    PURCHASE_MERCHANDISE_ERROR,
    WEBAPP_PURCHASE_MERCHANDISE,
    WEBAPP_PURCHASE_MERCHANDISE_ERROR,
    SETUP_SUCCESS,
    CLICK_VIBEFAM_LOGO,
    CLICK_POWERED_BY_VIBEFAM,
}
