import { functions, httpsCallable } from '@/firebase'

export function getStudioCustomField(studioId) {
    const getStudioCustomFieldByMember = httpsCallable(
        functions,
        'getStudioCustomFieldByMember'
    )
    return getStudioCustomFieldByMember({ studioId })
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

export function getStudioMemberCustomField({ userId, studioId }) {
    const getStudioMemberCustomField = httpsCallable(
        functions,
        'getStudioMemberCustomField'
    )
    return getStudioMemberCustomField({ userId, studioId })
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

export function editStudioMemberCustomField({
    userId,
    studioId,
    memberCustomData,
}) {
    const editStudioMemberCustomField = httpsCallable(
        functions,
        'editStudioMemberCustomField'
    )

    return editStudioMemberCustomField({
        userId,
        studioId,
        memberCustomData,
    })
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}
