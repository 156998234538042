<template>
    <v-dialog
        v-model="value"
        hide-overlay
        width="500px"
        @click:outside="$emit('input', false)"
    >
        <img src="@/assets/vibefam-app-qrcode.svg" />
    </v-dialog>
</template>
<script>
export default {
    props: ['value'],
}
</script>
