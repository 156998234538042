import { db } from '@/firebase'
import { collection, query, getDocs, where } from 'firebase/firestore'

export async function getSharedMembers(packageId) {
    if (!packageId) return []
    const q = query(
        collection(db, 'studio_memberships'),
        where('originalPackage', '==', packageId)
    )
    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map((doc) => doc.data())
}
