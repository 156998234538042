<template>
    <QuestionTemplate
        :question="question"
        :description="description"
        :isRequired="isRequired"
    >
        <ValidationProvider
            :name="question"
            :rules="validateRules"
            :customMessages="{ required: customMessages }"
            v-slot="{ errors }"
        >
            <v-select
                v-if="fieldEditable"
                :background-color="backgroundColor"
                dense
                outlined
                :light="light"
                :placeholder="$t('message.selectOne')"
                :items="computedItems"
                :error-messages="errors"
                :value="parsedExistingValue"
                @change="(value) => $emit('onValueChanged', value)"
            />

            <v-textarea
                v-else
                :background-color="backgroundColor"
                dense
                auto-grow
                rows="1"
                disabled
                :value="parsedExistingValue"
                :hint="hint"
                :persistent-hint="isPersistentHint"
            ></v-textarea>
        </ValidationProvider>
    </QuestionTemplate>
</template>

<script>
import QuestionTemplate from './QuestionTemplate.vue'
import { ValidationProvider } from 'vee-validate'
import i18n from '@/i18n'
import { REQUIRED_FIELD_EMPTY_COLOR } from './CustomFieldsUtils'

export default {
    props: {
        question: String,
        description: String,
        fieldEditable: Boolean,
        isEditable: Boolean,
        isRequired: Boolean,
        options: Array,
        existingValue: {
            type: String,
            default: () => '',
        },
        showRedBackground: { type: Boolean, default: true },
    },
    components: {
        ValidationProvider,
        QuestionTemplate,
    },
    data() {
        return {
            customMessages: i18n.t('message.thisFieldNoEmpty'),
        }
    },
    computed: {
        computedItems() {
            return this.options.map((item) => item.value)
        },
        validateRules() {
            return this.isRequired ? 'required' : ''
        },
        backgroundColor() {
            if (
                this.fieldEditable &&
                this.parsedExistingValue === '' &&
                this.isRequired &&
                this.showRedBackground
            ) {
                return REQUIRED_FIELD_EMPTY_COLOR // red background for required fields that are empty
            }
            return ''
        },
        hint() {
            return !this.fieldEditable && !this.isEditable
                ? i18n.t(`message.fieldCannotBeChanged`)
                : ''
        },
        isPersistentHint() {
            return !this.fieldEditable && !this.isEditable
        },
        parsedExistingValue() {
            // check if existing value is one of the options
            const isValueInOptions = this.options.some(
                (option) => option.value === this.existingValue
            )
            if (isValueInOptions) {
                return this.existingValue
            }

            // If not in edit mode, show old valuex
            if (!this.fieldEditable) {
                return this.existingValue
            }

            return ''
        },
        light() {
            return this.backgroundColor === 'red lighten-4'
        },
    },
}
</script>

<style scoped>
.question-text {
    white-space: normal;
    word-break: break-word;
}
</style>
