<template>
    <v-list-item>
        <v-col>
            <v-row align="center">
                <v-list-item-avatar>
                    <img
                        :src="getCardIcon(paymentMethod.brand)"
                        data-testid="card-icon"
                    />
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title
                        class="text-wrap"
                        data-testid="card-brand-and-last4"
                        >{{ paymentMethod.brand.toUpperCase() }} ....{{
                            paymentMethod.last4
                        }}</v-list-item-title
                    >
                    <v-list-item-subtitle
                        class="text-wrap"
                        data-testid="card-expiration"
                        >{{ $t('message.expiration') }}:
                        {{ paymentMethod.expMonth }}/{{
                            paymentMethod.expYear
                        }}</v-list-item-subtitle
                    >
                </v-list-item-content>

                <v-list-item-action
                    v-if="paymentMethodIdForPackage !== null"
                    class="ml-1"
                >
                    <v-chip
                        v-if="showCurrentChip"
                        color="success"
                        data-testid="current-chip"
                    >
                        {{ $t('message.current') }}
                    </v-chip>

                    <v-btn
                        v-else-if="showSelectButton"
                        color="primary"
                        @click="onUpdatePaymentMethod"
                        :disabled="statusObj.loading"
                        :loading="loadingSelectButton"
                        data-testid="select-button"
                    >
                        {{ $t('message.select') }}
                    </v-btn>

                    <v-btn
                        v-else-if="showCustomButton"
                        :color="buttonProps.color"
                        :outlined="buttonProps.outlined"
                        :small="buttonProps.small"
                        @click="onButtonClicked"
                        :disabled="statusObj.loading"
                        :loading="loadingSelectButton"
                        ref="customButton"
                    >
                        <span v-html="buttonProps.text"></span>
                    </v-btn>
                </v-list-item-action>

                <v-list-item-action>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                @click="onDeletePaymentMethod"
                                data-testid="remove-button"
                            >
                                {{ $t('message.remove') }}
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-list-item-action>
            </v-row>
            <v-divider class="mt-4"></v-divider>
        </v-col>
    </v-list-item>
</template>

<script>
import alipay from '@/assets/icons/creditcards/logo/alipay.svg'
import amex from '@/assets/icons/creditcards/logo/amex.svg'
import discover from '@/assets/icons/creditcards/logo/discover.svg'
import generic from '@/assets/icons/creditcards/logo/generic.svg'
import mastercard from '@/assets/icons/creditcards/logo/mastercard.svg'
import unionpay from '@/assets/icons/creditcards/logo/unionpay.svg'
import visa from '@/assets/icons/creditcards/logo/visa.svg'

export default {
    props: {
        paymentMethod: {
            type: Object,
            required: true,
        },
        paymentMethodIdForPackage: {
            type: String,
            required: false,
            default: null,
        },
        statusObj: {
            type: Object,
            required: false,
            default: () => ({
                loading: false,
                paymentMethodId: null,
            }),
        },
        isCharging: {
            type: Boolean,
            required: false,
        },
        buttonProps: {
            type: Object,
        },
    },
    emits: ['updatePaymentMethod', 'deletePaymentMethod'],
    computed: {
        loadingSelectButton() {
            return (
                this.statusObj.loading &&
                this.paymentMethod.id === this.statusObj.paymentMethodId
            )
        },
        showCurrentChip() {
            return (
                this.paymentMethodIdForPackage === this.paymentMethod.id &&
                !this.isCharging
            )
        },
        showSelectButton() {
            return (
                this.paymentMethodIdForPackage !== this.paymentMethod.id &&
                !this.isCharging
            )
        },
        showCustomButton() {
            return this.isCharging && this.buttonProps?.show
        },
    },
    methods: {
        getCardIcon(brand) {
            switch (brand.toLowerCase()) {
                case 'visa':
                    return visa
                case 'mastercard':
                    return mastercard
                case 'amex':
                    return amex
                case 'discover':
                    return discover
                case 'alipay':
                    return alipay
                case 'unionpay':
                    return unionpay
                default:
                    return generic
            }
        },
        onUpdatePaymentMethod() {
            this.$emit('updatePaymentMethod', this.paymentMethod.id)
        },
        onDeletePaymentMethod() {
            this.$emit('deletePaymentMethod', this.paymentMethod.id)
        },
        onButtonClicked() {
            this.$emit('onButtonClicked', this.paymentMethod.id)
        },
    },
}
</script>
