<template>
    <v-img
        src="@/assets/logo_with_text.png"
        :max-width="150"
        @click="openVibefam"
        :style="{ cursor: 'pointer' }"
        alt="Vibefam"
    >
    </v-img>
</template>
<script>
import { CLICK_VIBEFAM_LOGO } from '@/analytics/events'

export default {
    methods: {
        openVibefam() {
            window.open('https://vibefam.com/', '_blank')
            this.$gtag.event(CLICK_VIBEFAM_LOGO)
        },
    },
}
</script>
<style scoped></style>
