<template>
    <v-card
        width="100%"
        flat
        :style="{ background: $vuetify.theme.themes[theme].background }"
    >
        <div v-for="item of classes" :key="item.date">
            <div class="pl-3 mt-5 d-flex date-text">
                {{ item.date }}
            </div>
            <br />
            <div class="pa-3" v-if="item.item.length === 0">
                {{ $t('message.noClasses') }}
            </div>
            <div v-else>
                <div v-for="(subItem, index) in item.item" :key="index">
                    <ClassCard
                        :subItem="subItem"
                        :isClickable="isClickable"
                        :bookBtn="bookBtn"
                        :cancelWaitlistBtn="cancelWaitlistBtn"
                        :cancelUpcomingBtn="cancelUpcomingBtn"
                        :isMobileView="isMobileView"
                        @cancelled="cancelled"
                        @removed="removed"
                        @assignFamilyUser="assignFamilyUser"
                        @cancelFeePaymentError="cancelFeePaymentError"
                    />
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
import ClassCard from '@/components/browseclasses/ClassCard.vue'
import { convertDateFormat } from '../../util/dateformat'
export default {
    props: {
        studio: Object,
        classes: Array,
        bookBtn: {
            type: Boolean,
            default: true,
        },
        cancelWaitlistBtn: {
            type: Boolean,
            default: false,
        },
        cancelUpcomingBtn: {
            type: Boolean,
            default: false,
        },
        isClickable: {
            type: Boolean,
            default: false,
        },
        isMobileView: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ClassCard,
    },
    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light'
        },
    },
    methods: {
        formatDate(dateString) {
            return convertDateFormat(dateString)
        },
        cancelled() {
            this.$emit('cancelled')
        },
        removed() {
            this.$emit('removed')
        },
        assignFamilyUser() {
            this.$emit('assignFamilyUser')
        },
        cancelFeePaymentError(err) {
            this.$emit('cancelFeePaymentError', err)
        },
    },
}
</script>

<style scoped>
.date-text {
    font-weight: bold;
    font-family: Roboto;
    font-size: 20px;
}
</style>
