<template>
    <v-row>
        <v-text-field
            v-model="search"
            outlined
            solo
            hide-details
            append-icon="mdi-magnify"
            :label="$t('message.searchUsername')"
            @keyup.enter="searchUser"
            @keyup="searchUserOnKeyUp($event)"
            @keyup.delete="searchUserOnKeyUp($event)"
        ></v-text-field>
    </v-row>
</template>

<script>
import { db } from '@/firebase'
import {
    collection,
    query,
    getDocs,
    where,
    orderBy,
    limit,
} from 'firebase/firestore'
export default {
    data() {
        return {
            search: '',
            users: [],
            timer: null,
        }
    },
    methods: {
        async searchUser() {
            const search = this.search.toLowerCase().trim() // lowercase because username are all lowercase letters, and trim empty spaces
            if (search === '') {
                this.users = []
                this.$emit('userSearched', this.users)
                return
            }
            this.users = []
            const q = query(
                collection(db, 'users'),
                where('username', '>=', search),
                where('username', '<', search + '\uf8ff'),
                orderBy('username'),
                limit(5)
            )
            const querySnapshot = await getDocs(q)
            this.users = querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }))
            this.$emit('userSearched', this.users)
        },
        searchUserOnKeyUp(event) {
            if (event.code == 'Enter') return // To prevent double trigger of the "Enter" event
            if (this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
                this.searchUser()
            }, 300)
        },
    },
}
</script>
