// CancelItem are of classSignUp model

export function canRefund(cancelItem) {
    if (Object.keys(cancelItem).length === 0) {
        return false
    }
    const classDateSeconds = cancelItem.classDate._seconds
    const limitSeconds = classDateSeconds - cancelItem.refundPolicy * 60 * 60
    const todaySeconds = new Date().getTime() / 1000
    return !cancelItem.isBookedFromSmartFill && todaySeconds <= limitSeconds
}
