<template>
    <v-btn color="#25D366" fixed bottom right fab @click="openWhatsApp">
        <v-icon style="color: white !important">mdi-whatsapp</v-icon>
    </v-btn>
</template>
<script>
export default {
    props: {
        whatsAppNumber: String,
    },
    methods: {
        openWhatsApp() {
            const url = `https://wa.me/${this.whatsAppNumber}`
            window.open(url, '_blank')
        },
    },
}
</script>
