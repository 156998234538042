<template>
    <v-dialog :value="isCollectUserDetails" :persistent="true" width="500px">
        <v-card>
            <Logout ref="Logout" :hideButton="true" />
            <v-card-title>
                <v-col>
                    <v-row class="justify-center">
                        <p class="text--secondary text-subtitle-2">
                            Logged in using Facebook account.
                            <span
                                class="text-decoration-underline logout-text"
                                v-on:click="signOut"
                                >Logout?</span
                            >
                        </p>
                    </v-row>
                    <v-row class="justify-center">
                        <h4 class="ml-2">User Details</h4>
                    </v-row>
                </v-col>
            </v-card-title>
            <v-card-text>
                <SignUpForm
                    :key="isCollectUserDetails"
                    :collectUserDetails="true"
                    @success="onSuccess"
                />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import SignUpForm from '@/components/auth/SignUpForm.vue'
import Logout from '@/components/auth/Logout.vue'

import { mapActions, mapGetters } from 'vuex'
import { AUTHENTICATION_MODULE_NAME } from '@/store/moduleNames'
import { ALERT_MODULE_NAME } from '@/store/moduleNames'

export default {
    components: {
        SignUpForm,
        Logout,
    },
    computed: {
        ...mapGetters({
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
            user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
            isLoading: `${AUTHENTICATION_MODULE_NAME}/isLoading`,
            isCollectUserDetails: `${AUTHENTICATION_MODULE_NAME}/isCollectUserDetails`,
        }),
    },
    methods: {
        ...mapActions({
            refreshUserDocument: `${AUTHENTICATION_MODULE_NAME}/refreshUserDocument`,
            closeCollectUserDetails: `${AUTHENTICATION_MODULE_NAME}/closeCollectUserDetails`,
            setAlertStateAction: `${ALERT_MODULE_NAME}/setAlertStateAction`,
        }),
        signOut() {
            this.$refs.Logout.signOut()
            this.closeCollectUserDetails()
        },
        onSuccess() {
            this.refreshUserDocument()
            this.closeCollectUserDetails()
            const payload = {
                type: 'success',
                message:
                    'You have successfully created your account! Please verify your email to begin buying packages.',
            }
            this.setAlertStateAction(payload)
        },
    },
}
</script>
<style scoped>
.logout-text {
    cursor: pointer;
}
</style>
