import { db } from '@/firebase'
import { collection, query, getDocs, where } from 'firebase/firestore'

async function getClassSignups(classId, userId) {
    const q = query(
        collection(db, 'classSignup'),
        where('classId', '==', classId),
        where('userId', '==', userId)
    )
    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
}

export { getClassSignups }
