import { functions, httpsCallable } from '@/firebase'

/**
 * Gets supported countries.
 */
function getSupportedCountries() {
    const getAllCountries = httpsCallable(functions, 'getAllCountries')
    return getAllCountries().then((result) => result.data)
}

export default getSupportedCountries
