import { functions, httpsCallable } from '@/firebase'

async function assignFamilyUser(familyUserId, classSignupId) {
    const assignFamilyUserToClassFn = httpsCallable(
        functions,
        'assignFamilyUserToClass'
    )
    return assignFamilyUserToClassFn({ familyUserId, classSignupId })
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

async function getFamilyUsers(id) {
    const inputs = { id }
    const getFamilyUsersFn = httpsCallable(functions, 'getFamilyUsers')
    return getFamilyUsersFn(inputs)
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

async function createFamilyUser(parentId, fullName, relationship) {
    const inputs = { parentId, fullName, relationship }
    const createFamilyUserFn = httpsCallable(functions, 'createFamilyUser')
    return createFamilyUserFn(inputs)
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

async function editFamilyUser(id, fullName, relationship) {
    const inputs = { id, fullName, relationship }
    const editFamilyUserFn = httpsCallable(functions, 'editFamilyUser')
    return editFamilyUserFn(inputs)
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

async function deleteFamilyUser(id) {
    const inputs = { id }
    const deleteFamilyUserFn = httpsCallable(functions, 'deleteFamilyUser')
    return deleteFamilyUserFn(inputs)
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

export {
    assignFamilyUser,
    getFamilyUsers,
    createFamilyUser,
    editFamilyUser,
    deleteFamilyUser,
}
