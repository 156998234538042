<template>
    <div>
        <!-- v-if here is to hide back button if the user uses this in a dialog from My Packages-->
        <BackButton v-if="packageId === null" />

        <h3 class="my-5 text-center" v-if="!isUserAuth">
            {{ $t('message.youAreNotLoggedIn') }}
        </h3>

        <v-card class="pa-4" v-else>
            <v-card-title> {{ $t('message.paymentMethods') }}</v-card-title>

            <v-card-subtitle>
                {{ $t('message.paymentMethodsDescription') }}
            </v-card-subtitle>

            <v-divider class="mx-6"></v-divider>

            <ProgressLoader v-if="isCurrentlyLoading" class="my-5" />

            <PaymentMethodsList
                v-else
                :paymentMethods="paymentMethods"
                :loadingPaymentMethods="isCurrentlyLoading"
                :paymentMethodIdForPackage="paymentMethodIdForPackage"
                :statusObj="statusObj"
                :isCharging="isCharging"
                :buttonProps="buttonProps"
                @updatePaymentMethod="(mtdId) => updatePaymentMethod(mtdId)"
                @deletePaymentMethod="(mtdId) => openWarningDialog(mtdId)"
                @onButtonClicked="onButtonClicked"
            />

            <p class="error--text text-center">{{ error }}</p>

            <v-card-actions>
                <v-btn
                    color="primary"
                    @click="openAddPaymentMethodDialog"
                    :loading="loadingCheckoutSession"
                    data-testid="add-payment-method-button"
                >
                    {{ $t('message.addPaymentMethod') }}
                </v-btn>
            </v-card-actions>

            <!-- Add Payment Method Dialog -->
            <v-dialog
                :value="!loadingCheckoutSession && showAddPaymentMethodDialog"
                :max-width="loadingCheckoutSession ? 0 : 500"
                persistent
                content-class="elevation-0"
            >
                <v-card class="d-flex flex-column">
                    <v-icon
                        @click="closeAddPaymentMethodDialog"
                        class="ml-auto mr-4 mt-4"
                        >mdi-close</v-icon
                    >

                    <StripeAddPaymentMethod
                        v-if="showAddPaymentMethodDialog"
                        @AddPaymentMethodSuccess="onAddPaymentMethodSuccess"
                        @close="closeAddPaymentMethodDialog"
                        @loadingCheckoutSession="
                            (val) => (loadingCheckoutSession = val)
                        "
                    />
                </v-card>
            </v-dialog>

            <WarningDialog
                :show-dialog="showWarningDialog"
                :title="this.$t('message.removePaymentMethodConfirmation')"
                :content="this.$t('message.removePaymentMethodContent')"
                :confirm-button-text="this.$t('message.confirm')"
                :confirm-callback="removePaymentMethod"
                :cancel-callback="closeWarningDialog"
                :submitting="submittingDelete"
                :error-message="error.toString()"
            />
        </v-card>
    </div>
</template>

<script>
import PaymentMethodsList from '@/components/paymentmethods/PaymentMethodsList.vue'
import BackButton from '@/components/shared/BackButton.vue'
import ProgressLoader from '@/components/shared/ProgressLoader.vue'
import WarningDialog from '@/components/shared/WarningDialog.vue'
import StripeAddPaymentMethod from '@/components/stripe/StripeAddPaymentMethod.vue'
import {
    AUTHENTICATION_MODULE_NAME,
    STUDIO_MODULE_NAME,
} from '@/store/moduleNames'
import {
    deleteStudioMemberPaymentMethod,
    listStudioMemberPaymentMethods,
    updateRecurringPackagePaymentMethod,
} from '@/util/cloudFunctions/paymentMethods'
import { getMyMemberships } from '@/util/cloudFunctions/packagesFunctions'
import { mapGetters } from 'vuex'

export default {
    components: {
        StripeAddPaymentMethod,
        PaymentMethodsList,
        WarningDialog,
        ProgressLoader,
        BackButton,
    },
    props: {
        packageId: {
            type: String,
            default: null,
        },
        isCharging: {
            type: Boolean,
            required: false,
            default: false,
        },
        statusObjProp: {
            type: Object,
            required: false,
            default: function () {
                return {
                    loading: false,
                    paymentMethodId: null,
                }
            },
        },
        buttonProps: {
            type: Object,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            paymentMethods: [],
            showAddPaymentMethodDialog: false,
            showWarningDialog: false,
            submittingDelete: false, // both 'submitting' datas are mostly for showing loading status
            statusObj: this.statusObjProp,
            loadingPaymentMethods: false,
            paymentMethodIdToDelete: null,
            loadingCheckoutSession: false,
            error: '',
            paymentMethodIdForPackage: null,
        }
    },
    mounted() {
        this.fetchPaymentMethods()
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
        }),
        isCurrentlyLoading() {
            return this.isLoading || this.loadingPaymentMethods
        },
    },
    methods: {
        async fetchPaymentMethods() {
            this.loadingPaymentMethods = true
            this.paymentMethods = []
            this.error = ''
            const promises = [listStudioMemberPaymentMethods(this.studio.id)]

            if (this.packageId !== null) {
                promises.push(getMyMemberships(this.studio.id))
            }

            const [result, membershipsResult] = await Promise.all(promises)

            if (result.success) {
                this.paymentMethods = result.paymentMethods
            } else {
                this.error = result.error.toString()
            }

            if (membershipsResult && membershipsResult.success) {
                const membership = membershipsResult.docs.find(
                    (doc) => doc.id === this.packageId
                )

                // if membership.paymentMethodId is undefined or membership is not found, set to empty string
                // else set to the paymentMethodId
                this.paymentMethodIdForPackage =
                    (membership && membership.paymentMethodId) || ''
            }

            this.loadingPaymentMethods = false
        },
        openAddPaymentMethodDialog() {
            this.showAddPaymentMethodDialog = true
        },
        closeAddPaymentMethodDialog() {
            this.showAddPaymentMethodDialog = false
        },
        openWarningDialog(methodId) {
            this.error = ''
            this.paymentMethodIdToDelete = methodId
            this.showWarningDialog = true
        },
        closeWarningDialog() {
            this.showWarningDialog = false
        },
        async removePaymentMethod() {
            this.submittingDelete = true
            const result = await deleteStudioMemberPaymentMethod(
                this.studio.id,
                this.paymentMethodIdToDelete
            )
            if (result.success) {
                await this.fetchPaymentMethods()
            } else {
                this.error = result.error.toString()
            }
            this.paymentMethodIdToDelete = null
            this.submittingDelete = false
            this.showWarningDialog = false
        },
        async updatePaymentMethod(methodId) {
            this.statusObj.loading = true
            this.statusObj.paymentMethodId = methodId
            const result = await updateRecurringPackagePaymentMethod(
                this.studio.id,
                methodId,
                this.packageId
            )
            if (result.success) {
                this.fetchPaymentMethods()
            } else {
                this.error =
                    'There was an issue updating the payment method. Please try again.'
            }
            this.statusObj.loading = false
            this.statusObj.paymentMethodId = null
        },
        onAddPaymentMethodSuccess() {
            this.fetchPaymentMethods()
            this.closeAddPaymentMethodDialog()
        },
        onButtonClicked(methodId) {
            this.$emit('onButtonClicked', methodId)
        },
    },
    watch: {
        isUserAuth: {
            handler() {
                if (this.isUserAuth) {
                    this.fetchPaymentMethods()
                }
            },
        },
    },
}
</script>
