<template>
    <v-scroll-x-transition>
        <v-col align="center" v-show="loading || error.length > 0">
            <lottie-animation
                :path="image"
                :height="dimensions"
                :width="dimensions"
            />
            <p v-if="loading">
                {{ $t('message.bookingClass') + $t('message.ellipsis') }}
            </p>
            <h1 v-if="error.length > 0" class="error--text">
                {{ $t('message.oops') + $t('message.ellipsis') }}
            </h1>
            <p>{{ error }}</p>
        </v-col>
    </v-scroll-x-transition>
</template>
<script>
import { mapGetters } from 'vuex'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import { bookClass } from '@/util/cloudFunctions/classesFunctions'
import { waitlistClass } from '@/util/cloudFunctions/classesFunctions'

// Analytics
import {
    BOOK_CLASS,
    BOOK_CLASS_ERROR,
    WAITLIST_CLASS,
    WAITLIST_CLASS_ERROR,
    WEBAPP_BOOK_CLASS,
    WEBAPP_BOOK_CLASS_ERROR,
    WEBAPP_WAITLIST_CLASS,
    WEBAPP_WAITLIST_CLASS_ERROR,
} from '@/analytics/events'
import { subStr } from '@/analytics/util'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue' // import lottie-vuejs

export default {
    components: {
        LottieAnimation,
    },
    props: {
        hideSpots: Boolean,
        classId: String,
        spotNumbers: Array,
        numSpots: Number,
        packageId: String,
        isWaitlistClass: Boolean,
    },
    data() {
        return {
            loading: true,
            error: '',
        }
    },
    computed: {
        dimensions() {
            return this.$vuetify.breakpoint.mdAndUp ? 350 : 200
        },
        image() {
            if (this.error.length > 0) {
                const randomInt = Math.floor(Math.random() * 3 + 1)
                return `lottie/failed${randomInt}.json`
            }
            const randomInt = Math.floor(Math.random() * 10 + 1)
            return `lottie/loading${randomInt}.json`
        },
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
    },
    methods: {
        async bookClassFromCloud() {
            this.loading = true
            this.error = ''
            this.$emit('start')
            const result = await bookClass(
                this.classId,
                this.spotNumbers,
                this.numSpots,
                this.packageId
            )
            if (result.success) {
                this.logSuccess(BOOK_CLASS)
                this.logSuccess(WEBAPP_BOOK_CLASS)
                this.$emit('success')
            } else {
                this.$gtag.event(BOOK_CLASS_ERROR, {
                    errorMessage: subStr(result.error),
                })
                this.$gtag.event(WEBAPP_BOOK_CLASS_ERROR, {
                    errorMessage: subStr(result.error),
                })
                this.error = result.error
            }
            this.$emit('end')
            this.loading = false
        },
        async waitlistClassFromCloud() {
            this.loading = true
            const result = await waitlistClass(this.classId, this.packageId)

            if (result.success) {
                this.logSuccess(WAITLIST_CLASS)
                this.logSuccess(WEBAPP_WAITLIST_CLASS)
                this.$emit('success')
            } else {
                this.$gtag.event(WAITLIST_CLASS_ERROR, {
                    errorMessage: subStr(result.error),
                })
                this.$gtag.event(WEBAPP_WAITLIST_CLASS_ERROR, {
                    errorMessage: subStr(result.error),
                })
                this.error = result.error
                return
            }
            this.loading = false
        },
        logSuccess(event) {
            const eventData = {
                studioId: this.studio.id,
                studioName: this.studio.studioName,
                packageId: this.packageId,
                classId: this.classId,
            }
            this.$gtag.event(event, eventData)
        },
    },
    created() {
        if (this.isWaitlistClass) {
            this.waitlistClassFromCloud()
        } else {
            this.bookClassFromCloud()
        }
    },
}
</script>
