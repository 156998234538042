<template>
    <div class="description-text" v-if="isHtml" v-html="content"></div>
    <div class="description-text" v-else>{{ content }}</div>
</template>

<script>
/**
 * `DescriptionText`preserves white space, new lines, and automatically wraps long words to prevent overflow.
 * It also accepts HTML content if the `isHtml` prop is true.
 */
export default {
    name: 'DescriptionText',
    props: {
        content: {
            type: String,
            default: '',
        },
        isHtml: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style scoped>
.description-text {
    white-space: pre-wrap;
    word-wrap: break-word;
}
</style>
