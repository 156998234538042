import { functions, httpsCallable } from '@/firebase'

/**
 * @param {Object} data Has properties {studioID, code}
 * @param {String} userID userID
 * @returns {Object} Success property is set to true if there is at least
 * one eligible package.
 */
export async function getEligiblePackageDiscounts(data, userID) {
    const getEligiblePackageDiscounts = httpsCallable(
        functions,
        'getEligiblePackageDiscounts'
    )
    const cloudfn = await getEligiblePackageDiscounts(data, userID)
    return cloudfn
}
