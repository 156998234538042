<template>
    <div class="body pb-12">
        <v-row
            :justify="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
            :class="$vuetify.breakpoint.mdAndUp ? 'mx-8 my-5' : 'mx-2 my-2'"
            align="center"
        >
            <VibefamLogo />
        </v-row>

        <v-row justify="center" class="my-5" align="center">
            <v-img
                src="@/assets/illustration_cropped.png"
                max-height="200"
                max-width="500"
            ></v-img>
        </v-row>

        <v-row justify="center" class="mx-3 text-center">
            <h1 class="d-none d-sm-block">{{ tagLine }}</h1>
            <h3 class="d-block d-sm-none">{{ tagLine }}</h3>
        </v-row>

        <v-row justify="center">
            <v-col cols="10" md="6" lg="4">
                <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    v-if="doneLoading"
                    v-model="search"
                    outlined
                    placeholder="Search by studio name, location or class name"
                ></v-text-field>
                <v-skeleton-loader v-else type="list-item"></v-skeleton-loader>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-col cols="10" sm="9" md="8">
                <v-row justify="start" v-if="doneLoading">
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                        v-for="studio in studios"
                        :key="studio.name"
                    >
                        <StudioCard :studio="studio" />
                    </v-col>
                </v-row>

                <v-row justify="start" v-else>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                        v-for="n in 12"
                        :key="n"
                    >
                        <v-skeleton-loader
                            class="mx-auto"
                            type="card"
                        ></v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import getAllStudios from '@/util/cloudFunctions/getAllStudios'
import StudioCard from '@/components/home/StudioCard.vue'
import VibefamLogo from '@/components/shared/VibefamLogo.vue'
export default {
    components: {
        StudioCard,
        VibefamLogo,
    },
    data() {
        return {
            studios: [],
            originalStudios: [],
            doneLoading: false,
            search: '',
        }
    },
    async created() {
        this.studios = await getAllStudios()
        this.originalStudios = this.studios
        this.doneLoading = true
    },
    watch: {
        search: {
            handler(input) {
                if (input === '') {
                    this.studios = this.originalStudios
                } else {
                    input = input.toLowerCase()
                    this.studios = this.originalStudios.filter((studio) => {
                        const studioName = studio.studioName.toLowerCase()
                        const matchesStudioName =
                            studioName.startsWith(input) ||
                            studioName.includes(input)

                        if (matchesStudioName) {
                            return true
                        }
                        const locations = studio.locations.map((location) =>
                            location.name.toLowerCase()
                        )
                        const matchesLocations =
                            locations.filter(
                                (locationName) =>
                                    locationName.startsWith(input) ||
                                    locationName.includes(input)
                            ).length > 0
                        if (matchesLocations) {
                            return true
                        }

                        const classes = studio.classes.map((classItem) =>
                            classItem.className.toLowerCase()
                        )
                        const matchesClasses =
                            classes.filter(
                                (className) =>
                                    className.startsWith(input) ||
                                    className.includes(input)
                            ).length > 0
                        if (matchesClasses) {
                            return true
                        }
                        return false
                    })
                }
            },
        },
    },
    computed: {
        tagLine() {
            return 'Where fitness communities are built'
        },
    },
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
    .body {
        overflow: hidden;
    }
}

.letter {
    letter-spacing: 5px;
    font-weight: 600;
    font-size: 20px;
}
</style>
