export function getFiltersFromUrl(windowLocationSearch) {
    const params = new URLSearchParams(windowLocationSearch)
    return {
        classFilter: getValuesFromParam(params, 'classes'),
        courseFilter: getValuesFromParam(params, 'courses'),
        locationFilter: getValuesFromParam(params, 'location'),
        instructorFilter: getValuesFromParam(params, 'instructors'),
    }
}

function getValuesFromParam(params, key) {
    const value = params.get(key)
    if (value) {
        return value.split('+') // Split by '+' to handle spaces
    }
    return []
}
