<template>
    <div v-if="!loading">
        <div v-for="(data, index) in studioCustomData" :key="index">
            <CheckBox
                v-if="data.fieldType === fieldTypes.CHECKBOX"
                :question="data.question"
                :description="data.description"
                :options="data.options"
                :isRequired="checkIfFieldEditable(data) && data.isRequired"
                :fieldEditable="checkIfFieldEditable(data)"
                :existingValue="convertToArray(data.value)"
                @onValueChanged="(value) => onValueChanged({ index, value })"
                :isEditable="data.isEditable"
                :showRedBackground="showRedBackground"
            />
            <DropDown
                v-else-if="data.fieldType === fieldTypes.DROPDOWN"
                :question="data.question"
                :description="data.description"
                :options="data.options"
                :isRequired="checkIfFieldEditable(data) && data.isRequired"
                :fieldEditable="checkIfFieldEditable(data)"
                :existingValue="convertToString(data.value)"
                @onValueChanged="(value) => onValueChanged({ index, value })"
                :isEditable="data.isEditable"
                :showRedBackground="showRedBackground"
            />
            <FreeText
                v-else
                :question="data.question"
                :description="data.description"
                :isRequired="checkIfFieldEditable(data) && data.isRequired"
                :fieldEditable="checkIfFieldEditable(data)"
                :existingValue="convertToString(data.value)"
                @onValueChanged="(value) => onValueChanged({ index, value })"
                :isEditable="data.isEditable"
                :showRedBackground="showRedBackground"
            />
        </div>
    </div>
</template>

<script>
import CheckBox from './CheckBox.vue'
import DropDown from './DropDown.vue'
import FreeText from './FreeText.vue'
import {
    getStudioCustomField,
    getStudioMemberCustomField,
} from '@/util/cloudFunctions/studioMemberCustomFieldFunctions'
import {
    AUTHENTICATION_MODULE_NAME,
    CUSTOM_FIELDS_MODULE_NAME,
} from '@/store/moduleNames'
import { CustomDataType } from './CustomFieldsUtils'
import { mapGetters, mapMutations } from 'vuex'

export default {
    components: {
        CheckBox,
        DropDown,
        FreeText,
    },
    computed: {
        ...mapGetters({
            user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
        }),
    },
    props: {
        studioId: String,
        isEditMode: Boolean,
        showRedBackground: { type: Boolean, default: true },
    },
    data() {
        return {
            fieldTypes: CustomDataType,
            loading: true,
            studioCustomData: [],
            isValueChanged: false,
        }
    },
    async created() {
        await this.loadData()
    },
    methods: {
        ...mapMutations({
            setHasUnfilledCustomFields: `${CUSTOM_FIELDS_MODULE_NAME}/setHasUnfilledCustomFields`,
        }),
        async loadData() {
            this.loading = true
            this.$emit('isLoading', true)
            const customFieldsValue = await this.getStudioMemberCustomFields()
            await this.setStudioCustomFields(customFieldsValue)
            this.loading = false
            this.$emit('isLoading', false)
            this.$emit('isValueChanged', false)
        },
        async getStudioMemberCustomFields() {
            // this.user is undefined in sign up page
            if (!this.user) {
                return {}
            }

            const result = await getStudioMemberCustomField({
                userId: this.user.id,
                studioId: this.studioId,
            })

            if (!result.success) {
                return {}
            }
            return result.memberCustomData
        },
        checkIfFieldEditable(data) {
            return this.isEditMode && data.isEditable
        },
        compareByPosition(a, b) {
            return a.position - b.position
        },
        filterFields([, data]) {
            // If in edit mode, only include fields that are active and editable
            if (this.isEditMode) {
                return data.isActive && data.isEditable
            }
            // If not in edit mode, only include fields that are active
            return data.isActive
        },
        mapFields([key, data], customFieldsValue) {
            // If a custom value entered by the user exists for this field, use it. Otherwise, use an empty string
            const customValue = customFieldsValue?.[key]?.value

            // update hasUnfilledCustomFields in store if the field is required and not filled
            if (
                data.isRequired &&
                data.isEditable &&
                data.isActive &&
                !customValue
            ) {
                this.setHasUnfilledCustomFields(true)
            }
            // adds a fieldId property and value property
            return { ...data, fieldId: key, value: customValue }
        },
        async setStudioCustomFields(customFieldsValue) {
            const studioCustomField = await getStudioCustomField(this.studioId)
            if (studioCustomField.studioCustomDatas) {
                this.studioCustomData = Object.entries(
                    studioCustomField.studioCustomDatas
                )
                    .filter(this.filterFields)
                    .map((field) => this.mapFields(field, customFieldsValue))
                    .sort(this.compareByPosition)
            }

            this.$emit('studioCustomData', this.studioCustomData)
        },
        onValueChanged(data) {
            const { index, value: newValue } = data

            if (index >= 0 && index < this.studioCustomData.length) {
                this.studioCustomData[index]['value'] = newValue
                this.$emit('isValueChanged', true)
            } else {
                console.error(
                    'Invalid index provided in onValueChanged method.'
                )
            }
        },
        onSubmit() {
            this.$emit('studioCustomData', this.studioCustomData)
        },
        convertToString(value) {
            if (Array.isArray(value)) {
                return value.join(',')
            }

            return value
        },
        convertToArray(value) {
            if (typeof value === 'string') {
                return value.split(',')
            }

            return value
        },
    },
}
</script>
