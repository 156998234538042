import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { Ripple } from 'vuetify/lib/directives'
import zhHans from 'vuetify/lib/locale/zh-Hans'
import en from 'vuetify/lib/locale/en'
import zh_hant from 'vuetify/lib/locale/zh-Hant'

Vue.use(Vuetify, {
    directives: {
        Ripple,
    },
})

export default new Vuetify({
    lang: {
        locales: { zhHans, en, zh_hant },
        current: 'en',
    },
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#a3a3a3',
                dilutedPrimary: '#a3a3a32ex',
                secondary: '#FFBD58',
                primaryMenu: '#FFFFFF',
                background: '#F9F9F9',
                card: { base: '#FFFFFF' },
                appFontColor: { base: '#000000' },
                ordinary: { base: '#000000' },
                ordinaryInverted: { base: '#FFFFFF' },
            },
            dark: {
                primary: '#a3a3a3',
                dilutedPrimary: '#a3a3a32e',
                secondary: '#FFBD58',
                primaryMenu: '#000000',
                background: '#121212',
                card: { base: '#1E1E1E' },
                appFontColor: { base: '#FFFFFF' },
                ordinary: { base: '#FFFFFF' },
                ordinaryInverted: { base: '#000000' },
            },
        },
    },
})
