<template>
    <v-data-table
        :headers="headers"
        :items="classSignups"
        hide-default-footer
        :loading="loading"
    >
        <template v-slot:item.name="{ item }">
            {{ item.familyUserId ? item.familyUserFullName : item.fullName }}
        </template>
        <template v-slot:item.actions="{ item }">
            <AssignFamilyUser :classSignup="item" @success="setClassSignups" />
        </template>
    </v-data-table>
</template>

<script>
import AssignFamilyUser from '@/components/familyusers/AssignFamilyUser.vue'

import { mapGetters } from 'vuex'
import { AUTHENTICATION_MODULE_NAME } from '@/store/moduleNames'
import { getClassSignups } from '@/util/classes/getClassSignups'

export default {
    props: {
        classId: String,
    },
    components: {
        AssignFamilyUser,
    },
    data() {
        return {
            headers: [
                { text: 'Spot Number', value: 'spotNumber' },
                { text: 'Name', value: 'name' },
                { text: '', value: 'actions' },
            ],
            classSignups: [],
        }
    },
    computed: {
        ...mapGetters({
            user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
        }),
    },
    methods: {
        async setClassSignups() {
            this.loading = true
            this.classSignups = await getClassSignups(
                this.classId,
                this.user.id
            )
            this.classSignups.sort((a, b) => a.spotNumber - b.spotNumber)
            this.loading = false
        },
    },
    created() {
        this.setClassSignups()
    },
}
</script>
