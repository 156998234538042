import { functions, httpsCallable } from '@/firebase'

export default function addTokenToDb(token, studioId, user, brandedAppServer) {
    const userId = user.id
    if (userId && token && studioId) {
        const getClassesByStudio = httpsCallable(
            functions,
            'brandedAppRegisterNotif'
        )
        const callData = { token, studioId, brandedAppServer }
        return getClassesByStudio(callData).then((result) => result.data)
    }
}
