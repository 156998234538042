const moment = require('moment-timezone')

exports.dateStringFromTimestamp = dateStringFromTimestamp
exports.timeStringFromTimestamp = timeStringFromTimestamp
exports.dateTimeStringFromTimestamp = dateTimeStringFromTimestamp
exports.convertDateFormat = convertDateFormat

const DEFAULT_DATE_FORMAT = 'dddd, D MMM YYYY'
/**
 * @param {Timestamp} timestamp
 * @returns date formatted to a human-readable date string
 */
function dateStringFromTimestamp(timestamp, timezone, format) {
    const formatter = format ?? DEFAULT_DATE_FORMAT
    return formatTimestamp(timestamp, timezone, formatter)
}

/**
 * @param {Timestamp} timestamp
 * @returns date formatted to a human-readable time string
 */
function timeStringFromTimestamp(timestamp, timezone) {
    let date
    if (timestamp instanceof Date) {
        date = timestamp
    } else {
        date = new Date(timestamp._seconds * 1000)
    }
    const momentDate = moment(date)
    let formatter
    if (momentDate.minutes() == 0) {
        formatter = 'ha'
    } else {
        formatter = 'h:mm a'
    }
    return formatTimestamp(timestamp, timezone, formatter)
}

/**
 * @param {Timestamp} timestamp
 * @returns date formatted to a human-readable date and time string
 */
function dateTimeStringFromTimestamp(timestamp) {
    const dateFormat = dateStringFromTimestamp(timestamp)
    const timeFormat = timeStringFromTimestamp(timestamp)
    return `${dateFormat}, ${timeFormat}`
}

function formatTimestamp(timestamp, timezone, formatter) {
    let date
    if (timestamp instanceof Date) {
        date = timestamp
    } else {
        date = new Date(timestamp._seconds * 1000)
    }
    const timezoneStr = timezone ? timezone : 'Asia/Singapore'
    const momentDate = moment.tz(date, timezoneStr)
    return momentDate.format(formatter)
}

/**
 * @param {string} dateString
 * @param {string} currentFormat
 * @param {string} newFormat
 * Use this function to convert a date ONLY string from one format to another. If you have a date and TIME, use `dateStringFromTimestamp` instead.
 * @returns The date string in the new format
 */
function convertDateFormat(
    dateString,
    currentFormat = 'YYYY-MM-DD',
    newFormat = DEFAULT_DATE_FORMAT
) {
    return moment(dateString, currentFormat).format(newFormat)
}
