<template>
    <div :class="{ 'card-center': !largeBtnStyle }">
        <v-btn
            :class="{ 'btn-style': largeBtnStyle }"
            outlined
            @click="openWaitlistDialog(subItem)"
            v-on:click.prevent
            :loading="loadingData"
        >
            {{ $t('message.classRemove') }}
        </v-btn>
        <WarningDialog
            :showDialog="waitlistDialog"
            :title="this.$t('message.classRemoveFromWaitlist')"
            :content="waitlistDialogContent"
            :cancelCallback="closeWaitlistDialog"
            :confirmCallback="removeWaitlist"
            :errorMessage="waitlistError"
            :submitting="isSubmitting"
        >
        </WarningDialog>
    </div>
</template>

<script>
import WarningDialog from '@/components/shared/WarningDialog'
import { removeWaitlistClass } from '@/util/cloudFunctions/classesFunctions'
import { CANCEL_WAITLIST, CANCEL_WAITLIST_ERROR } from '@/analytics/events'
import { subStr } from '@/analytics/util'

export default {
    props: {
        subItem: Object,
        largeBtnStyle: Boolean, // to make button larger style for use in BookClass Component
        loadingData: Boolean, // to show loading spinner on button if still fetching data
    },
    components: {
        WarningDialog,
    },
    data() {
        return {
            waitlistDialog: false,
            removeItem: {},
            waitlistError: '',
            isSubmitting: false,
        }
    },
    computed: {
        waitlistDialogContent() {
            return this.$t('message.classRemoveFromWaitlistDialog')
        },
    },
    methods: {
        openWaitlistDialog(item) {
            this.waitlistDialog = true
            this.removeItem = item
        },
        closeWaitlistDialog() {
            this.waitlistDialog = false
        },
        async removeWaitlist() {
            this.isSubmitting = true
            const data = {
                waitlistId: this.removeItem.id,
            }
            const result = await removeWaitlistClass(data)
            if (result.success) {
                this.$gtag.event(CANCEL_WAITLIST)
                this.$emit('success')
                this.closeWaitlistDialog()
            } else {
                this.$gtag.event(CANCEL_WAITLIST_ERROR, {
                    errorMessage: subStr(result.error),
                })
                // NOTE: Error message can't translate dynamically with i18n
                this.waitlistError = result.error
            }
            this.isSubmitting = false
        },
    },
}
</script>

<style scoped>
.card-center {
    margin-bottom: -10px !important;
    margin-top: 40px;
    margin-left: 5px;
}

.btn-style {
    width: 100%;
    height: 50px !important;
    margin-bottom: 10px;
    margin-top: 10px;
}
</style>
