/// General utility functions for analytics.
import {
    PURCHASE,
    WEBAPP_PURCHASE,
    PURCHASE_COURSE,
    WEBAPP_PURCHASE_COURSE,
    PURCHASE_MERCHANDISE,
    WEBAPP_PURCHASE_MERCHANDISE,
} from '@/analytics/events'

/**
 * Substring to get first 100 characters from string even for strings with <100 characters.
 * @param {String} str
 */
function subStr(str) {
    return str.toString().substring(0, Math.min(str.length, 99))
}

function isPurchaseMerchandise(eventName) {
    return [PURCHASE_MERCHANDISE, WEBAPP_PURCHASE_MERCHANDISE].includes(
        eventName
    )
}

function isPurchasePackageOrPurchaseCourse(eventName) {
    return [
        PURCHASE,
        WEBAPP_PURCHASE,
        PURCHASE_COURSE,
        WEBAPP_PURCHASE_COURSE,
    ].includes(eventName)
}

export { subStr, isPurchaseMerchandise, isPurchasePackageOrPurchaseCourse }
