import { functions, httpsCallable } from '@/firebase'

async function getProducts(studioId) {
    const getProductsFn = httpsCallable(functions, 'getProducts')
    return getProductsFn({ studioId })
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

async function getCategories(studioId) {
    const getCategoriesFn = httpsCallable(functions, 'getProductCategories')
    return getCategoriesFn({ studioId })
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

export { getProducts, getCategories }
