<template>
    <v-dialog v-model="dialog" max-width="500">
        <v-snackbar
            v-model="snackbar"
            :timeout="2000"
            :color="snackbarColor"
            top
        >
            {{ snackbarMessage }}
        </v-snackbar>

        <template v-slot:activator="{ on, attrs }">
            <v-avatar
                :size="userPhotoSize"
                class="mr-5"
                v-bind="attrs"
                v-on="on"
            >
                <v-img
                    :src="base64 || user.profilePhoto"
                    style="border-style: solid"
                />
            </v-avatar>
        </template>

        <v-card>
            <v-card-title>
                <v-btn icon :ripple="false" @click="closeDialog" class="mr-2">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                {{ $t('message.editPersonalDetails') }}
            </v-card-title>

            <v-card-text>
                <div class="m-10">
                    <v-row justify="center" class="mb-5">
                        <v-avatar :size="150">
                            <v-img
                                :src="base64 || user.profilePhoto"
                                style="border-style: solid"
                            />
                        </v-avatar>
                    </v-row>
                    <v-row class="mb-5" justify="center">
                        <v-btn outlined :ripple="false" @click="selectFile">
                            <v-icon left>mdi-plus</v-icon>
                            {{ $t('message.uploadPhoto') }}
                        </v-btn>
                        <v-file-input
                            id="input"
                            style="display: none"
                            accept="image/*"
                            @change="convertToBase64"
                            label="Upload Photo"
                            prepend-inner-icon="mdi-plus"
                            v-model="profilePhoto"
                        />
                    </v-row>
                    <v-row justify="center">
                        <v-btn
                            :disabled="!profilePhoto"
                            :loading="isLoading"
                            color="primary"
                            @click="uploadImage"
                        >
                            {{ $t('message.submit') }}
                        </v-btn>
                    </v-row>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { StorageService } from '@/util/services/StorageService'
import { editUser } from '@/util/cloudFunctions/editUser'
import { AUTHENTICATION_MODULE_NAME } from '@/store/moduleNames'

export default {
    methods: {
        convertToBase64() {
            if (!this.profilePhoto) {
                this.base64 = ''
                return
            }

            const reader = new FileReader()
            reader.readAsDataURL(this.profilePhoto)
            reader.onload = () => {
                this.base64 = reader.result
            }
        },
        selectFile() {
            document.getElementById('input').click()
        },
        async uploadImage() {
            try {
                this.isLoading = true
                this.setSnackbar('', '', false)

                const ext = this.profilePhoto.type.split('/')[1]
                const path = `profile_photos/${this.user.id}`
                const fileName = 'profilePhoto'

                const downloadUrl = await StorageService.uploadFile(
                    path,
                    fileName,
                    ext,
                    this.profilePhoto
                )

                const { success, error } = await editUser({
                    dob: this.user.dob,
                    fullName: this.user.fullName,
                    username: this.user.username,
                    userId: this.user.id,
                    profilePhoto: downloadUrl,
                })

                if (!success) throw Error(error)

                await this.$store.dispatch(
                    `${AUTHENTICATION_MODULE_NAME}/refreshUserDocument`
                )

                this.setSnackbar(
                    this.$t('message.updateSuccess'),
                    'success',
                    true
                )
                this.closeDialog()
            } catch (e) {
                this.setSnackbar(e, 'error', true)
            }
            this.isLoading = false
        },
        closeDialog() {
            this.dialog = false
        },
        setSnackbar(message, color, shouldOn) {
            this.snackbarMessage = message
            this.snackbar = shouldOn
            this.snackbarColor = color
        },
    },
    data() {
        return {
            base64: '',
            dialog: false,
            isLoading: false,
            snackbar: false,
            snackbarColor: '',
            snackbarMessage: '',
            profilePhoto: '',
        }
    },
    props: {
        user: Object,
        userPhotoSize: Number,
    },
}
</script>
