<template>
    <ValidationObserver ref="observer">
        <v-form ref="form">
            <!-- Password Field -->
            <ValidationProvider
                :name="$t('message.newPassword')"
                rules="required|max:64"
                v-slot="{ errors }"
            >
                <v-text-field
                    :label="$t('message.newPassword')"
                    v-model="password"
                    prepend-icon="mdi-lock"
                    :error-messages="errors"
                    :append-icon="passwordToggle ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="() => (passwordToggle = !passwordToggle)"
                    :type="passwordToggle ? 'password' : 'text'"
                ></v-text-field>
            </ValidationProvider>

            <p class="error--text text-center py-3" v-if="error !== ''">
                {{ error }}
            </p>

            <v-row justify="center" class="my-3">
                <v-btn
                    class="primary"
                    @click="submit"
                    :loading="loading"
                    depressed
                >
                    {{ $t('message.changePassword') }}
                </v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
import { auth, updatePassword } from '@/firebase'
import getAuthErrorMessage from '@/util/authErrors'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// Analytics
import { CHANGE_PASSWORD, CHANGE_PASSWORD_ERROR } from '@/analytics/events'
import { subStr } from '@/analytics/util'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            password: '',
            email: '',
            error: '',
            loading: false,
            passwordToggle: true,
        }
    },
    methods: {
        async validate() {
            return await this.$refs.observer.validate()
        },
        async submit() {
            this.error = ''
            this.loading = true

            const isValid = await this.validate()

            if (!isValid) {
                this.loading = false
                return
            }

            updatePassword(auth.currentUser, this.password)
                .then(() => {
                    this.$gtag.event(CHANGE_PASSWORD)
                    this.$emit('success')
                })
                .catch((error) => {
                    this.$gtag.event(CHANGE_PASSWORD_ERROR, {
                        errorMessage: subStr(error.code),
                    })
                    this.error = getAuthErrorMessage(error.code)
                    this.loading = false
                })
        },
    },
}
</script>
