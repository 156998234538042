<template>
    <v-row class="mt-5 mx-0">
        <v-row>
            <v-col cols="12" sm="6" md="3">
                <v-autocomplete
                    ref="classesFilter"
                    :value="classFilter"
                    @input="onClassFilterChange"
                    :items="allClasses"
                    :label="$t('message.classes')"
                    :placeholder="$t('message.select') + $t('message.ellipsis')"
                    required
                    multiple
                    outlined
                    small-chips
                    hide-details
                    clearable
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <v-autocomplete
                    ref="instructorsFilter"
                    :value="instructorFilter"
                    @input="onInstructorFilterChange"
                    :items="allInstructors"
                    :label="$t('message.instructors')"
                    :placeholder="$t('message.select') + $t('message.ellipsis')"
                    required
                    multiple
                    outlined
                    small-chips
                    hide-details
                    clearable
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <v-autocomplete
                    ref="locationsFilter"
                    :value="locationFilter"
                    @input="onLocationFilterChange"
                    :items="allLocations"
                    :label="$t('message.locations')"
                    :placeholder="$t('message.select') + $t('message.ellipsis')"
                    required
                    multiple
                    outlined
                    small-chips
                    hide-details
                    clearable
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <date-picker
                    ref="dateFilter"
                    :label="$t('message.date')"
                    :value="date"
                    @input="onDateChange"
                    :outlined="true"
                    :min="minDate"
                    hide-details
                />
            </v-col>
        </v-row>
    </v-row>
</template>

<script>
import DatePicker from '@/components/shared/DatePicker.vue'

export default {
    props: {
        studio: Object,
        type: String, // "courses" or "classes"
        classFilter: Array,
        instructorFilter: Array,
        locationFilter: Array,
        date: String,
        minDate: String,
    },
    components: {
        DatePicker,
    },
    computed: {
        allClasses() {
            return this.type === 'course'
                ? this.studio.courses ?? []
                : (this.studio.classes ?? []).map(
                      (classItem) => classItem.className
                  )
        },
        allLocations() {
            return this.studio.locations.map((location) => location.name)
        },
        allInstructors() {
            return this.studio.instructorNames
        },
    },
    methods: {
        onClassFilterChange(event) {
            this.$emit('update:classFilter', event)
        },
        onInstructorFilterChange(event) {
            this.$emit('update:instructorFilter', event)
        },
        onLocationFilterChange(event) {
            this.$emit('update:locationFilter', event)
        },
        onDateChange(event) {
            this.$emit('update:date', event)
        },
    },
}
</script>
