<template>
    <v-row>
        <v-col v-if="isUserAuth" align="end">
            <UserProfile :user="user" />
        </v-col>
        <v-row class="ma-0" v-if="!isUserAuth">
            <v-col>
                <Login block />
            </v-col>
            <v-col>
                <SignUpButton block />
            </v-col>
        </v-row>
    </v-row>
</template>
<script>
import SignUpButton from '@/components/auth/SignUpButton'
import Login from '@/components/auth/Login'
import UserProfile from '@/components/auth/UserProfile'
import { mapGetters } from 'vuex'
import { AUTHENTICATION_MODULE_NAME } from '@/store/moduleNames'

export default {
    components: {
        UserProfile,
        SignUpButton,
        Login,
    },
    computed: {
        ...mapGetters({
            user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
        }),
    },
}
</script>
