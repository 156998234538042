<template>
    <div>
        <v-snackbar :timeout="2000" v-model="snackbar" color="success" top>
            {{ $t('message.updateSuccess') }}
        </v-snackbar>
        <v-dialog v-model="dialog" max-width="750">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon :ripple="false" v-on="on" v-bind="attrs">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <v-btn
                        icon
                        :ripple="false"
                        @click="closeDialog"
                        class="mr-2"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <div>
                        {{ $t('message.editPersonalDetails') }}
                    </div>
                </v-card-title>
                <v-card-text>
                    <div class="my-5 mx-10">
                        <validation-observer ref="observer">
                            <validation-provider
                                :name="$t('message.fullName')"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <v-row>
                                    <v-text-field
                                        :label="$t('message.fullName')"
                                        solo
                                        prepend-inner-icon="mdi-text-short"
                                        v-model="fullName"
                                    />
                                </v-row>
                                <p v-if="errors[0]">
                                    {{ errors[0] }}{{ $t('message.required') }}
                                </p>
                            </validation-provider>

                            <validation-provider
                                :name="$t('message.username')"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <v-row>
                                    <v-text-field
                                        class="mb-5"
                                        :label="$t('message.username')"
                                        :hint="
                                            $t('message.usernameRequirement')
                                        "
                                        solo
                                        prepend-inner-icon="mdi-account"
                                        v-model="username"
                                    />
                                </v-row>
                                <p v-if="errors[0]">
                                    {{ errors[0] }}{{ $t('message.required') }}
                                </p>
                            </validation-provider>
                            <v-row>
                                <date-picker
                                    v-if="
                                        !brandedAppsInReview.includes(studioId)
                                    "
                                    class="my-5"
                                    :value="dob"
                                    v-model="dob"
                                    solo
                                    :label="$t('message.dob')"
                                />
                            </v-row>

                            <v-row
                                v-if="errorMessage"
                                justify="center"
                                class="my-2"
                            >
                                {{ errorMessage }}
                            </v-row>

                            <v-row justify="center">
                                <v-btn
                                    :loading="isLoading"
                                    color="primary"
                                    @click="updateParticulars"
                                >
                                    {{ $t('message.submit') }}
                                </v-btn>
                            </v-row>
                        </validation-observer>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    AUTHENTICATION_MODULE_NAME,
    STUDIO_MODULE_NAME,
} from '@/store/moduleNames'
import { editUser } from '@/util/cloudFunctions/editUser'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import DatePicker from '../shared/DatePicker.vue'
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            isBrandedApp: `${AUTHENTICATION_MODULE_NAME}/isBrandedApp`,
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
            brandedAppsInReview: `${STUDIO_MODULE_NAME}/brandedAppsInReview`,
        }),
        studioId() {
            return this.studio.id
        },
    },
    components: {
        ValidationObserver,
        ValidationProvider,
        DatePicker,
    },
    data() {
        return {
            dialog: false,
            dob: this.user.dob,
            errorMessage: '',
            fullName: this.user.fullName,
            isLoading: false,
            snackbar: false,
            username: this.user.username,
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false
        },
        async updateParticulars() {
            this.isLoading = true
            this.errorMessage = ''

            const isInputValid = await this.$refs.observer.validate()
            if (!isInputValid) {
                this.errorMessage = this.$t('message.checkInput')
                this.isLoading = false
                return
            }

            const { success, error } = await editUser({
                dob: this.dob,
                fullName: this.fullName,
                username: this.username,
                userId: this.user.id,
                profilePhoto: this.user.profilePhoto,
            })

            if (!success) {
                this.isLoading = false
                this.errorMessage = error
                return
            }

            await this.$store.dispatch(
                `${AUTHENTICATION_MODULE_NAME}/refreshUserDocument`
            )
            this.isLoading = false
            this.snackbar = true
            this.closeDialog()
        },
    },
    props: {
        user: Object,
    },
}
</script>
