<template>
    <div>
        <div class="d-flex flex-row justify-space-between">
            <div class="d-flex flex-row">
                <div class="pr-1 question-text">
                    <span v-html="question"></span>
                </div>
                <div class="red--text" v-if="isRequired">*</div>
            </div>
            <v-tooltip
                max-width="70%"
                open-on-click
                close-on-back
                origin="top"
                right
                v-if="description"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="grey" v-bind="attrs" v-on="on" class="mr-2"
                        >mdi-information</v-icon
                    >
                </template>
                <span>{{ description }}</span>
            </v-tooltip>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        question: String,
        description: String,
        isRequired: Boolean,
    },
}
</script>
