<template>
    <span>
        <v-dialog v-model="dialog" width="unset">
            <FamilyUsers
                :userId="classSignup.userId"
                :isSelectable="true"
                @selectFamilyUser="assignFamilyUser"
                :isDialog="true"
                @closeDialog="closeDialog"
            />
        </v-dialog>

        <v-btn text small @click.stop="openDialog" v-on:click.prevent>
            <span>
                {{
                    classSignup.familyUserId
                        ? classSignup.familyUserFullName
                        : "I'm Attending"
                }}
            </span>
            <v-icon class="ml-1" small>mdi-pencil</v-icon>
        </v-btn>
    </span>
</template>
<script>
import FamilyUsers from '@/components/familyusers/FamilyUsers'

// Analytics
import { assignFamilyUser } from '../../util/cloudFunctions/familyUsers'

export default {
    components: {
        FamilyUsers,
    },
    props: {
        classSignup: Object,
    },
    data() {
        return {
            dialog: false,
            loading: false,
            error: '',
        }
    },
    methods: {
        openDialog() {
            this.dialog = true
        },
        closeDialog() {
            this.dialog = false
        },
        async assignFamilyUser(familyUser) {
            this.closeDialog()
            this.error = ''
            this.loading = true
            await assignFamilyUser(
                familyUser ? familyUser.id : null,
                this.classSignup.id
            )
            this.handleSuccess()
            this.$gtag.event('assign_family_user')
            this.loading = false
        },
        handleSuccess() {
            this.$emit('success')
        },
    },
}
</script>
