import { storage } from '../../firebase'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'

export class StorageService {
    static async uploadFile(path, fileName, ext, file) {
        if (file.size > 50000000) {
            throw Error('File size too large')
        }

        const reference = ref(storage, `${path}/${fileName}.${ext}`)
        await uploadBytes(reference, file)
        return await getDownloadURL(reference)
    }
}
