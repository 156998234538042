<template>
    <v-col align="center" class="pb-0">
        <lottie-animation
            path="lottie/greentick.json"
            :height="200"
            :width="200"
        />
        <p v-if="!isWaitlistClass">{{ $t('message.bookingSuccess') }}</p>
        <p v-else>
            {{ $t('message.waitlistSuccess') }}
        </p>
        <GuestsTable
            v-if="isFamilyBookable && classId && !isWaitlistClass"
            :classId="classId"
        />
        <v-btn
            v-if="packageItem.isPackageShareable && !isBookClass"
            color="primary"
            width="40%"
            depressed
            @click="openDialog"
            >{{ $t('message.sharePackage') }}</v-btn
        >

        <v-dialog v-model="dialog" width="600px">
            <v-card>
                <v-card-title>
                    <v-col class="pb-0">
                        <v-row class="justify-end">
                            <v-icon @click="closeDialog">mdi-close</v-icon>
                        </v-row>
                    </v-col>
                </v-card-title>
                <v-card-text>
                    <PackageShareWithCard
                        :packageItem="this.packageItem"
                        @shareAfterPurchase="getPackageItem"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-col>
</template>
<script>
import { SHARE_PACKAGE } from '@/analytics/events'
import PackageShareWithCard from '@/components/packages/PackageShareWithCard'
import GuestsTable from '@/components/guests/GuestsTable'
import { getSharedMembers } from '../../util/packages/getSharedMembers'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue' // import lottie-vuejs
export default {
    props: {
        packageId: String,
        isBookClass: {
            default: false,
            type: Boolean,
        },
        isWaitlistClass: {
            default: false,
            type: Boolean,
        },
        classId: String,
        isFamilyBookable: {
            default: false,
            type: Boolean,
        },
    },
    components: {
        PackageShareWithCard,
        LottieAnimation,
        GuestsTable,
    },
    data() {
        return {
            dialog: false,
            users: [],
            packageItem: Object,
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false
        },
        openDialog() {
            this.$gtag.event(SHARE_PACKAGE)
            this.dialog = true
        },
        async getPackageItem() {
            this.users = await getSharedMembers(this.packageId)
            this.packageItem = { ...this.users[0], id: this.packageId }
        },
    },
    async created() {
        this.getPackageItem()
    },
}
</script>
