<template>
    <div>
        <v-card class="pt-2 px-4">
            <!-- Show skeleton loader if loading -->
            <v-skeleton-loader v-if="loading" class="mx-auto" type="article" />
            <v-col v-else>
                <!-- Display studio details and edit button -->
                <v-row class="justify-space-between align-center">
                    <v-card-title class="ml-n4">{{
                        $t('message.studioDetailsTitle')
                    }}</v-card-title>

                    <v-icon
                        v-if="!localIsEditMode"
                        @click="() => (localIsEditMode = true)"
                        >mdi-pencil</v-icon
                    >

                    <v-icon
                        v-else-if="!hasUnfilledCustomFields && localIsEditMode"
                        @click="handleClose"
                        >mdi-close</v-icon
                    >
                </v-row>
            </v-col>

            <!-- Text to inform user to fill in required fields before proceeding with purchase -->
            <v-row class="pb-4" v-if="hasUnfilledCustomFields && !loading">
                <v-card-text>
                    {{ $t('message.fillRequiredFields') }}
                </v-card-text>

                <v-divider class="px-4"></v-divider>
            </v-row>

            <!-- CustomFieldsDetails component to display custom fields -->
            <ValidationObserver ref="observer">
                <CustomFieldsDetails
                    :studioId="studioId"
                    :isEditMode="localIsEditMode"
                    @isValueChanged="(bool) => (this.isValueChanged = bool)"
                    @isLoading="isLoading"
                    @studioCustomData="setStudioCustomData"
                    :key="customFieldKey"
                    ref="CustomFieldsDetails"
                />
            </ValidationObserver>

            <!-- Dialog for confirming close of studio details when changes have been made -->
            <v-dialog v-model="dialog" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">{{
                        $t('message.confirmAction')
                    }}</v-card-title>
                    <v-card-text>
                        {{ $t('message.confirmCloseStudioDetails') }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="dialog = false">
                            {{ $t('message.cancel') }}
                        </v-btn>
                        <v-btn text @click="confirmClose">
                            {{ $t('message.okay') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-col align="center">
                <v-btn
                    v-if="localIsEditMode"
                    class="primary"
                    @click="submit"
                    :loading="submitting"
                >
                    {{ $t('message.submit') }}
                </v-btn>
            </v-col>
        </v-card>

        <!-- Snackbar for displaying success or error messages -->
        <v-snackbar
            v-model="snackbar.show"
            :color="snackbar.color"
            :timeout="snackbar.timeout"
            top
        >
            <div class="white--text">{{ snackbar.text }}</div>
        </v-snackbar>
    </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { editStudioMemberCustomField } from '@/util/cloudFunctions/studioMemberCustomFieldFunctions'
import CustomFieldsDetails from './CustomFieldsDetails.vue'
import {
    AUTHENTICATION_MODULE_NAME,
    CUSTOM_FIELDS_MODULE_NAME,
} from '@/store/moduleNames'
import { mapGetters, mapMutations } from 'vuex'
import { parseStudioCustomData } from '@/components/profile/CustomFieldsUtils'

export default {
    components: {
        ValidationObserver,
        CustomFieldsDetails,
    },
    computed: {
        ...mapGetters({
            user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
        }),
    },
    props: {
        studioId: String,
        isEditMode: Boolean,
        hasUnfilledCustomFields: Boolean,
    },
    data() {
        return {
            snackbar: {
                color: 'success',
                timeout: 2000,
                show: false,
                text: '',
            },
            loading: true,
            submitting: false,
            error: '',
            studioCustomData: [],
            localIsEditMode: this.isEditMode,
            customFieldKey: true,
            isValueChanged: false,
            dialog: false,
        }
    },
    methods: {
        ...mapMutations({
            setHasUnfilledCustomFields: `${CUSTOM_FIELDS_MODULE_NAME}/setHasUnfilledCustomFields`,
        }),
        isLoading(loading) {
            this.loading = loading
        },
        setStudioCustomData(data) {
            this.studioCustomData = data
            this.$emit('studioCustomData', data)
        },
        async validate() {
            return await this.$refs.observer.validate()
        },
        handleClose() {
            if (this.isValueChanged) {
                this.dialog = true
            } else {
                this.localIsEditMode = false
            }
        },
        confirmClose() {
            this.dialog = false
            this.localIsEditMode = false
            this.$refs.CustomFieldsDetails.loadData()
        },
        async submit() {
            this.$refs.CustomFieldsDetails.onSubmit()
            this.error = ''

            const isValid = await this.validate()

            if (!isValid) {
                this.snackbar.color = 'error'
                this.snackbar.show = true
                this.snackbar.text = 'Unable to submit! Check your inputs!'
                return
            }

            if (!this.isValueChanged) {
                this.snackbar.color = 'success'
                this.snackbar.show = true
                this.snackbar.text = 'No changes made!'
                this.localIsEditMode = false
                return
            }

            const memberCustomData = parseStudioCustomData(
                this.studioCustomData
            )

            const payload = {
                userId: this.user.id,
                studioId: this.studioId,
                memberCustomData: memberCustomData,
            }

            this.submitting = true

            const updateResponse = await editStudioMemberCustomField(payload)

            if (!updateResponse.success) {
                this.snackbar.color = 'error'
                this.snackbar.show = true
                this.snackbar.text = 'Oops, something went wrong!'
            } else {
                this.localIsEditMode = false
                this.customFieldKey = !this.customFieldKey // Force re-rendering of custom fields
                this.snackbar.color = 'success'
                this.snackbar.show = true
                this.snackbar.text = 'Success'

                // commit to store, will only reach here if all required fields are filled
                this.setHasUnfilledCustomFields(false)
            }
            this.submitting = false
            this.localIsEditMode = false
            this.$emit('success') // for stepper to move to next step
        },
    },
}
</script>
