<template>
    <v-row class="mx-0">
        <v-col cols="12" class="date-box-wrapper">
            <div ref="container" class="d-flex date-box-container">
                <div class="date-nav" @click="changeMonday('decrease')">
                    <v-icon v-if="!isMobile" class="appFontColor--text"
                        >mdi-arrow-left-drop-circle-outline</v-icon
                    >
                </div>

                <div
                    class="date-box"
                    :class="{
                        active: d.value === value,
                    }"
                    v-for="(d, i) of listDates"
                    :key="i"
                    @click="chooseDate(d.value)"
                >
                    <div>{{ d.dayName }}</div>
                    <div>{{ d.day }}</div>
                    <div>{{ d.month }}</div>
                </div>

                <div class="date-nav" @click="changeMonday('increase')">
                    <v-icon v-if="!isMobile" class="appFontColor--text"
                        >mdi-arrow-right-drop-circle-outline</v-icon
                    >
                </div>
            </div>
            <v-row v-if="isMobile" class="mb-1">
                <v-col cols="6">
                    <div @click="changeMonday('decrease')">
                        <v-btn block>
                            <v-icon class="pr-3"> mdi-arrow-left </v-icon>
                            Prev Dates
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="6">
                    <div @click="changeMonday('increase')">
                        <v-btn block>
                            Next Dates
                            <v-icon class="pl-3"> mdi-arrow-right </v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { dateToString } from '@/util/datetimehelpers'

export default {
    name: 'DateBoxes',

    props: {
        value: String,
    },

    data: () => ({
        mondayTheWeek: null,
    }),

    created() {
        this.mondayTheWeek = this.getMondayOfWeek(this.getTodayDate())
    },

    mounted() {
        this.$refs.container.addEventListener('touchstart', this.handleScroll)
        this.$refs.container.addEventListener('touchend', this.handleScroll)
    },

    beforeDestroy() {
        this.$refs.container.removeEventListener(
            'touchstart',
            this.handleScroll
        )
        this.$refs.container.removeEventListener('touchend', this.handleScroll)
    },

    methods: {
        handleScroll(e) {
            e.stopPropagation()
        },

        getTodayDate() {
            const date = new Date()
            date.setTime(date.getTime() - date.getTimezoneOffset() * 60000)
            return date
        },

        changeMonday(value) {
            const date = new Date(this.mondayTheWeek)
            if (value === 'increase') {
                date.setDate(date.getDate() + 7)
            } else if (value === 'decrease') {
                date.setDate(date.getDate() - 7)
            }
            this.mondayTheWeek = date
        },

        chooseDate(date) {
            this.$emit('input', date)
        },

        getMondayOfWeek(weekday) {
            weekday.setTime(
                weekday.getTime() + weekday.getTimezoneOffset() * 60000
            )
            const monday = new Date(weekday)
            // 7 stand for Sunday instead of 0
            monday.setDate(weekday.getDate() - (weekday.getDay() || 7) + 1)
            return monday
        },
    },

    computed: {
        listDates() {
            const listDates = []
            for (let i = 0; i <= 6; i++) {
                const date = new Date(this.mondayTheWeek)
                date.setDate(date.getDate() + i)
                const dayName = date.toLocaleDateString('en', {
                    weekday: 'short',
                })
                const day = date.getDate()
                const month = date.toLocaleDateString('en', { month: 'short' })
                const value = dateToString(date)
                listDates.push({ dayName, day, month, value })
            }
            return listDates
        },
        isMobile() {
            return this.$vuetify.breakpoint.name == 'xs'
        },
    },

    watch: {
        value(v) {
            this.mondayTheWeek = this.getMondayOfWeek(new Date(v))
        },
    },
}
</script>

<style lang="scss" scoped>
.date-box-wrapper {
    background-color: var(--v-background-base);
    padding: 0;
}

.date-box-container {
    padding: 12px 0px;
    align-items: center;
    overflow: scroll;

    @media (min-width: 600px) {
        padding: 12px;
        justify-content: center;
        overflow: auto;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.date-box {
    cursor: pointer;
    width: 100%;
    background-color: var(--v-card-base);
    border-radius: 7px;
    padding: 10px 20px;
    margin: 0px 10px;
    text-align: center;
    box-shadow: 0 0 10px #00000055;

    .theme--dark & {
        box-shadow: 0 0 10px #ffffff33;
    }

    &.active {
        background-color: var(--v-ordinary-base);
        color: var(--v-ordinaryInverted-base);
        box-shadow: inset 0 0 10px var(--v-ordinaryInverted-base);
    }

    @media (max-width: 600px) {
        width: 100%;
        padding: 0;
        margin: 0px 4px;
    }
}

.date-nav {
    cursor: pointer;

    .v-icon {
        color: #fff;
    }
}
</style>
