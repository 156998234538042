import { auth } from '@/firebase'
import getUserDocument from '@/util/cloudFunctions/userFunctions'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import createUserAndAddAsMember from '@/util/cloudFunctions/authentication'
export const actionsAuth = {
    authAction({ commit }) {
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                const studio = this.getters[`${STUDIO_MODULE_NAME}/getStudio`]
                await setUserDocument(commit, studio?.id)
            } else {
                commit('setUser', null)
            }
            commit('setLoading', false)
        })
    },
    setBrandedApp({ commit }) {
        commit('setBrandedApp', true)
    },
    closeCollectUserDetails({ commit }) {
        commit('setCollectUserDetails', false)
    },
    refreshUserDocument({ commit }) {
        setUserDocument(commit)
    },
}

async function setUserDocument(commit, studioId) {
    const result = await getUserDocument(auth.currentUser.uid)
    if (result.success) {
        const userDoc = result.doc
        // create member if not exist after login
        if (studioId) {
            createUserAndAddAsMember(
                studioId,
                userDoc.fullName,
                userDoc.email,
                userDoc.username,
                userDoc.dob,
                userDoc.personalDetails?.mobileNum,
                null,
                userDoc.country,
                userDoc.id,
                null
            )
        }
        commit('setUser', userDoc)
    } else {
        // Logged in but cannot find user document, so we need to collect the user details
        // Only happens with external auth
        if (auth.currentUser.providerData[0].providerId !== 'password') {
            commit('setUser', { id: auth.currentUser.uid })
            commit('setCollectUserDetails', true)
        }
    }
}
