<template>
    <v-dialog v-model="dialog" persistent width="400">
        <v-card>
            <lottie-animation
                v-if="animationKey > 0"
                :key="animationKey"
                :options="defaultOptions"
                :width="dimensions"
                :height="dimensions"
            />
            <h3 class="text-center mb-0 pb-3">{{ text }}</h3>
            <v-card-text>
                <slot></slot>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { replaceColor } from 'lottie-colorify'
import LottieAnimation from 'vue-lottie'
import animationData from '@/assets/paymentloading.json'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import { mapGetters } from 'vuex'
export default {
    components: {
        LottieAnimation,
    },
    created() {
        const primaryColor = this.studio.primaryColor
        if (primaryColor) {
            const colored = replaceColor('#0373E3', primaryColor, animationData)
            this.animation = colored
        }
        this.animationKey++
    },
    data() {
        return {
            animationColor: '#000000', // Default color
            animation: animationData,
            animationKey: 0,
        }
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        defaultOptions() {
            return {
                animationData: this.animation,
            }
        },
        dimensions() {
            return this.$vuetify.breakpoint.mdAndUp ? 250 : 150
        },
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>
