<template>
    <v-col>
        <p>{{ $t('message.packages') }}</p>
        <v-row>
            <v-col class="text--secondary" v-if="packages.length === 0">
                {{ $t('message.packagesNotAvailable') }}
            </v-col>
            <v-col
                cols="12"
                md="6"
                v-for="(packageItem, index) in packages"
                :key="index"
            >
                <PackageCard
                    :packageItem="packageItem"
                    :isBuyable="isBuyable"
                    @sharePackage="sharePackage"
                />
            </v-col>
        </v-row>

        <p class="mt-5">
            {{ $t('message.packagesRecurring') }}
            <v-tooltip v-model="tooltip" top>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" @click="tooltip = !tooltip"
                        >mdi-help-circle-outline</v-icon
                    >
                </template>
                <span>{{ $t('message.packagesRecurringDescription') }}</span>
            </v-tooltip>
        </p>

        <v-row class="mt-3">
            <v-col
                class="text--secondary"
                v-if="recurringPackages.length === 0"
            >
                {{ $t('message.packagesRecurringNotAvailable') }}
            </v-col>
            <v-col
                v-else
                cols="12"
                md="6"
                v-for="(packageItem, index) in recurringPackages"
                :key="index"
            >
                <PackageCard
                    :packageItem="packageItem"
                    :isBuyable="isBuyable"
                    @cancelStatus="cancelStatus"
                />
            </v-col>
        </v-row>
    </v-col>
</template>
<script>
import PackageCard from '@/components/packages/PackageCard'
export default {
    components: {
        PackageCard,
    },
    props: {
        packages: Array,
        recurringPackages: Array,
        isBuyable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            tooltip: false,
        }
    },
    methods: {
        sharePackage() {
            this.$emit('sharePackage')
        },
        cancelStatus(isSuccess, error) {
            this.$emit('cancelStatus', isSuccess, error)
        },
    },
}
</script>
