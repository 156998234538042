<template>
    <div class="merchandise-page">
        <v-col cols="12" class="align-end" v-if="isWidget">
            <UserAuth />
        </v-col>
        <v-row class="my-5">
            <v-col cols="12" lg="3">
                <h1>{{ $t('message.products') }}</h1>
            </v-col>

            <v-col cols="12" lg="9">
                <div class="d-flex">
                    <v-text-field
                        :placeholder="
                            this.$t('message.searchProducts', {
                                studioName: studioName,
                            })
                        "
                        v-model="searchTerm"
                    />
                    <v-btn
                        color="primary"
                        class="ml-4 d-lg-none"
                        @click="filterIsShown = !filterIsShown"
                    >
                        <span class="mdi mdi-filter"></span>
                        {{ $t('message.filter').toLocaleUpperCase() }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <v-row class="my-5">
            <v-col cols="12" lg="3">
                <v-scroll-y-reverse-transition>
                    <MerchandiseFilter
                        ref="filter"
                        v-show="filterIsShown"
                        @priceFiltered="priceFilter = $event"
                        @categoryFiltered="categoryFilter = $event"
                    />
                </v-scroll-y-reverse-transition>
            </v-col>

            <v-col cols="12" lg="9">
                <MerchandiseList
                    ref="merchandise-list"
                    :loading="loading"
                    :merchandise="merchandise"
                    :search-term="searchTerm"
                    :price-filter="priceFilter"
                    :category-filter="categoryFilter"
                    @addToCart="setNumberOfCartItems"
                />
            </v-col>
        </v-row>

        <!-- View Cart Button -->
        <div class="viewCartButton">
            <v-badge :content="numberOfCartItems" :value="isShowBadge">
                <v-btn class="secondary" x-large @click="navigateToCartPage">
                    <v-icon left>mdi-cart</v-icon> {{ $t('message.viewCart') }}
                </v-btn>
            </v-badge>
        </div>
    </div>
</template>

<script>
import MerchandiseFilter from '@/components/merchandise/MerchandiseFilter.vue'
import MerchandiseList from '@/components/merchandise/MerchandiseList.vue'
import UserAuth from '@/components/auth/UserAuth'

import { getProducts } from '@/util/cloudFunctions/merchandiseFunctions'
import { getNumberOfCartItems } from '@/util/cloudFunctions/shoppingCart'

import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import { mapGetters } from 'vuex'

import { WIDGET_ROUTE } from '@/constants'

export default {
    components: {
        MerchandiseFilter,
        MerchandiseList,
        UserAuth,
    },

    data() {
        return {
            studioName: '',
            searchTerm: '',
            priceFilter: '',
            categoryFilter: '',
            filterIsShown: false,
            loading: true,
            merchandise: [],
            numberOfCartItems: 0,
        }
    },

    created() {
        this.setFilterVisibility()
        window.addEventListener('resize', this.setFilterVisibility, {
            passive: true,
        })
        this.initData()
        this.setNumberOfCartItems()
    },

    destroy() {
        window.removeEventListener('resize', this.setFilterVisibility, {
            passive: true,
        })
    },

    methods: {
        setFilterVisibility() {
            this.filterIsShown = window.innerWidth >= 1264
        },
        async initData() {
            this.loading = true
            const result = await getProducts(this.studio.id)
            if (!result.success) {
                this.loading = false
                return
            }
            this.merchandise = result.docs
            this.loading = false
        },
        async setNumberOfCartItems() {
            const result = await getNumberOfCartItems(this.studio.id)
            if (result.success) {
                this.numberOfCartItems = result.count
            }
        },
        navigateToCartPage() {
            const hash = this.$route.hash
            const studioRoute = this.$route.params.studioRoute
            this.$router.push(`/${studioRoute}/merchandise/cart${hash}`)
        },
    },

    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        isShowBadge() {
            return this.numberOfCartItems > 0
        },
        isWidget() {
            return this.$route.hash === WIDGET_ROUTE
        },
    },
}
</script>

<style scoped lang="scss">
.viewCartButton {
    position: fixed;
    bottom: 10px;
    right: 23px;
    z-index: 100;

    @media screen and (min-width: 1264px) {
        bottom: 100px;
        right: 100px;
    }
}
.merchandise-page {
    padding-bottom: 42px;
    ::v-deep {
        .v-input {
            margin-top: 0;
            padding-top: 0;
        }

        .v-messages {
            display: none;
        }
    }

    .filter-is-shown {
        display: block;
    }
}
</style>
