<template>
    <v-container>
        <v-btn color="error" outlined dark @click="openDialog">{{
            $t('message.deleteAccount')
        }}</v-btn>
        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title>{{ $t('message.deleteAccount') }}</v-card-title>
                <v-card-text>
                    <v-row justify="center" v-if="success">
                        <lottie-animation
                            path="lottie/greentick.json"
                            :height="200"
                            :width="200"
                        />
                        <p class="text-center">
                            {{
                                $t('message.deleteAccountRequestReceived', {
                                    vibefamEmail: 'contact@vibefam.com',
                                })
                            }}
                        </p>
                    </v-row>
                    <div v-else>
                        <p class="error--text">
                            <b>{{ $t('message.warning') }}:</b>
                            {{ $t('message.deleteAccountWarning') }}
                        </p>
                        <v-text-field
                            prepend-icon="mdi-lock"
                            :append-icon="
                                passwordToggle ? 'mdi-eye-off' : 'mdi-eye'
                            "
                            v-model="password"
                            :label="this.$t('message.password')"
                            @click:append="
                                () => (passwordToggle = !passwordToggle)
                            "
                            :type="passwordToggle ? 'password' : 'text'"
                        ></v-text-field>
                    </div>
                    <p ref="error" class="error--text"></p>
                </v-card-text>
                <v-card-actions v-if="!success">
                    <v-btn
                        color="primary"
                        @click="confirmDeletion"
                        :loading="hasSubmitted"
                    >
                        {{ $t('message.confirm') }}</v-btn
                    >
                    <v-btn
                        @click="closeDialog"
                        outlined
                        :disabled="hasSubmitted"
                        >{{ $t('message.cancel') }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {
    auth,
    EmailAuthProvider,
    reauthenticateWithCredential,
} from '@/firebase'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue' // import lottie-vuejs
export default {
    components: {
        LottieAnimation,
    },
    data() {
        return {
            dialog: false,
            password: '',
            showSuccess: false,
            passwordToggle: true,
            hasSubmitted: false,
            success: false,
        }
    },
    methods: {
        openDialog() {
            this.dialog = true
        },
        closeDialog() {
            this.dialog = false
        },
        confirmDeletion() {
            // Perform account deletion logic here
            // For demonstration purposes, we'll simply show a success message
            this.hasSubmitted = true
            this.showSuccess = true
            this.$refs.error.innerHTML = ''

            const user = auth.currentUser
            const cred = EmailAuthProvider.credential(user.email, this.password)
            reauthenticateWithCredential(user, cred)
                .then(() => {
                    // Reset the password field
                    this.password = ''

                    // Close the dialog after a delay (you can modify the delay time as needed)
                    setTimeout(() => {
                        this.hasSubmitted = false
                        this.success = true
                    }, 2000)
                })
                .catch(() => {
                    this.$refs.error.innerHTML = this.$t(
                        'message.incorrectPassword'
                    )
                    this.hasSubmitted = false
                })
        },
    },
}
</script>
