<template>
    <v-fade-transition>
        <v-alert v-if="success" type="success">
            {{ $t('message.paymentSuccess') }}
        </v-alert>
        <v-alert v-else-if="success == false" type="error">
            {{ $t('message.paymentFailure') }}
        </v-alert>
    </v-fade-transition>
</template>
<script>
import { retrieveCheckoutSessionStatus } from '@/util/cloudFunctions/stripe'
import { mapGetters } from 'vuex'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
export default {
    data() {
        return {
            success: undefined,
        }
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        stripeAccountId() {
            return this.studio.stripeAccountId
        },
    },
    watch: {
        '$route.query.session_id': {
            handler: async function (sessionId) {
                if (!sessionId) {
                    return
                }
                if (!this.stripeAccountId) {
                    this.success = false
                    return
                }
                const result = await retrieveCheckoutSessionStatus(
                    sessionId,
                    this.stripeAccountId
                )
                this.success = result.success
            },
            immediate: true,
        },
    },
}
</script>
