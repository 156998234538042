<template>
    <ValidationObserver ref="observer">
        <div v-if="noEmailCase">
            <v-alert type="error">
                {{ $t('message.signupErrorFacebookEmail') }}<br />
            </v-alert>
            <v-col align="center">
                <a
                    href="https://www.facebook.com/settings?tab=account&section=email&view"
                    target="_blank"
                    >{{ $t('message.signupVerifyFacebookEmail') }}</a
                >
            </v-col>
        </div>

        <v-skeleton-loader
            v-if="loading"
            type="heading, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, divider, table-tbody"
        />
        <v-form ref="form" v-show="!loading">
            <div :style="{ minWidth: componentMinWidth + 'px' }"></div>
            <!-- Title -->
            <v-card-title>
                <v-col>
                    <v-row class="justify-center">
                        <h4 class="ml-2">{{ $t('message.signup') }}</h4>
                    </v-row>
                </v-col>
            </v-card-title>

            <!-- Email Field -->
            <ValidationProvider
                v-if="!collectUserDetails"
                v-slot="{ errors }"
                :name="$t('message.email')"
                rules="required|max:64|email"
                :disabled="collectUserDetails"
            >
                <text-trimmer
                    aria-readonly="true"
                    :label="$t('message.email')"
                    v-model="email"
                    prepend-icon="mdi-email"
                    :error-messages="errors"
                ></text-trimmer>
            </ValidationProvider>

            <!-- Password Field -->
            <ValidationProvider
                v-if="!collectUserDetails"
                :name="$t('message.password')"
                rules="required|max:64"
                v-slot="{ errors }"
            >
                <v-text-field
                    :label="$t('message.password')"
                    v-model="password"
                    prepend-icon="mdi-lock"
                    :error-messages="errors"
                    :append-icon="passwordToggle ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="() => (passwordToggle = !passwordToggle)"
                    :type="passwordToggle ? 'password' : 'text'"
                ></v-text-field>
            </ValidationProvider>

            <!-- Full Name -->
            <ValidationProvider
                v-slot="{ errors }"
                :name="$t('message.fullName')"
                :rules="{
                    required: true,
                    fullNameRegex: fullNameRegex,
                    min: 4,
                }"
            >
                <text-trimmer
                    :label="$t('message.fullName')"
                    v-model="fullName"
                    prepend-icon="mdi-text"
                    :error-messages="errors"
                ></text-trimmer>
            </ValidationProvider>

            <!-- Username -->
            <ValidationProvider
                v-slot="{ errors }"
                :name="$t('message.username')"
                :rules="{
                    required: true,
                    usernameRegex: usernameRegex,
                    min: 4,
                    max: 17,
                }"
            >
                <text-trimmer
                    :label="$t('message.username')"
                    :hint="$t('message.usernameRequirement')"
                    persistent-hint
                    :error-messages="errors"
                    v-model="username"
                    prepend-icon="mdi-account"
                ></text-trimmer>
            </ValidationProvider>

            <!-- Date of Birth -->
            <ValidationProvider
                v-slot="{ errors }"
                :name="$t('message.dob')"
                rules="required"
                v-if="!brandedAppsInReview.includes(studioId)"
            >
                <date-picker
                    :label="$t('message.dob')"
                    v-model="dob"
                    :errors="errors"
                    :max="new Date().toISOString().substr(0, 10)"
                    :outlined="false"
                    class="pt-3"
                />
            </ValidationProvider>

            <!-- Country -->
            <ValidationProvider
                v-slot="{ errors }"
                :name="$t('message.country')"
                rules="required"
            >
                <v-select
                    :label="$t('message.country')"
                    :error-messages="errors"
                    v-model="country"
                    prepend-icon="mdi-earth"
                    :items="supportedCountries"
                    item-text="name"
                    item-value="code"
                />
            </ValidationProvider>

            <!-- Phone Number -->
            <ValidationProvider
                rules="required"
                v-if="!brandedAppsInReview.includes(studioId)"
            >
                <vue-phone-number-input
                    v-model="phoneNumberInput"
                    :default-country-code="country"
                    :preferred-countries="['SG', 'MY', 'VN', 'ID', 'US']"
                    :required="true"
                    :error="phoneNumberInput != '' && !phoneNumberObj.isValid"
                    clearable
                    @update="onPhoneNumberChange"
                    :dark="$vuetify.theme.dark"
                />
                <v-col class="error-text" v-if="phoneNumberError !== ''">
                    {{ phoneNumberError }}
                </v-col>
            </ValidationProvider>

            <p class="error--text text-center py-3" v-if="error !== ''">
                {{ error }}
            </p>

            <!-- Studio Custom Field -->
            <div v-show="!isCustomFieldsEmpty">
                <v-divider class="mt-10 mb-6"></v-divider>

                <v-row justify="center">
                    <v-card-title>
                        {{ $t('message.studioDetailsTitle') }}</v-card-title
                    >
                    <v-card-text>
                        {{
                            $t('message.studioDetailsDescription', {
                                studioName: studioName,
                            })
                        }}
                    </v-card-text>
                </v-row>

                <CustomFieldsDetails
                    class="pt-6"
                    :studioId="studioId"
                    :isEditMode="true"
                    :showRedBackground="false"
                    ref="CustomFieldsDetails"
                    @studioCustomData="setStudioCustomData"
                    @isLoading="(loading) => (this.loading = loading)"
                />
            </div>

            <v-row justify="center" class="my-3">
                <v-btn
                    class="primary auth-btn"
                    @click="submit"
                    :loading="isSubmitting"
                    depressed
                >
                    {{ $t('message.signup') }}
                </v-btn>
                <!-- for facebook signup -->
            </v-row>
            <v-divider
                v-if="!collectUserDetails"
                class="mt-10 mb-7"
            ></v-divider>
            <FacebookLogin v-if="!collectUserDetails && !isBrandedApp" />
        </v-form>
        <v-snackbar v-model="snackbar.show" color="error" timeout="2000" top>
            <div class="white--text">
                {{ snackbar.text }}
            </div>
        </v-snackbar>
    </ValidationObserver>
</template>

<script>
import FacebookLogin from '@/components/auth/FacebookLogin'
import CustomFieldsDetails from '@/components/profile/CustomFieldsDetails.vue'
import { parseStudioCustomData } from '@/components/profile/CustomFieldsUtils'
import DatePicker from '@/components/shared/DatePicker.vue'
import TextTrimmer from '@/components/shared/TextTrimmer.vue'
import {
    auth,
    createUserWithEmailAndPassword,
    deleteUser,
    sendEmailVerification,
} from '@/firebase'
import i18n from '@/i18n'
import {
    AUTHENTICATION_MODULE_NAME,
    STUDIO_MODULE_NAME,
} from '@/store/moduleNames'
import getAuthErrorMessage from '@/util/authErrors'
import createUserAndAddAsMember from '@/util/cloudFunctions/authentication'
import getSupportedCountries from '@/util/cloudFunctions/countries'
import { fullNameRegex, usernameRegex } from '@/util/regex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'

// Analytics
import {
    SIGNUP,
    SIGNUP_ERROR,
    SIGNUP_FACEBOOK,
    SIGNUP_FACEBOOK_ERROR,
} from '@/analytics/events'
import { subStr } from '@/analytics/util'

export default {
    props: {
        collectUserDetails: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            isBrandedApp: `${AUTHENTICATION_MODULE_NAME}/isBrandedApp`,
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
            brandedAppsInReview: `${STUDIO_MODULE_NAME}/brandedAppsInReview`,
        }),
        studioId() {
            return this.studio.id
        },
        studioName() {
            return this.studio.studioName
        },
        isCustomFieldsEmpty() {
            return this.studioCustomData.length === 0
        },
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        CustomFieldsDetails,
        TextTrimmer,
        DatePicker,
        FacebookLogin,
    },
    data() {
        return {
            email: '',
            password: '',
            fullName: '',
            username: '',
            dob: '',
            country: 'SG',
            phoneNumberInput: '',
            phoneNumberObj: {},
            phoneNumberError: '',
            error: '',
            loading: false,
            passwordToggle: true,
            supportedCountries: [{ name: 'Singapore', code: 'SG' }],
            fullNameRegex,
            usernameRegex,
            noEmailCase: false,
            studioCustomData: {},
            snackbar: {
                show: false,
                text: '',
            },
            isSubmitting: false,
            componentMinWidth: '0',
        }
    },
    mounted() {
        this.updateMinWidth()
        window.addEventListener('resize', this.updateMinWidth)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateMinWidth)
    },
    methods: {
        ...mapActions({
            refreshUserDocument: `${AUTHENTICATION_MODULE_NAME}/refreshUserDocument`,
        }),
        async validate() {
            return await this.$refs.observer.validate()
        },
        updateMinWidth() {
            if (this.$refs.form.$el.clientWidth > this.componentMinWidth) {
                this.componentMinWidth = this.$refs.form.$el.clientWidth
            }
        },
        setErrorSnackBar(errorMsg) {
            this.snackbar.text = errorMsg
            this.snackbar.show = true
        },
        setStudioCustomData(data) {
            this.studioCustomData = data
        },

        async submit() {
            this.error = ''
            this.isSubmitting = true
            this.$refs.CustomFieldsDetails.onSubmit()

            if (!this.isBrandedApp) {
                if (!this.phoneNumberInput) {
                    this.phoneNumberError = i18n.t('message.fieldNoEmpty', {
                        field: i18n.t('message.mobileNumber'),
                    })
                } else if (!this.phoneNumberObj.isValid) {
                    this.phoneNumberError = i18n.t('message.invalidPhoneNumber')
                }

                const isValid =
                    (await this.validate()) &&
                    (this.brandedAppsInReview.includes(this.studioId) ||
                        this.phoneNumberObj.isValid)

                if (!isValid) {
                    this.isSubmitting = false
                    this.setErrorSnackBar(i18n.t('message.signUpSnackbarError'))
                    return
                }
            }

            this.phoneNumberError = ''

            const memberCustomData = parseStudioCustomData(
                this.studioCustomData
            )

            if (this.collectUserDetails) {
                const { uid, email } = auth.currentUser
                const result = await createUserAndAddAsMember(
                    this.studio.id,
                    this.fullName,
                    email,
                    this.username,
                    this.dob,
                    this.phoneNumberObj.formattedNumber,
                    undefined, // no password for collecting user details
                    this.country,
                    uid,
                    memberCustomData
                )
                if (!result.success) {
                    this.$gtag.event(SIGNUP_FACEBOOK_ERROR, {
                        errorMessage: subStr(result.error),
                    })
                    this.error = result.error
                    this.isSubmitting = false
                    return
                }
                sendEmailVerification(auth.currentUser)
                this.$gtag.event(SIGNUP_FACEBOOK)
                this.$emit('success')
                return
            }

            createUserWithEmailAndPassword(auth, this.email, this.password)
                .then(async (response) => {
                    const result = await createUserAndAddAsMember(
                        this.studio.id,
                        this.fullName,
                        this.email,
                        this.username,
                        this.dob,
                        this.phoneNumberObj.formattedNumber,
                        this.password,
                        this.country,
                        response.user.uid,
                        memberCustomData
                    )

                    if (!result.success) {
                        await deleteUser(auth.currentUser)
                        this.$gtag.event(SIGNUP_ERROR, {
                            errorMessage: subStr(result.error),
                        })
                        this.error = result.error
                        this.isSubmitting = false
                        return
                    }
                    this.refreshUserDocument()
                    sendEmailVerification(auth.currentUser)
                    this.$gtag.event(SIGNUP)
                    this.$emit('success')
                })
                .catch((error) => {
                    this.$gtag.event(SIGNUP_ERROR, {
                        errorMessage: subStr(error.code),
                    })
                    this.error = getAuthErrorMessage(error.code)
                    this.isSubmitting = false
                })
        },
        async setSupportedCountries() {
            const result = await getSupportedCountries()
            if (result.success) {
                this.supportedCountries = result.countries
            }
        },
        onPhoneNumberChange(results) {
            this.phoneNumberObj = results
        },
    },
    created() {
        this.setSupportedCountries()
        if (this.collectUserDetails) {
            const email = auth.currentUser.email
            // Account for case where Facebook login does not have email
            if (email) {
                this.fullName = auth.currentUser.displayName
                this.username = email.split('@')[0]
            } else {
                this.noEmailCase = true
            }
        }
    },
}
</script>

<style scoped>
.error-text {
    font-family: Roboto;
    font-size: 12px;
    padding: 0px;
    margin: 0 0 -4px 33px;
}
</style>
