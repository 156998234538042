<template>
    <div>
        <!-- Sign Up Button -->
        <v-btn
            text
            :block="block"
            v-show="!hideButton"
            @click="navigateToSignUpPage"
        >
            {{ $t('message.signup') }}
        </v-btn>
    </div>
</template>

<script>
export default {
    props: ['block', 'hideButton'],
    data() {
        return {
            dialog: false,
        }
    },
    methods: {
        navigateToSignUpPage() {
            this.$emit('openDialog')
            const hash = this.$route.hash
            const studioRoute = this.$route.params.studioRoute
            const signUpPageRoute = `/${studioRoute}/signup/${hash}`
            if (this.$route.path != signUpPageRoute) {
                this.$router.push(signUpPageRoute)
            }
        },
    },
}
</script>
