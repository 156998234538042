<template>
    <v-card>
        <v-card-text>
            <p class="primary--text mb-1">{{ $t('message.categories') }}</p>

            <v-checkbox
                :key="`cat-${id}`"
                :label="name"
                v-for="{ id, name } in renderedCategories"
                class="ordinary--text text-center"
                v-model="categoryFilter[id]"
                @change="categoryFilterChange"
            />

            <a v-if="categories.length > 10" @click="isExpand = !isExpand">
                <span class="mdi mdi-chevron-down"></span>
                {{ !isExpand ? $t('message.seeMore') : $t('message.seeLess') }}
            </a>

            <p class="primary--text pt-4 mt-10 mb-1">
                {{ $t('message.priceRange') }}
            </p>

            <v-checkbox
                :key="`price-${idx}`"
                :label="label"
                v-for="({ label }, idx) in priceRanges"
                class="ordinary--text text-center"
                :value="priceFilter === idx"
                @change="priceFilterChecked(idx, $event)"
            />

            <v-row class="mt-4">
                <v-col cols="8">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                :placeholder="$t('message.min')"
                                v-model="minPrice"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                :placeholder="$t('message.max')"
                                v-model="maxPrice"
                            />
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="4">
                    <v-btn
                        color="primary"
                        :disabled="!minPrice && !maxPrice"
                        @click="filterPrice"
                        >{{ $t('message.go') }}</v-btn
                    >
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import { mapGetters } from 'vuex'
import { getCategories } from '@/util/cloudFunctions/merchandiseFunctions'

export default {
    data: () => ({
        categories: [],
        categoryFilter: {},
        priceFilter: null,
        minPrice: null,
        maxPrice: null,
        isExpand: false,
    }),

    created() {
        this.fetchCategories()
    },

    methods: {
        async fetchCategories() {
            this.loading = true
            const result = await getCategories(this.studio.id)
            if (!result.error) {
                this.categories = result.docs
            }
            this.loading = false
        },

        priceFilterChecked(idx, isChecked) {
            this.minPrice = null
            this.maxPrice = null
            this.priceFilter = isChecked ? idx : null
            this.filterPrice()
        },

        filterPrice() {
            let filter = []

            if (this.minPrice || this.maxPrice) {
                this.priceFilter = null
                filter = [this.minPrice, this.maxPrice]
            } else if (this.priceFilter || this.priceFilter === 0) {
                filter = this.priceRanges[this.priceFilter].range
            }

            this.$emit('priceFiltered', JSON.stringify(filter))
        },

        categoryFilterChange() {
            this.$emit(
                'categoryFiltered',
                JSON.stringify(
                    Object.keys(this.categoryFilter).filter(
                        (id) => this.categoryFilter[id]
                    )
                )
            )
        },
    },

    computed: {
        priceRanges() {
            return [
                {
                    label: this.$t('message.underPrice', {
                        price: `${this.currency}10`,
                    }),
                    range: [null, 10],
                },
                {
                    label: this.$t('message.priceToPrice', {
                        priceOne: `${this.currency}10`,
                        priceTwo: `${this.currency}25`,
                    }),
                    range: [10, 25],
                },
                {
                    label: this.$t('message.priceToPrice', {
                        priceOne: `${this.currency}25`,
                        priceTwo: `${this.currency}50`,
                    }),
                    range: [25, 50],
                },
                {
                    label: this.$t('message.priceAndAbove', {
                        price: `${this.currency}100`,
                    }),
                    range: [100, null],
                },
            ]
        },
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        currency() {
            return this.studio.currency ?? '$'
        },
        renderedCategories() {
            if (this.categories.length > 10)
                return this.isExpand
                    ? this.categories
                    : this.categories.slice(0, 10)

            return this.categories
        },
    },
}
</script>
