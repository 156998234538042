<template>
    <v-row class="mx-0 pb-3">
        <v-col>
            <v-btn text @click="$router.go(-1)">
                <v-icon> mdi-arrow-left </v-icon>&nbsp;
                {{ $t('message.back') }}
            </v-btn>
        </v-col>
        <v-col cols="4" v-if="isWidget">
            <UserAuth />
        </v-col>
    </v-row>
</template>
<script>
import UserAuth from '@/components/auth/UserAuth'

import { mapGetters } from 'vuex'
import { AUTHENTICATION_MODULE_NAME } from '@/store/moduleNames'
import { WIDGET_ROUTE } from '@/constants'

export default {
    components: {
        UserAuth,
    },
    computed: {
        ...mapGetters({
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
        }),
        isWidget() {
            return this.$route.hash === WIDGET_ROUTE
        },
    },
}
</script>
