import { gettersCustomFields } from '@/store/moduleComponents/customFields/gettersCustomFields'
import { actionsCustomFields } from '@/store/moduleComponents/customFields/actionsCustomFields'
import { mutationsCustomFields } from '@/store/moduleComponents/customFields/mutationsCustomFields'

export const customFieldsModule = {
    namespaced: true,
    state: {
        hasUnfilledCustomFields: null,
    },
    getters: gettersCustomFields,
    actions: actionsCustomFields,
    mutations: mutationsCustomFields,
}
