<template>
    <div>
        <!-- Forget Password Button -->
        <v-btn text @click="openDialog">
            {{ $t('message.forgetPassword') }}
        </v-btn>

        <v-dialog v-model="dialog" width="600px">
            <!-- Forget Password Form -->
            <v-card>
                <v-card-title>
                    <v-col>
                        <v-row class="justify-end">
                            <v-icon @click="closeDialog">mdi-close</v-icon>
                        </v-row>
                        <v-row class="justify-center">
                            <h4 class="ml-2">
                                {{ $t('message.forgetPassword') }}
                            </h4>
                        </v-row>
                    </v-col>
                </v-card-title>
                <v-card-text>
                    <ForgetPasswordForm :key="formKey" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ForgetPasswordForm from '@/components/auth/ForgetPasswordForm'
import { mapActions } from 'vuex'
import { ALERT_MODULE_NAME } from '@/store/moduleNames'
export default {
    components: {
        ForgetPasswordForm,
    },
    data() {
        return {
            dialog: false,
            formKey: true,
        }
    },
    methods: {
        ...mapActions({
            setAlertStateAction: `${ALERT_MODULE_NAME}/setAlertStateAction`,
        }),
        toggleFormKey() {
            this.formKey = !this.formKey
        },
        closeDialog() {
            this.dialog = false
        },
        openDialog() {
            this.dialog = true
        },
    },
}
</script>
