import { db } from '@/firebase'
import {
    collection,
    query,
    getDocs,
    where,
    orderBy,
    limit,
} from 'firebase/firestore'

export async function getStudioLastPublicClass(studioId) {
    const q = query(
        collection(db, 'classes'),
        where('studioId', '==', studioId),
        where('isPublic', '==', true),
        orderBy('startTimestamp', 'desc'),
        limit(1)
    )
    const querySnapshot = await getDocs(q)
    const classes = querySnapshot.docs
    if (querySnapshot.docs.length === 0) {
        return null
    }
    return classes.map((doc) => ({ ...doc.data(), id: doc.id }))[0]
}
