<template>
    <v-col>
        <EmailLoginForm @success="emitSuccess" />
        <v-row justify="center" align="center" class="pt-7">
            <ForgetPassword />
        </v-row>
        <v-divider class="mt-10 mb-7"></v-divider>
        <FacebookLogin @success="emitSuccess" v-if="!isBrandedApp" />

        <v-row justify="center" align="center" class="pt-5">
            {{ $t('message.noAccountPrompt') }}
            <v-btn text small @click="emitSignUp" ref="signUpButton">
                {{ $t('message.signupHere') }}
            </v-btn>
        </v-row>
    </v-col>
</template>
<script>
import EmailLoginForm from '@/components/auth/EmailLoginForm'
import ForgetPassword from '@/components/auth/ForgetPassword'
import FacebookLogin from '@/components/auth/FacebookLogin'
import { mapGetters } from 'vuex'
import { AUTHENTICATION_MODULE_NAME } from '@/store/moduleNames'
export default {
    components: {
        EmailLoginForm,
        ForgetPassword,
        FacebookLogin,
    },
    computed: {
        ...mapGetters({
            isBrandedApp: `${AUTHENTICATION_MODULE_NAME}/isBrandedApp`,
        }),
    },
    methods: {
        emitSuccess() {
            this.$emit('success')
        },
        emitSignUp() {
            this.$emit('signUp')
        },
    },
}
</script>
