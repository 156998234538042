<template>
    <ValidationObserver ref="observer">
        <v-form ref="form">
            <!-- Email Field -->
            <ValidationProvider
                v-slot="{ errors }"
                :name="$t('message.email')"
                rules="required|max:64|email"
            >
                <text-trimmer
                    :label="$t('message.email')"
                    v-model="email"
                    prepend-icon="mdi-account-outline"
                    :error-messages="errors"
                ></text-trimmer>
            </ValidationProvider>

            <p class="error--text text-center py-3" v-if="error !== ''">
                {{ error }}
            </p>

            <p class="success--text text-center py-3" v-if="success !== ''">
                {{ success }}
            </p>

            <v-row justify="center" class="my-3">
                <v-btn
                    class="primary"
                    @click="submit"
                    :loading="loading"
                    depressed
                >
                    {{ $t('message.forgetPassword') }}
                </v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
import TextTrimmer from '@/components/shared/TextTrimmer.vue'
import { auth, sendPasswordResetEmail } from '@/firebase'
import getAuthErrorMessage from '@/util/authErrors'
import { fetchSignInMethod } from '@/util/loginProviders'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// Analytics
import { FORGET_PASSWORD, FORGET_PASSWORD_ERROR } from '@/analytics/events'
import { subStr } from '@/analytics/util'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        TextTrimmer,
    },
    data() {
        return {
            password: '',
            email: '',
            success: '',
            error: '',
            loading: false,
            passwordToggle: true,
        }
    },
    methods: {
        async validate() {
            return await this.$refs.observer.validate()
        },
        async isPasswordSignInMethod() {
            const signInMethod = await fetchSignInMethod(this.email)

            if (signInMethod !== 'password') {
                this.error = this.$t('message.loginWithMethodInstead', {
                    signInMethod: signInMethod,
                })
                return false
            }

            return true
        },
        async submit() {
            this.success = ''
            this.error = ''
            this.loading = true

            const isValid = await this.validate()

            if (!isValid) {
                this.loading = false
                return
            }

            sendPasswordResetEmail(auth, this.email)
                .then(() => {
                    this.$gtag.event(FORGET_PASSWORD)
                    this.success = this.$t('message.passwordResetEmailSent')
                })
                .catch((error) => {
                    this.$gtag.event(FORGET_PASSWORD_ERROR, {
                        errorMessage: subStr(error.code),
                    })
                    this.error = getAuthErrorMessage(error.code)
                })
                .finally(() => (this.loading = false))
        },
    },
}
</script>
