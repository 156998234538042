<template>
    <div>
        <!-- Top level filters -->
        <div class="d-none d-sm-flex">
            <FilterDesktop
                :studio="studio"
                :type="'class'"
                :minDate="todayDate"
                :classFilter="classFilter"
                :instructorFilter="instructorFilter"
                :locationFilter="locationFilter"
                :date="date"
                @update:classFilter="onClassFilterChange"
                @update:instructorFilter="onInstructorFilterChange"
                @update:locationFilter="onLocationFilterChange"
                @update:date="onDateChange"
            />
        </div>

        <!-- Mobile filters -->
        <v-row
            min-height="20vh"
            width="100vw"
            class="d-flex d-sm-none px-5 mx-1 py-5 mt-3 rounded-lg"
            :style="{
                backgroundColor: $vuetify.theme.themes.light.dilutedPrimary,
                color: $vuetify.theme.themes.light.primary,
            }"
        >
            {{ $t('message.filterClasses') }}
            <v-row justify="end" @click="toggleDropdown = !toggleDropdown">
                <v-icon v-if="toggleDropdown" right class="my-3 mx-1">
                    mdi-chevron-down
                </v-icon>
                <v-icon v-else right class="my-3 mx-1"
                    >mdi-chevron-right</v-icon
                >
            </v-row>
            <v-slide-y-transition>
                <FilterDesktop
                    v-if="toggleDropdown"
                    :studio="studio"
                    :type="'class'"
                    :minDate="todayDate"
                    :classFilter="classFilter"
                    :instructorFilter="instructorFilter"
                    :locationFilter="locationFilter"
                    :date="date"
                    @update:classFilter="onClassFilterChange"
                    @update:instructorFilter="onInstructorFilterChange"
                    @update:locationFilter="onLocationFilterChange"
                    @update:date="onDateChange"
                />
            </v-slide-y-transition>
        </v-row>
    </div>
</template>
<script>
import FilterDesktop from '@/components/browseclasses/FilterDesktop.vue'

export default {
    components: {
        FilterDesktop,
    },
    data() {
        return {
            toggleDropdown: false,
        }
    },
    props: {
        studio: Object,
        classFilter: Array,
        instructorFilter: Array,
        locationFilter: Array,
        date: String,
        todayDate: String,
    },
    methods: {
        onClassFilterChange(event) {
            this.$emit('update:classFilter', event)
        },
        onInstructorFilterChange(event) {
            this.$emit('update:instructorFilter', event)
        },
        onLocationFilterChange(event) {
            this.$emit('update:locationFilter', event)
        },
        onDateChange(event) {
            this.$emit('update:date', event)
        },
    },
}
</script>
