<template>
    <v-container fill-height fluid>
        <BackButton />
        <h3 class="my-5 text-center" v-if="!isUserAuth">
            {{ $t('message.youAreNotLoggedIn') }}
        </h3>

        <v-skeleton-loader
            type="card"
            v-if="isLoading"
            class="my-5"
        ></v-skeleton-loader>

        <v-row justify="center" align="center" v-if="isUserAuth && !isLoading">
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title class="primary--text">
                        <v-row align="center" class="mx-10 mt-3">
                            <edit-image-dialog
                                :user="user"
                                :userPhotoSize="userPhotoSize"
                                v-if="!isBrandedApp && !isStudioInReview"
                            />
                            <h4>{{ fullName }}</h4>
                            <v-spacer />
                            <edit-user-dialog :user="user" />
                        </v-row>
                    </v-card-title>

                    <v-card-text class="my-5">
                        <v-row align="center" class="mx-10">
                            <v-col cols="12" md="6" lg="4">
                                <span class="font-weight-light">
                                    {{ $t('message.username') }}
                                </span>
                                <br />
                                <span class="nextLine">{{ username }}</span>
                            </v-col>

                            <v-col cols="12" md="6" lg="4">
                                <span class="font-weight-light">
                                    {{ $t('message.dob') }}</span
                                >
                                <br />
                                <span class="nextLine">
                                    {{ dob }}
                                </span>
                            </v-col>

                            <v-col cols="12" md="6" lg="4">
                                <span class="font-weight-light">
                                    {{ $t('message.country') }}</span
                                >
                                <br />
                                <span class="nextLine">
                                    {{ country }}
                                </span>
                            </v-col>

                            <v-col cols="12">
                                <span class="font-weight-light">
                                    {{ $t('message.email') }}
                                </span>
                                <br />
                                <span class="nextLine">
                                    {{ email }}
                                </span>
                            </v-col>

                            <DeleteAccount v-if="isBrandedApp" />
                        </v-row>
                    </v-card-text>
                </v-card>

                <FamilyUsers class="my-5" :userId="user.id" />
                <CustomFieldsProfile
                    v-if="!isCustomFieldsEmpty"
                    @studioCustomData="handleStudioCustomData"
                    :studioId="studioId"
                    :isEditMode="false"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
    AUTHENTICATION_MODULE_NAME,
    STUDIO_MODULE_NAME,
} from '@/store/moduleNames'
import BackButton from '@/components/shared/BackButton.vue'
import CustomFieldsProfile from '@/components/profile/CustomFieldsProfile.vue'
import DeleteAccount from '@/components/profile/DeleteAccount'
import FamilyUsers from '@/components/familyusers/FamilyUsers'
import EditUserDialog from '@/components/user/EditUserDialog'
import EditImageDialog from '@/components/user/EditImageDialog'

export default {
    components: {
        BackButton,
        CustomFieldsProfile,
        DeleteAccount,
        FamilyUsers,
        EditUserDialog,
        EditImageDialog,
    },
    data() {
        return {
            isCustomFieldsEmpty: false,
        }
    },
    computed: {
        isStudioInReview() {
            return (
                this.studio && this.brandedAppsInReview.includes(this.studio.id)
            )
        },
        ...mapGetters({
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
            user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
            isLoading: `${AUTHENTICATION_MODULE_NAME}/isLoading`,
            isBrandedApp: `${AUTHENTICATION_MODULE_NAME}/isBrandedApp`,
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
            brandedAppsInReview: `${STUDIO_MODULE_NAME}/brandedAppsInReview`,
        }),
        profilePhoto() {
            return this.user.profilePhoto
        },
        fullName() {
            return this.user.fullName
        },
        username() {
            return this.user.username
        },
        dob() {
            return this.user.dob
        },
        country() {
            return this.user.country
        },
        email() {
            return this.user.email
        },
        userPhotoSize() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 40
                case 'sm':
                    return 60
                case 'md':
                case 'lg':
                case 'xl':
                    return 70
            }
            return 52
        },
        studioId() {
            return this.studio.studioId
        },
    },
    methods: {
        handleStudioCustomData(data) {
            if (data.length === 0) {
                this.isCustomFieldsEmpty = true
            }
        },
    },
}
</script>

<style scoped>
.nextLine {
    word-wrap: break-word;
    font-weight: 400;
}
</style>
