import { getDayOfDate, isTimestampBetweenTime } from '@/util/datetimehelpers'

const NONE = 'none'

function isPackageValidForClass(pkg, classItem, timezone) {
    let locationKey = 'locations'
    let classesKey = 'classes'
    let dayKey = 'days'
    // If it's a purchased package, set the keys to be different
    if (pkg.datePurchased) {
        locationKey = 'locationRestrictions'
        classesKey = 'classesRestrictions'
        dayKey = 'dayRestrictions'
    }

    // Filter class
    if (
        pkg.hasClassRestriction &&
        pkg[classesKey] &&
        !pkg[classesKey].includes(NONE)
    ) {
        if (!pkg[classesKey].includes(classItem.name)) {
            return false
        }
    }

    // Filter location
    if (
        pkg.hasLocationRestriction &&
        pkg[locationKey] &&
        !pkg[locationKey].includes(NONE)
    ) {
        if (!pkg[locationKey].includes(classItem.location.name)) {
            return false
        }
    }

    // Filter day and time
    if (
        pkg.hasTimeRange &&
        pkg[dayKey] &&
        pkg.startTime &&
        pkg.endTime &&
        !pkg[dayKey].includes(NONE)
    ) {
        // Filter day
        const classDay = getDayOfDate(classItem.startTimestamp, timezone)
        if (!pkg[dayKey].includes(classDay)) {
            return false
        }

        // Filter time
        if (
            !isTimestampBetweenTime(
                classItem.startTimestamp,
                pkg.startTime,
                pkg.endTime,
                timezone
            )
        ) {
            return false
        }
    }

    return true
}

export { isPackageValidForClass, NONE }
