import i18n from '@/i18n'
import { extend, setInteractionMode } from 'vee-validate'
import { email, max, min, regex, required } from 'vee-validate/dist/rules'

/**
 * Below are the default messages for the VeeValidate rules.
 * If you want to override the default messages, you can do so passing the customMessages prop to your ValidationProvider component.
 * Do not use extend!! It will override validation error messages globally.
 * https://vee-validate.logaretm.com/v3/api/validation-provider.html#props
 *
 * Alternatively you can define your own custom validation rule and custom message.
 * https://vee-validate.logaretm.com/v3/guide/basics.html#adding-rules
 */
extend('required', {
    ...required,
    message: (field) => i18n.t('message.fieldNoEmpty', { field: field }),
})

extend('email', {
    ...email,
    message: () => i18n.t('message.emailInvalid'),
})

extend('max', {
    ...max,
    message: (field, { length }) =>
        i18n.t('message.fieldMaxLengthExceeded', {
            field: field,
            length: length,
        }),
})

extend('min', {
    ...min,
    message: (field, { length }) =>
        i18n.t('message.fieldMinLengthNotMet', {
            field: field,
            length: length,
        }),
})

extend('usernameRegex', {
    ...regex,
    message: (field) =>
        i18n.t('message.usernameRegexValidation', { field: field }),
})

extend('fullNameRegex', {
    ...regex,
    message: (field) => i18n.t('message.nameRegexValidation', { field: field }),
})

setInteractionMode('eager') // set default interaction mode to eager
