<template>
    <div>
        <!-- Package Info Button -->
        <v-btn text :class="classProp" :block="block" @click="openDialog">
            {{ buttonText }}
        </v-btn>

        <v-dialog v-model="dialog" width="600px">
            <!-- Package Info Card -->
            <v-card>
                <v-card-title>
                    <v-col class="pb-0">
                        <v-row class="justify-end">
                            <v-icon @click="closeDialog">mdi-close</v-icon>
                        </v-row>
                    </v-col>
                </v-card-title>
                <v-card-text>
                    <PackageInfoForm
                        :packageItem="packageItem"
                        :isBuyable="isBuyable"
                        @sharePackage="sharePackage"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import PackageInfoForm from '@/components/packages/PackageInfoForm'

// Analytics
import { CLICK_VIEW_MORE } from '@/analytics/events'

export default {
    props: ['classProp', 'block', 'buttonText', 'packageItem', 'isBuyable'],
    components: {
        PackageInfoForm,
    },
    data() {
        return {
            dialog: false,
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false
        },
        openDialog() {
            this.$gtag.event(CLICK_VIEW_MORE)
            this.dialog = true
        },
        sharePackage() {
            this.$emit('sharePackage')
        },
    },
}
</script>
