<template>
    <div>
        <!-- Change Password Button -->
        <v-btn
            text
            v-show="!hideButton"
            @click="openDialog"
            ref="changePasswordButton"
        >
            {{ $t('message.changePassword') }}
        </v-btn>

        <v-dialog v-model="dialog" width="600px">
            <!-- Change Password Form -->
            <v-card>
                <v-card-title>
                    <v-col>
                        <v-row class="justify-end">
                            <v-icon @click="closeDialog">mdi-close</v-icon>
                        </v-row>
                        <v-row class="justify-center">
                            <h4 class="ml-2">
                                {{ $t('message.changePassword') }}
                            </h4>
                        </v-row>
                    </v-col>
                </v-card-title>
                <v-card-text>
                    <ChangePasswordForm :key="formKey" @success="onSuccess" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ChangePasswordForm from '@/components/auth/ChangePasswordForm'
import { auth } from '@/firebase'
import { mapActions } from 'vuex'
import { ALERT_MODULE_NAME } from '@/store/moduleNames'
import { fetchSignInMethod } from '@/util/loginProviders'
export default {
    props: ['hideButton'],
    components: {
        ChangePasswordForm,
    },
    data() {
        return {
            dialog: false,
            formKey: true,
        }
    },
    methods: {
        ...mapActions({
            setAlertStateAction: `${ALERT_MODULE_NAME}/setAlertStateAction`,
        }),
        onSuccess() {
            this.closeDialog()
            const payload = {
                type: 'success',
                message: this.$t('message.changePasswordSuccess'),
            }
            this.setAlertStateAction(payload)
            this.toggleFormKey()
        },
        toggleFormKey() {
            this.formKey = !this.formKey
        },
        closeDialog() {
            this.dialog = false
        },
        async openDialog() {
            // Event emitted to signify attempting to open dialog
            this.$emit('openDialog')

            const signInMethod = await fetchSignInMethod(auth.currentUser.email)

            if (signInMethod !== 'password') {
                const payload = {
                    type: 'info',
                    message: this.$t('message.loggedInWith', {
                        signInMethod: signInMethod,
                    }),
                }
                this.setAlertStateAction(payload)
                return
            }

            this.dialog = true
        },
    },
}
</script>
