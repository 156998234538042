import { render, staticRenderFns } from "./BookCourse.vue?vue&type=template&id=52f20aac&scoped=true"
import script from "./BookCourse.vue?vue&type=script&lang=js"
export * from "./BookCourse.vue?vue&type=script&lang=js"
import style0 from "./BookCourse.vue?vue&type=style&index=0&id=52f20aac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52f20aac",
  null
  
)

export default component.exports