<template>
    <div>
        <BlockingLoader
            v-model="isPaymentProcessing"
            :text="$t('message.paymentSuccessLoading')"
        >
            <v-btn
                @click="openCheckoutSession"
                block
                color="primary"
                class="mb-3"
            >
                <v-icon left> mdi-lock </v-icon>
                {{ $t('message.proceedToCheckout') }}
            </v-btn>
            <v-btn
                @click="checkCheckoutSessionStatus"
                block
                color="secondary"
                >{{ $t('message.done') }}</v-btn
            >
        </BlockingLoader>
        <v-btn
            @click="createCheckoutSession"
            color="primary"
            :loading="loading"
            x-large
        >
            <v-icon left>mdi-credit-card</v-icon> {{ $t('message.checkout') }}
        </v-btn>
    </div>
</template>
<script>
import BlockingLoader from '@/components/shared/BlockingLoader.vue'

import { createCheckoutSessionForProductsPurchase } from '@/util/cloudFunctions/stripe'
import { retrieveCheckoutSessionStatus } from '@/util/cloudFunctions/stripe'
import { getNumberOfCartItems } from '@/util/cloudFunctions/shoppingCart'

import { mapGetters } from 'vuex'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'

export default {
    components: {
        BlockingLoader,
    },
    data() {
        return {
            loading: false,
            isPaymentProcessing: false,
        }
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
    },
    methods: {
        logError(error) {
            this.$emit('error', error)
            this.loading = false
            return
        },
        async createCheckoutSession() {
            this.loading = true
            const baseUrl = window.location.href.split('?')[0]
            // check whether there are any cart items first
            const countResult = await getNumberOfCartItems(this.studio.id)
            if (!countResult.success) {
                this.loading = false
                return
            }
            if (countResult.count <= 0) {
                this.loading = false
                return
            }

            const result = await createCheckoutSessionForProductsPurchase(
                this.studio.id,
                baseUrl
            )
            if (!result.success) {
                this.logError(result.error)
                this.loading = false
                return
            }
            this.loading = false
            this.sessionId = result.sessionId
            this.url = result.sessionUrl
            this.isPaymentProcessing = true
        },
        openCheckoutSession() {
            window.open(this.url, '_blank')
        },
        async checkCheckoutSessionStatus() {
            const result = await retrieveCheckoutSessionStatus(
                this.sessionId,
                this.studio.stripeAccountId
            )
            if (result.success) {
                this.$emit('success')
            }
            this.isPaymentProcessing = false
        },
    },
}
</script>
