import { functions, httpsCallable } from '@/firebase'

export function getUpcomingUserCourseSessions(studioId) {
    const getClassesByStudio = httpsCallable(
        functions,
        'getMyUpcomingCourseSessions'
    )
    return getClassesByStudio({ studioId }).then((result) => result.data)
}

export function getPastUserCourseSessions(studioId, lastDocId) {
    const userData = { studioId, lastDocId }
    const getMyPastClasses = httpsCallable(functions, 'getMyPastCourseSessions')
    return getMyPastClasses(userData).then((result) => result.data)
}

export function getCourseSessionsFromStudioId(data) {
    const {
        studioId,
        courseNameFilter,
        instructorFilter,
        locationFilter,
        startDate,
        endDate,
    } = data
    const getClassesByStudio = httpsCallable(
        functions,
        'getCourseSessionsByStudio'
    )
    const callData = {
        studioId,
        courseNameFilter,
        instructorFilter,
        locationFilter,
        startDate,
        endDate,
    }
    return getClassesByStudio(callData).then((result) => result.data)
}

export async function hasRegisteredForCourseOffering(courseOfferingId) {
    const callData = { courseOfferingId }
    const hasRegisteredFunction = httpsCallable(
        functions,
        'hasRegisteredForCourseOffering'
    )
    return hasRegisteredFunction(callData).then((result) => result.data)
}

export async function getDiscountedCoursePrice(courseOfferingId, code) {
    const data = { courseOfferingId, code }
    const fn = httpsCallable(functions, 'getDiscountedCoursePrice')
    return fn(data).then((result) => result.data)
}
