<template>
    <v-col align="center" class="pb-0" v-if="loaded">
        <lottie-animation
            path="lottie/payment-success.json"
            :height="dimensions"
            :width="dimensions"
        />
        <h1 class="success--text">{{ $t('message.paymentSuccess') }}</h1>
        <p v-if="!isMobile">{{ $t('message.closeTab') }}</p>
        <v-btn class="primary my-5" v-if="isMobile" @click="routeToStudio">{{
            $t('message.goToHome')
        }}</v-btn>
    </v-col>
</template>

<script>
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue' // import lottie-vuejs
import { EventManager } from '@/analytics/eventManager'
import { isPurchasePackageOrPurchaseCourse } from '@/analytics/util'

export default {
    components: {
        LottieAnimation,
    },
    computed: {
        dimensions() {
            return this.$vuetify.breakpoint.mdAndUp ? 500 : 350
        },
    },
    data() {
        return {
            isMobile: false,
            studioRoute: '',
            loaded: false,
        }
    },
    methods: {
        routeToStudio() {
            const route = this.studioRoute
            const isMobile = this.isMobile
            if (isMobile && route) {
                this.$router.replace(`/${route}`)
            }
        },
        logEvents() {
            const events = EventManager.retrieveEvents()
            if (events) {
                for (const [eventName, parameters] of Object.entries(events)) {
                    if (isPurchasePackageOrPurchaseCourse(eventName)) {
                        this.$gtag.event(eventName, parameters)
                    }
                }
            }
        },
    },
    created() {
        const lang = this.$route.query.lang

        if (lang) {
            const locale = lang.toLowerCase() === 'zh' ? 'zh' : 'en'
            this.$i18n.locale = locale
        } else {
            this.$i18n.locale = 'en'
        }
        const route = this.$route.query.route
        const isMobile = this.$vuetify.breakpoint.smAndDown
        if (isMobile && route) {
            this.studioRoute = route
            this.isMobile = isMobile
        }
        this.loaded = true
        this.logEvents()
    },
}
</script>
