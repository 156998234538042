<template>
    <div>
        <BackButton />
        <v-row class="d-none d-md-flex my-5">
            <v-col cols="3">
                <v-card class="py-10" height="80vh">
                    <v-tabs vertical>
                        <v-tab
                            class="text-capitalize"
                            @click="handleUpcomingClasses"
                        >
                            {{ $t('message.classesUpcoming') }}
                        </v-tab>
                        <v-tab
                            class="text-capitalize"
                            @click="handleWaitlistedClasses"
                        >
                            {{ $t('message.waitlist') }}
                        </v-tab>
                        <v-tab
                            class="text-capitalize"
                            @click="handlePastClasses"
                        >
                            {{ $t('message.classesPast') }}
                        </v-tab>
                    </v-tabs>
                </v-card>
            </v-col>
            <v-col cols="9">
                <v-card
                    v-if="isUpcoming"
                    flat
                    :style="{
                        background: $vuetify.theme.themes[theme].background,
                    }"
                    class="mt-3"
                >
                    <p v-if="noUpcomingData" class="py-5 px-5">
                        {{ $t('message.classesNoUpcoming') }}
                    </p>
                    <v-card flat v-else>
                        <v-row>
                            <v-col>
                                <v-card-text
                                    class="py-2 primary--text font-weight-bold"
                                >
                                    {{ $t('message.time') }}
                                </v-card-text>
                            </v-col>
                            <v-col>
                                <v-card-text
                                    class="py-2 primary--text font-weight-bold"
                                >
                                    {{ $t('message.class') }}
                                </v-card-text>
                            </v-col>
                            <v-col>
                                <v-card-text
                                    class="py-2 primary--text font-weight-bold"
                                >
                                    {{ $t('message.instructor') }}
                                </v-card-text>
                            </v-col>
                            <v-col>
                                <v-card-text
                                    class="py-2 primary--text font-weight-bold"
                                >
                                    {{ $t('message.location') }}
                                </v-card-text>
                            </v-col>
                            <!-- Dummy column for aesthetics purposes -->
                            <v-col></v-col>
                        </v-row>
                    </v-card>
                    <class-view
                        :studio="studio"
                        :classes="upcomingClassesAndCourseSessions"
                        :bookBtn="bookBtn"
                        :cancelUpcomingBtn="true"
                        :isClickable="true"
                        @cancelled="handleCancelledSuccess"
                        @cancelFeePaymentError="handleCancelFeePaymentError"
                        @assignFamilyUser="handleAssignFamilyUserSuccess"
                    />
                    <v-skeleton-loader
                        class="mt-5"
                        v-if="upcomingDataLoading"
                        type="card"
                    />
                </v-card>
                <v-card
                    v-if="isWaitlist"
                    flat
                    :style="{
                        background: $vuetify.theme.themes[theme].background,
                    }"
                    class="mt-3"
                >
                    <p v-if="noWaitlistData" class="py-5 px-5">
                        {{ $t('message.classesNoWaitlist') }}
                    </p>
                    <v-card flat v-else>
                        <v-row>
                            <v-col>
                                <v-card-text
                                    class="py-2 primary--text font-weight-bold"
                                >
                                    {{ $t('message.time') }}
                                </v-card-text>
                            </v-col>
                            <v-col>
                                <v-card-text
                                    class="py-2 primary--text font-weight-bold"
                                >
                                    {{ $t('message.class') }}
                                </v-card-text>
                            </v-col>
                            <v-col>
                                <v-card-text
                                    class="py-2 primary--text font-weight-bold"
                                >
                                    {{ $t('message.instructor') }}
                                </v-card-text>
                            </v-col>
                            <v-col>
                                <v-card-text
                                    class="py-2 primary--text font-weight-bold"
                                >
                                    {{ $t('message.location') }}
                                </v-card-text>
                            </v-col>
                            <!-- Dummy column for aesthetics purposes -->
                            <v-col></v-col>
                        </v-row>
                    </v-card>
                    <class-view
                        :studio="studio"
                        :classes="waitlistedClasses"
                        :bookBtn="bookBtn"
                        :cancelWaitlistBtn="true"
                        :isClickable="true"
                        @removed="handleWaitlistSuccess"
                    />
                    <v-skeleton-loader
                        class="mt-5"
                        v-if="waitlistDataLoading"
                        type="card"
                    />
                </v-card>
                <v-card
                    v-if="isPast"
                    flat
                    :style="{
                        background: $vuetify.theme.themes[theme].background,
                    }"
                    class="mt-3"
                >
                    <p
                        v-if="
                            noPastData && pastClassesAndCoursesRaw.length === 0
                        "
                        class="py-5 px-5"
                    >
                        {{ $t('message.classesNoPast') }}
                    </p>
                    <v-card flat v-else>
                        <v-row>
                            <v-col>
                                <v-card-text
                                    class="py-2 primary--text font-weight-bold"
                                >
                                    {{ $t('message.time') }}
                                </v-card-text>
                            </v-col>
                            <v-col>
                                <v-card-text
                                    class="py-2 primary--text font-weight-bold"
                                >
                                    {{ $t('message.class') }}
                                </v-card-text>
                            </v-col>
                            <v-col>
                                <v-card-text
                                    class="py-2 primary--text font-weight-bold"
                                >
                                    {{ $t('message.instructor') }}
                                </v-card-text>
                            </v-col>
                            <v-col>
                                <v-card-text
                                    class="py-2 primary--text font-weight-bold"
                                >
                                    {{ $t('message.location') }}
                                </v-card-text>
                            </v-col>
                            <!-- Dummy column for aesthetics purposes -->
                            <v-col></v-col>
                        </v-row>
                    </v-card>
                    <class-view
                        :studio="studio"
                        :classes="pastClassesAndCourseSessions"
                        :bookBtn="bookBtn"
                    />
                    <v-skeleton-loader
                        class="mt-5"
                        v-if="pastDataLoading"
                        type="card"
                    />
                    <div class="d-flex justify-center">
                        <v-btn
                            v-if="!noPastData"
                            text
                            class="primary text-capitalize mt-2"
                            @click="getPastClassesAndCourseSessions"
                            >{{ $t('message.classesViewMore') }}</v-btn
                        >
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-card class="d-none d-sm-flex d-md-none" flat>
            <v-tabs centered>
                <v-tab class="text-capitalize">
                    {{ $t('message.classesUpcoming') }}
                </v-tab>
                <v-tab class="text-capitalize">
                    {{ $t('message.waitlist') }}
                </v-tab>
                <v-tab class="text-capitalize">
                    {{ $t('message.classesPast') }}
                </v-tab>

                <v-tab-item>
                    <v-card flat>
                        <p v-if="noUpcomingData" class="py-5 px-5">
                            {{ $t('message.classesNoUpcoming') }}
                        </p>
                        <class-view
                            :studio="studio"
                            :classes="upcomingClassesAndCourseSessions"
                            :bookBtn="bookBtn"
                            :cancelUpcomingBtn="true"
                            :myClassMd="true"
                            :isClickable="true"
                            @cancelled="handleCancelledSuccess"
                            @cancelFeePaymentError="handleCancelFeePaymentError"
                            @assignFamilyUser="handleAssignFamilyUserSuccess"
                        />
                        <v-skeleton-loader
                            class="mt-5"
                            v-if="upcomingDataLoading"
                            type="card"
                        />
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <p v-if="noWaitlistData" class="py-5 px-5">
                            {{ $t('message.classesNoWaitlist') }}
                        </p>
                        <class-view
                            :studio="studio"
                            :classes="waitlistedClasses"
                            :bookBtn="bookBtn"
                            :cancelWaitlistBtn="true"
                            :myClassMd="true"
                            :isClickable="true"
                            @removed="handleWaitlistSuccess"
                        />
                        <v-skeleton-loader
                            class="mt-5"
                            v-if="waitlistDataLoading"
                            type="card"
                        />
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <p v-if="noPastData" class="py-5 px-5">
                            {{ $t('message.classesNoPast') }}
                        </p>
                        <class-view
                            :studio="studio"
                            :classes="pastClassesAndCourseSessions"
                            :bookBtn="bookBtn"
                            :myClassMd="true"
                        />
                        <v-skeleton-loader
                            class="mt-5"
                            v-if="pastDataLoading"
                            type="card"
                        />
                        <div class="d-flex justify-center">
                            <v-btn
                                text
                                class="primary text-capitalize"
                                @click="getPastClassesAndCourseSessions"
                                v-if="!noPastData"
                                >{{ $t('message.classesViewMore') }}</v-btn
                            >
                        </div>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-card>
        <v-card class="d-flex d-sm-none my-5" flat>
            <v-tabs centered>
                <v-tab class="text-capitalize">
                    {{ $t('message.classesUpcoming') }}
                </v-tab>
                <v-tab class="text-capitalize">
                    {{ $t('message.waitlist') }}
                </v-tab>
                <v-tab class="text-capitalize">
                    {{ $t('message.classesPast') }}
                </v-tab>

                <v-tab-item>
                    <v-card flat>
                        <p v-if="noUpcomingData" class="py-5 px-5">
                            {{ $t('message.classesNoUpcoming') }}
                        </p>
                        <class-view
                            :studio="studio"
                            :classes="upcomingClassesAndCourseSessions"
                            :bookBtn="bookBtn"
                            :cancelUpcomingBtn="true"
                            @cancelled="handleCancelledSuccess"
                            @cancelFeePaymentError="handleCancelFeePaymentError"
                            @assignFamilyUser="handleAssignFamilyUserSuccess"
                            :isClickable="true"
                            :isMobileView="true"
                        />
                        <v-skeleton-loader
                            class="mt-5"
                            v-if="upcomingDataLoading"
                            type="card"
                        />
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <p v-if="noWaitlistData" class="py-5 px-5">
                            {{ $t('message.classesNoWaitlist') }}
                        </p>
                        <class-view
                            :studio="studio"
                            :classes="waitlistedClasses"
                            :bookBtn="bookBtn"
                            :cancelWaitlistBtn="true"
                            @removed="handleWaitlistSuccess"
                            :isClickable="true"
                            :isMobileView="true"
                        />
                        <v-skeleton-loader
                            class="mt-5"
                            v-if="waitlistDataLoading"
                            type="card"
                        />
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card
                        flat
                        :style="{
                            background: $vuetify.theme.themes[theme].background,
                        }"
                    >
                        <p v-if="noPastData" class="py-5 px-5">
                            {{ $t('message.classesNoPast') }}
                        </p>
                        <class-view
                            :studio="studio"
                            :classes="pastClassesAndCourseSessions"
                            :bookBtn="bookBtn"
                            :isMobileView="true"
                        />
                        <v-skeleton-loader
                            class="mt-5"
                            v-if="pastDataLoading"
                            type="card"
                        />
                        <div class="d-flex justify-center">
                            <v-btn
                                text
                                class="primary text-capitalize"
                                @click="getPastClassesAndCourseSessions"
                                v-if="!noPastData"
                                >{{ $t('message.classesViewMore') }}</v-btn
                            >
                        </div>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-card>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
    getUserUpcomingClasses,
    getUserPastClasses,
    getUserWaitlist,
} from '@/util/cloudFunctions/classesFunctions'
import {
    getUpcomingUserCourseSessions,
    getPastUserCourseSessions,
} from '@/util/cloudFunctions/coursesFunctions'
import ClassView from '@/components/browseclasses/ClassView.vue'
import BackButton from '@/components/shared/BackButton.vue'
import { AUTHENTICATION_MODULE_NAME } from '@/store/moduleNames'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import { ALERT_MODULE_NAME } from '@/store/moduleNames'
import {
    dateStringFromTimestamp,
    timeStringFromTimestamp,
    convertDateFormat,
} from '@/util/dateformat.js'

// Analytics
import {
    CLICK_UPCOMING_CLASSES,
    CLICK_WAITLIST,
    CLICK_PAST_CLASSES,
} from '@/analytics/events'

export default {
    components: {
        ClassView,
        BackButton,
    },
    created() {
        this.getUpcomingClassesAndCourseSessions()
        this.getWaitlistClasses()
        this.getPastClassesAndCourseSessions()
    },
    data() {
        return {
            upcomingClassesAndCourseSessions: [],
            waitlistedClasses: [],
            pastClassesAndCourseSessions: [],
            pastClassesAndCoursesRaw: [],
            showUpcomingClasses: true,
            showWaitlistClasses: false,
            showPastClasses: false,
            noUpcomingData: false,
            noPastData: false,
            noWaitlistData: false,
            upcomingDataLoading: false,
            waitlistDataLoading: false,
            pastDataLoading: false,
            bookBtn: false,
            lastPastClassId: '',
            lastPastCourseId: '',
            cancelFeePaymentError: '',
        }
    },
    computed: {
        isUpcoming() {
            return (
                this.showUpcomingClasses &&
                !this.showWaitlistClasses &&
                !this.showPastClasses
            )
        },
        isWaitlist() {
            return (
                !this.showUpcomingClasses &&
                this.showWaitlistClasses &&
                !this.showPastClasses
            )
        },
        isPast() {
            return (
                !this.showUpcomingClasses &&
                !this.showWaitlistClasses &&
                this.showPastClasses
            )
        },
        ...mapGetters({
            user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light'
        },
    },
    methods: {
        refreshList() {
            this.upcomingClassesAndCourseSessions = []
            this.waitlistedClasses = []
            this.pastClassesAndCourseSessions = []
            this.pastClassesAndCoursesRaw = []
            this.getUpcomingClassesAndCourseSessions()
            this.getWaitlistClasses()
            this.getPastClassesAndCourseSessions()
        },
        ...mapActions({
            setAlertStateAction: `${ALERT_MODULE_NAME}/setAlertStateAction`,
        }),
        formatArrayToObject(classes, formattedObject) {
            for (let item of classes) {
                const dateKey = dateStringFromTimestamp(
                    item.startTimestamp,
                    this.studio.timezone,
                    'YYYY-MM-DD'
                )
                if (!formattedObject.has(dateKey)) {
                    formattedObject.set(dateKey, [])
                }
                item.timeStart = timeStringFromTimestamp(
                    item.classDate,
                    this.studio.timezone
                )
                item.timeEnd = timeStringFromTimestamp(
                    item.endTimestamp,
                    this.studio.timezone
                )
                formattedObject.get(dateKey).push(item)
            }
            return formattedObject
        },
        async getClassesAndCourseSessions(isPast) {
            const promises = []
            const studioId = this.studio.id
            if (isPast) {
                promises.push(
                    getUserPastClasses(studioId, this.lastPastClassId)
                )
                promises.push(
                    getPastUserCourseSessions(studioId, this.lastPastCourseId)
                )
            } else {
                promises.push(getUserUpcomingClasses(studioId))
                promises.push(getUpcomingUserCourseSessions(studioId))
            }
            return Promise.all(promises).then((values) => {
                const formattedObject = new Map()
                const classesRes = values[0]
                const coursesRes = values[1]
                this.formatClasses(classesRes, formattedObject)
                this.formatCourses(coursesRes, formattedObject)
                if (isPast) {
                    if (classesRes.docs && classesRes.docs.length > 0) {
                        this.lastPastClassId =
                            classesRes.docs[classesRes.docs.length - 1].id
                    }
                    if (coursesRes.docs && coursesRes.docs.length > 0) {
                        this.lastPastCourseId =
                            coursesRes.docs[coursesRes.docs.length - 1].id
                    }
                    this.pastClassesAndCoursesRaw = [
                        ...(classesRes.docs || []),
                        ...(coursesRes.docs || []), // if courseRes.docs is undefined, it will spread an empty array
                    ]
                    this.pastClassesAndCourseSessions = []
                    const sortedDates = Array.from(formattedObject.keys()).sort(
                        (a, b) => new Date(b).getTime() - new Date(a).getTime()
                    ) // descending
                    sortedDates.forEach((date) => {
                        this.pastClassesAndCourseSessions.push({
                            date: convertDateFormat(date),
                            item: formattedObject.get(date),
                        })
                    })
                } else {
                    const sortedDates = Array.from(formattedObject.keys()).sort(
                        (a, b) => new Date(a).getTime() - new Date(b).getTime()
                    ) // ascending
                    this.upcomingClassesAndCourseSessions = []
                    sortedDates.forEach((date) => {
                        this.upcomingClassesAndCourseSessions.push({
                            date: convertDateFormat(date),
                            item: formattedObject.get(date),
                        })
                    })
                }
            })
        },
        formatClasses(res, formattedObject) {
            if (res.success) {
                return this.formatArrayToObject(res.docs, formattedObject)
            } else {
                return []
            }
        },
        formatCourses(res, formattedObject) {
            if (res.success) {
                return this.formatCourseRegistrationsToObject(
                    res.docs,
                    formattedObject
                )
            } else {
                return []
            }
        },
        formatCourseRegistrationsToObject(courses, formattedObject) {
            for (const course of courses) {
                this.formatCourseRegistrationToObject(course, formattedObject)
            }
            return formattedObject
        },
        formatCourseRegistrationToObject(course, formattedObject) {
            const {
                courseName,
                courseOfferingId,
                courseOfferingImage,
                studioId,
                location,
                instructor,
            } = course
            const item = {
                courseName,
                courseOfferingId,
                courseOfferingImage,
                studioId,
                location,
                instructor,
            }
            const itemWithTiming = {
                ...item,
                name: courseName,
                classId: course.id,
            }
            const dateKey = dateStringFromTimestamp(
                course.startTimestamp,
                this.studio.timezone,
                'YYYY-MM-DD'
            )
            if (!formattedObject.has(dateKey)) {
                formattedObject.set(dateKey, [])
            }
            itemWithTiming.timeStart = timeStringFromTimestamp(
                course.startTimestamp,
                this.studio.timezone
            )
            itemWithTiming.timeEnd = timeStringFromTimestamp(
                course.endTimestamp,
                this.studio.timezone
            )
            formattedObject.get(dateKey).push(itemWithTiming)
            return formattedObject
        },
        async getUpcomingClassesAndCourseSessions() {
            this.upcomingDataLoading = true
            await this.getClassesAndCourseSessions(false)
            const len = Object.keys(
                this.upcomingClassesAndCourseSessions
            ).length
            if (len === 0) {
                this.noUpcomingData = true
            }
            this.upcomingDataLoading = false
        },
        async getPastClassesAndCourseSessions() {
            this.pastDataLoading = true
            await this.getClassesAndCourseSessions(true)
            const len = Object.keys(this.pastClassesAndCourseSessions).length
            if (len === 0) {
                this.noPastData = true
            }
            this.pastDataLoading = false
        },
        async getWaitlistClasses() {
            this.waitlistDataLoading = true
            const studioId = this.studio.id
            await getUserWaitlist(studioId).then((res) => {
                if (res.success) {
                    if (res.docs.length === 0) {
                        this.noWaitlistData = true
                    }
                    const formattedObject = new Map()
                    this.formatArrayToObject(res.docs, formattedObject)
                    this.waitlistedClasses = []
                    const sortedDates = Array.from(formattedObject.keys()).sort(
                        (a, b) => new Date(a).getTime() - new Date(b).getTime()
                    ) // ascending
                    sortedDates.forEach((date) => {
                        this.waitlistedClasses.push({
                            date: convertDateFormat(date),
                            item: formattedObject.get(date),
                        })
                    })
                }
                this.waitlistDataLoading = false
            })
        },
        handleUpcomingClasses() {
            this.$gtag.event(CLICK_UPCOMING_CLASSES)
            this.showUpcomingClasses = true
            this.showWaitlistClasses = false
            this.showPastClasses = false
        },
        handleWaitlistedClasses() {
            this.$gtag.event(CLICK_WAITLIST)
            this.showUpcomingClasses = false
            this.showWaitlistClasses = true
            this.showPastClasses = false
        },
        handlePastClasses() {
            this.$gtag.event(CLICK_PAST_CLASSES)
            this.showUpcomingClasses = false
            this.showWaitlistClasses = false
            this.showPastClasses = true
        },
        handleCancelledSuccess() {
            this.upcomingClasses = {}
            this.getUpcomingClassesAndCourseSessions()
            let payload = {
                type: 'success',
                message: this.$t('message.emailConfirmCancellationSent'),
            }
            if (this.cancelFeePaymentError !== '') {
                payload = {
                    type: 'info',
                    message: this.cancelFeePaymentError,
                }
            }
            this.setAlertStateAction(payload)

            this.cancelFeePaymentError = ''
        },
        handleCancelFeePaymentError(err) {
            this.cancelFeePaymentError = err
        },
        handleAssignFamilyUserSuccess() {
            this.upcomingClasses = {}
            this.getUpcomingClassesAndCourseSessions()
            const payload = {
                type: 'success',
                message: 'Success!',
            }
            this.setAlertStateAction(payload)
        },
        handleWaitlistSuccess() {
            this.waitlistedClasses = {}
            this.getWaitlistClasses()
            const payload = {
                type: 'success',
                message: this.$t('message.emailConfirmRemovalFromWaitlistSent'),
            }
            this.setAlertStateAction(payload)
        },
    },
}
</script>
