<template>
    <div class="d-flex flex-row justify-center">
        <!-- Button to toggle Card -->
        <v-btn
            :block="block"
            depressed
            color="primary"
            @click="openDialog"
            v-show="!hideButton"
        >
            {{ buttonText }}
        </v-btn>

        <v-dialog
            v-model="dialog"
            :fullscreen="isMobileScreen"
            :width="dialogWidth"
            persistent
        >
            <v-card class="d-flex flex-column">
                <v-window
                    v-model="step"
                    :key="windowKey"
                    touchless
                    v-if="dialog"
                >
                    <!-- Header Steppers -->
                    <v-stepper v-model="step" alt-labels>
                        <v-stepper-header>
                            <template
                                v-for="(component, index) in windowComponents"
                            >
                                <v-stepper-step
                                    :key="index"
                                    :step="parseInt(index) + 1"
                                >
                                    {{ component.header }}
                                </v-stepper-step>
                                <v-divider
                                    v-if="index !== windowComponents.length - 1"
                                    :key="component.componentName"
                                ></v-divider>
                            </template>
                        </v-stepper-header>
                    </v-stepper>

                    <!-- Window Components -->
                    <v-window-item
                        v-for="(component, index) in windowComponents"
                        :key="index"
                        :value="parseInt(index) + 1"
                    >
                        <component
                            :is="component.componentName"
                            class="pa-7"
                            v-bind="{
                                ...component.props,
                                packageId: packageIdForBookClass,
                            }"
                            @success="handleSuccess"
                            @failure="handleFailure"
                            @start="startLoading"
                            @end="stopLoading"
                        />
                    </v-window-item>
                </v-window>

                <v-spacer></v-spacer>

                <!-- Cancel/Done Button-->
                <v-btn
                    :color="btnColor"
                    @click="closeDialog"
                    v-if="isNotLoading"
                >
                    {{ isLastStep ? $t('message.done') : $t('message.cancel') }}
                </v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import BookClassLoader from '@/components/checkout/BookClassLoader'
import CustomFieldsProfile from '@/components/profile/CustomFieldsProfile'
import MobileNumber from '@/components/checkout/MobileNumber'
import PurchaseAgreement from '@/components/checkout/PurchaseAgreement'
import SuccessStatus from '@/components/checkout/SuccessStatus'
import StripeCard from '@/components/stripe/StripeCard'
import { auth, sendEmailVerification } from '@/firebase'
import {
    ALERT_MODULE_NAME,
    AUTHENTICATION_MODULE_NAME,
    STUDIO_MODULE_NAME,
    CUSTOM_FIELDS_MODULE_NAME,
} from '@/store/moduleNames'
import getAuthErrorMessage from '@/util/authErrors'
import { mapActions, mapGetters } from 'vuex'

// Analytics
import {
    BEGIN_CHECKOUT,
    CANCEL_CHECKOUT,
    FINISH_CHECKOUT,
} from '@/analytics/events'

export default {
    props: {
        packageItem: Object, // If a package purchase is involved
        classItem: Object, // if a class booking is involved
        packageId: String, // package used for booking class
        spotNumbers: Array,
        numSpots: Number,
        buttonText: String,
        hideSpots: {
            default: false,
            type: Boolean,
        },
        block: {
            default: false,
            type: Boolean,
        },
        isPurchasePackage: {
            default: false,
            type: Boolean,
        },
        isBookClass: {
            default: false,
            type: Boolean,
        },
        isWaitlistClass: {
            default: false,
            type: Boolean,
        },
        isPurchaseCourse: {
            default: false,
            type: Boolean,
        },
        courseItem: Object,
        hideButton: {
            default: false,
            type: Boolean,
        },
    },
    components: {
        PurchaseAgreement,
        StripeCard,
        BookClassLoader,
        SuccessStatus,
        MobileNumber,
        CustomFieldsProfile,
    },
    data() {
        return {
            dialog: false,
            isLoading: false,
            isFailure: false,
            step: 1,
            windowKey: false,
            sendVerificationEmailTimeout: 10000, // 10 seocnds buffer, to avoid sending too many emails
            canSendVerificationEmail: true,
            defaultWindowComponents: [
                {
                    header: this.$t('message.mobileNumber'),
                    componentName: 'MobileNumber',
                },
                {
                    header: this.$t('message.agreementForm'),
                    componentName: 'PurchaseAgreement',
                },
                {
                    header: this.$t('message.studioDetailsTitle'),
                    componentName: 'CustomFieldsProfile',
                },
                {
                    header: this.$t('message.buyPackage'),
                    componentName: 'StripeCard',
                },
                {
                    header: this.$t('message.bookClass'),
                    componentName: 'BookClassLoader',
                },
                {
                    header: this.$t('message.status'),
                    componentName: 'SuccessStatus',
                },
            ],
            purchasedPackageId: '',
            localHasUnfilledCustomField: null,
        }
    },
    computed: {
        btnColor() {
            if (!this.isLastStep) {
                return ''
            }

            return !this.isFailure ? 'success' : 'error'
        },
        isMobileScreen() {
            return this.$vuetify.breakpoint.width < 600
        },
        dialogWidth() {
            return this.isMobileScreen ? '100vw' : '800px'
        },
        isNotLoading() {
            return !(this.isLoading && this.isBookClass)
        },
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
            isUserMobileNumberRegistered: `${AUTHENTICATION_MODULE_NAME}/isUserMobileNumberRegistered`,
            hasUnfilledCustomFields: `${CUSTOM_FIELDS_MODULE_NAME}/hasUnfilledCustomFields`,
        }),
        isEmailVerified() {
            return true
        },
        packageIdForBookClass() {
            if (this.isPurchasePackage) {
                return this.purchasedPackageId
            }
            if (this.isBookClass) {
                return this.packageId
            }
            return ''
        },
        studioRequiresMobileNumber() {
            return this.studio.requirePhoneNumber
        },
        windowComponents() {
            const components = this.defaultWindowComponents.map((component) => {
                switch (component.componentName) {
                    case 'MobileNumber':
                        if (
                            this.isPurchasePackage &&
                            !this.isUserMobileNumberRegistered &&
                            this.studioRequiresMobileNumber
                        ) {
                            return {
                                ...component,
                                props: {
                                    studioName: this.studio.studioName,
                                    country: this.studio.country,
                                },
                            }
                        }
                        return false
                    case 'CustomFieldsProfile':
                        if (
                            this.hasUnfilledCustomFields ||
                            this.localHasUnfilledCustomField
                        ) {
                            this.localHasUnfilledCustomField = true
                            return {
                                ...component,
                                props: {
                                    studioId: this.studio.id,
                                    isEditMode: true,
                                    hasUnfilledCustomFields:
                                        this.hasUnfilledCustomFields,
                                },
                            }
                        }
                        return false
                    case 'PurchaseAgreement':
                        if (this.isPurchasePackage || this.isPurchaseCourse) {
                            return {
                                ...component,
                                props: {
                                    html: this.studio.agreementForm,
                                },
                            }
                        }
                        return false
                    case 'StripeCard':
                        if (this.isPurchasePackage) {
                            return {
                                ...component,
                                props: {
                                    packageItem: this.packageItem,
                                    classId: this.classItem
                                        ? this.classItem.id
                                        : undefined,
                                    spotNumbers: this.spotNumbers,
                                },
                            }
                        } else if (this.isPurchaseCourse) {
                            return {
                                ...component,
                                props: {
                                    isPurchaseCourse: true,
                                    courseItem: this.courseItem,
                                },
                            }
                        }
                        return false
                    case 'BookClassLoader':
                        if (this.isBookClass) {
                            return {
                                ...component,
                                props: {
                                    classId: this.classItem.id,
                                    spotNumbers: this.spotNumbers,
                                    numSpots: this.numSpots,
                                    hideSpots: this.hideSpots,
                                    isWaitlistClass: this.isWaitlistClass,
                                },
                            }
                        }
                        return false
                    case 'SuccessStatus':
                        if (!this.isFailure) {
                            return {
                                ...component,
                                props: {
                                    packageId: this.purchasedPackageId,
                                    isBookClass: this.isBookClass,
                                    spotNumbers: this.spotNumbers,
                                    classId: this.classItem?.id,
                                    isFamilyBookable:
                                        this.classItem?.isFamilyBookable,
                                    isWaitlistClass: this.isWaitlistClass,
                                },
                            }
                        }
                        break
                    default:
                        return false
                }
            })
            return components.filter((component) => component)
        },
        isLastStep() {
            return this.step === this.windowComponents.length
        },
    },
    methods: {
        startLoading() {
            this.isLoading = true
        },
        stopLoading() {
            this.isLoading = false
        },
        ...mapActions({
            setAlertStateAction: `${ALERT_MODULE_NAME}/setAlertStateAction`,
        }),
        closeDialog() {
            this.dialog = false
            if (this.step === this.windowComponents.length - 1) {
                this.$gtag.event(FINISH_CHECKOUT)
            } else {
                this.$gtag.event(CANCEL_CHECKOUT)
            }
            this.step = 1
            this.toggleKey()
            this.localHasUnfilledCustomField = this.hasUnfilledCustomFields
            this.$emit('closeDialog')
        },
        openDialog() {
            if (!this.studio.stripeAccountId) {
                if (this.isPurchasePackage) {
                    if (parseFloat(this.packageItem.price) > 0) {
                        this.setStripeNotSetUpAlert()
                        return
                    }
                }
                if (this.isPurchaseCourse) {
                    if (parseFloat(this.courseItem.price) > 0) {
                        this.setStripeNotSetUpAlert()
                        return
                    }
                }
            }

            if (!this.isUserAuth) {
                const payload = {
                    type: 'error',
                    message: this.$t('message.loginToPurchaseOrBookClass'),
                }
                this.setAlertStateAction(payload)
                return
            }

            if (this.isUserAuth && !this.isEmailVerified) {
                this.sendVerificationEmail()
                this.$emit('error', this.$t('message.errorVerifyEmail'))
                return
            }
            this.$gtag.event(BEGIN_CHECKOUT)
            this.dialog = true
        },
        toggleKey() {
            this.windowKey = !this.windowKey
        },
        setStripeNotSetUpAlert() {
            const payload = {
                type: 'info',
                message: this.$t('message.errorSetupStripe', {
                    studioName: this.studio.studioName,
                }),
            }
            this.setAlertStateAction(payload)
        },
        async sendVerificationEmail() {
            if (!this.canSendVerificationEmail) {
                return
            }

            this.canSendVerificationEmail = false
            let payload = {}
            await sendEmailVerification(auth.currentUser)
                .then(() => {
                    payload = {
                        type: 'info',
                        message: this.$t(
                            'message.errorVerifyEmailToPurchaseOrBookClass'
                        ),
                    }
                })
                .catch((error) => {
                    payload = {
                        type: 'error',
                        message: getAuthErrorMessage(error.code),
                    }
                })
            this.setAlertStateAction(payload)
            setTimeout(() => {
                this.canSendVerificationEmail = true
            }, this.sendVerificationEmailTimeout)
        },
        handleSuccess(packageId) {
            if (this.isPurchasePackage && packageId) {
                this.purchasedPackageId = packageId
            }
            this.step++
        },
        handleFailure() {
            this.isFailure = true
        },
    },
}
</script>
