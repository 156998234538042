import { functions, httpsCallable } from '@/firebase'

export async function listStudioMemberPaymentMethods(studioId) {
    const inputs = { studioId }
    const listStudioMemberPaymentMethodsFn = httpsCallable(
        functions,
        'listStudioMemberPaymentMethods'
    )
    return listStudioMemberPaymentMethodsFn(inputs)
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

export async function deleteStudioMemberPaymentMethod(
    studioId,
    paymentMethodId
) {
    const inputs = { studioId, paymentMethodId }

    const deleteStudioMemberPaymentMethodFn = httpsCallable(
        functions,
        'deleteStudioMemberPaymentMethod'
    )
    return deleteStudioMemberPaymentMethodFn(inputs)
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

export async function updateRecurringPackagePaymentMethod(
    studioId,
    paymentMethodId,
    packageId
) {
    const inputs = { studioId, paymentMethodId, packageId }
    const updateRecurringPackagePaymentMethodFn = httpsCallable(
        functions,
        'updateRecurringPackagePaymentMethod'
    )
    return updateRecurringPackagePaymentMethodFn(inputs)
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}
