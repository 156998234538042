<template>
    <v-card height="100%">
        <v-carousel height="200px">
            <v-carousel-item
                v-for="(image, i) in product.images"
                :key="i"
                :src="image"
            ></v-carousel-item>
        </v-carousel>

        <v-card-title>
            <div class="product-name">{{ product.name }}</div>
        </v-card-title>

        <v-card-subtitle>
            {{ product.category.name }}
        </v-card-subtitle>

        <v-card-text class="ordinary--text">
            <p class="description">{{ product.description }}</p>
            <div class="d-flex justify-space-between">
                <div>
                    <strong class="price">{{
                        `${studio.currency}${product.price}`
                    }}</strong>
                </div>

                <div class="d-flex flex-column justify-space-between right-col">
                    <v-btn
                        ref="add-to-cart-btn"
                        color="primary"
                        :loading="loading"
                        @click="addToCart"
                    >
                        <v-icon left>mdi-cart</v-icon>
                        <span class="mdi mdi-plus">{{
                            $t('message.addCart')
                        }}</span>
                    </v-btn>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { addToCart } from '@/util/cloudFunctions/shoppingCart'

import { ALERT_MODULE_NAME, STUDIO_MODULE_NAME } from '@/store/moduleNames'
import { mapGetters, mapActions } from 'vuex'
import { AUTHENTICATION_MODULE_NAME } from '../../store/moduleNames'
import { NOT_LOGIN_ADD_CART_ERROR } from '../../util/merchandise/errors'

export default {
    props: {
        product: Object,
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        ...mapActions({
            setAlertStateAction: `${ALERT_MODULE_NAME}/setAlertStateAction`,
        }),
        async addToCart() {
            if (this.loading) {
                return
            }
            this.loading = true
            if (!this.userAuth) {
                // if user is not logged in, throw error
                this.setAlertStateAction({
                    type: 'error',
                    message: this.$t('message.failedAddCart', {
                        error: NOT_LOGIN_ADD_CART_ERROR,
                    }),
                })
            } else {
                // if user is logged in, make API call
                const result = await addToCart(
                    this.studio.id,
                    this.product.id,
                    1
                )
                if (!result.success) {
                    // if API call returns failure, display error message
                    this.setAlertStateAction({
                        type: 'error',
                        message: this.$t('message.failedAddCart', {
                            error: result.error,
                        }),
                        timeout: 3000,
                    })
                } else {
                    // if API call success, display success message
                    this.setAlertStateAction({
                        type: 'success',
                        message: this.$t('message.successAddCart', {
                            productName: this.product.name,
                        }),
                        timeout: 3000,
                    })
                    this.$emit('addToCart')
                }
            }
            this.loading = false
        },
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
            userAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
        }),
    },
}
</script>

<style scoped>
.product-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.price {
    line-height: 36px;
    display: block;
}

.description {
    position: relative;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}

.description:after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 34px;
    height: 20px;
    content: '';
    background: var(--v-card-base);
    -webkit-mask-image: linear-gradient(
        to left,
        var(--v-ordinary-base),
        transparent
    );
}
</style>
