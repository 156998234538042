import {
    getStudioCustomField,
    getStudioMemberCustomField,
} from '@/util/cloudFunctions/studioMemberCustomFieldFunctions'

export const CustomDataType = {
    FREETEXT: 'Free Text',
    DROPDOWN: 'Drop Down',
    CHECKBOX: 'Check Box',
}

export const REQUIRED_FIELD_EMPTY_COLOR = 'red lighten-4'

export function parseStudioCustomData(studioCustomData) {
    if (!studioCustomData || !studioCustomData.length) {
        return undefined
    }

    const result = {}

    studioCustomData.forEach((data) => {
        result[data.fieldId] = {
            fieldType: data.fieldType,
            question: data.question,
            description: data.description,
            value: data.value,
        }
    })

    return result
}

export async function hasUnfilledCustomFields(userId, studioId) {
    // this.user is undefined in sign up page
    if (!userId) {
        return false
    }

    const [result, studioCustomField] = await Promise.all([
        getStudioMemberCustomField({
            userId: userId,
            studioId: studioId,
        }),
        getStudioCustomField(studioId),
    ])

    if (!result && !result.success) {
        return false
    }
    const memberCustomData = result.memberCustomData

    if (!studioCustomField && !studioCustomField.studioCustomDatas) {
        return false
    }

    // for each studio custom field, check if it is required and active and editable if it is empty
    for (const fieldId in studioCustomField.studioCustomDatas) {
        const field = studioCustomField.studioCustomDatas[fieldId]

        const memberAnswerObject = memberCustomData[fieldId]
        const memberAnswer = memberAnswerObject ? memberAnswerObject.value : ''

        if (field.isRequired && field.isActive && field.isEditable) {
            if (!memberAnswerObject || !memberAnswer) {
                return true
            }

            // Check if it is a drop down and the value is not in the options
            if (field.fieldType === 'Drop Down') {
                const isValueInOptions = field.options.some(
                    (option) => option.value === memberAnswer
                )
                if (!isValueInOptions) {
                    return true
                }
            }
        }
    }
    return false
}
