import {
    dateStringFromTimestamp,
    timeStringFromTimestamp,
    convertDateFormat,
} from '@/util/dateformat.js'

/**
 * Formats the class object to be displayed in the schedule.
 *
 * For example:
 *
 * [ {class1}, {class2}, {class3} ] becomes
 * [
 *  {date: '1st January, 2021', item: [ {class1}, {class2} ], value: '2021-01-01'},
 *  {date: '2nd January, 2021', item: [ {class3} ], value: '2021-01-02'}
 * ]
 */
export function groupClassesByDate(classes, timezone) {
    let formattedObject = new Map()
    for (let item of classes) {
        const dateKey = dateStringFromTimestamp(
            item.startTimestamp,
            timezone,
            'YYYY-MM-DD'
        )
        if (!formattedObject.has(dateKey)) {
            formattedObject.set(dateKey, [])
        }
        item.timeStart = timeStringFromTimestamp(item.startTimestamp, timezone)
        item.timeEnd = timeStringFromTimestamp(item.endTimestamp, timezone)
        formattedObject.get(dateKey).push(item)
    }
    const sortedDates = Array.from(formattedObject.keys()).sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime()
    )
    // By this point, all the dates are sorted and converted to the studio's timezone
    return sortedDates.map((date) => {
        return {
            date: convertDateFormat(date),
            item: formattedObject.get(date),
            value: date,
        }
    })
}
