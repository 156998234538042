<template>
    <div>
        <v-btn
            :class="{ 'btn-style': largeBtnStyle }"
            outlined
            @click="openSelectSpotDialog"
            v-on:click.prevent
            :loading="loadingData"
        >
            {{ $t('message.classCancel') }}
        </v-btn>
        <!-- Dialog for Deletion of Class Registration  -->
        <WarningDialog
            :showDialog="dialog"
            :title="this.$t('message.classCancelSignup')"
            :content="warningDialogContent"
            :cancelCallback="onCloseWarningDialog"
            :confirmCallback="onConfirmedWarning"
            :errorMessage="errorMessage"
            :loading="loadingData"
            :submitting="loadingSubmission"
        >
        </WarningDialog>

        <!-- Dialog for Selecting Spot to Cancel-->
        <SelectSpotDialog
            :showDialog="selectSpotDialog"
            :subItems="subItems"
            :cancelCallback="onCloseSelectSpotDialog"
            :confirmCallback="onSelectedSpotIdsToCancel"
            :loading="loadingData"
            :submitting="loadingSubmission"
            @cancelSuccess="() => $emit('success')"
            :isSpotsHidden="isSpotsHidden"
        >
        </SelectSpotDialog>
    </div>
</template>

<script>
import { canRefund } from '@/components/cancelclass/util'
import WarningDialog from '@/components/shared/WarningDialog'
import {
    cancelClass,
    getLateCancellationStatus,
} from '@/util/cloudFunctions/classesFunctions'
import SelectSpotDialog from './SelectSpotDialog.vue'
// Analytics
import { CANCEL_CLASS, CANCEL_CLASS_ERROR } from '@/analytics/events'
import { subStr } from '@/analytics/util'

export default {
    props: {
        subItems: Array, // classSignUp model
        largeBtnStyle: Boolean, // to make button larger style for use in BookClass Component
        loadingData: Boolean, // to show loading spinner on button if still fetching data
        isSpotsHidden: Boolean, // to affect SelectSpotDialog to show spots or just choose number of spots
    },
    components: {
        WarningDialog,
        SelectSpotDialog,
    },
    data() {
        return {
            dialog: false,
            selectSpotDialog: false,
            errorsArray: [],
            loadingSubmission: false,
            spotIdsToCancel: [],
            cancelFeePaymentErrorSpots: [],
            isLateChargable: false,
            lateCancellationFee: 0,
        }
    },
    computed: {
        warningDialogContent() {
            if (this.loadingData) {
                return ''
            }
            if (this.subItems?.some((item) => canRefund(item))) {
                // if any spot can be refunded
                return this.$t('message.classCancelWarningDialogRefund')
            } else if (this.isLateChargable) {
                return this.$t('message.classCancelWarningDialogPayCancelFee', {
                    lateCancellationFee: this.lateCancellationFee,
                })
            } else if (
                this.subItems?.some((item) => item.isBookedFromSmartFill)
            ) {
                return this.$t('message.classCancelWarningDialogSmartFill')
            } else {
                // late cancellation and no refund
                return this.$t('message.classCancelWarningDialogNoRefund')
            }
        },
        errorMessage() {
            if (this.errorsArray.length === 0) {
                return ''
            }
            return this.errorsArray.join('\n')
        },
        cancelFeePaymentErrorMessage() {
            if (this.isSpotsHidden) {
                return this.$t(
                    'message.cancelFeePaymentErrorMessageSpotsHidden',
                    {
                        length: this.cancelFeePaymentErrorSpots.length,
                    }
                )
            }

            const spotStrings = this.cancelFeePaymentErrorSpots.join(', ')
            return this.$t('message.cancelFeePaymentErrorMessage', {
                spotStrings: spotStrings,
            })
        },
    },
    methods: {
        onConfirmedWarning() {
            // if spotIds not set, means only one spot to cancel
            if (this.spotIdsToCancel.length === 0) {
                this.spotIdsToCancel = [this.subItems[0].id]
            }
            this.cancelSignups(this.spotIdsToCancel)
            this.spotIdsToCancel = []
        },
        onSelectedSpotIdsToCancel(spotIds) {
            this.spotIdsToCancel = spotIds
            this.openWarningDialog()
        },
        async cancelClassAndTag(spotId) {
            const item = this.subItems.find((item) => item.id === spotId)
            const data = {
                signupId: spotId,
                canRefund: canRefund(item),
            }
            const cancelResult = await cancelClass(data)

            if (cancelResult.success) {
                this.$gtag.event(CANCEL_CLASS)
                if (cancelResult.error) {
                    // cancel success but got payment error
                    this.cancelFeePaymentErrorSpots.push(item.spotNumber)
                }
            } else {
                this.$gtag.event(CANCEL_CLASS_ERROR, {
                    errorMessage: subStr(cancelResult.error),
                })
                // NOTE: Error message can't translate dynamically with i18n
                this.errorsArray.push(
                    `Error cancelling Spot ${
                        this.isSpotsHidden ? '' : item.spotNumber
                    }: ${cancelResult.error}`
                )
            }
            return cancelResult
        },
        async cancelSignups(spotIds) {
            this.loadingSubmission = true
            const promises = spotIds.map((spotId) =>
                this.cancelClassAndTag(spotId)
            )

            const result = await Promise.all(promises)
            const allSuccess = result.every((res) => res.success)
            if (this.cancelFeePaymentErrorSpots.length > 0) {
                this.$emit(
                    'cancelFeePaymentError',
                    this.cancelFeePaymentErrorMessage
                )
            }
            if (allSuccess) {
                this.$emit('success')
                this.onCloseSelectSpotDialog()
            }
            this.loadingSubmission = false
        },
        openWarningDialog() {
            this.selectSpotDialog = false // prevent warning dialog behind select spot dialog bug
            this.fetchLateCancellationStatus()
            this.dialog = true
        },
        onCloseWarningDialog() {
            this.dialog = false
            if (this.subItems.length > 1) {
                this.selectSpotDialog = true
            }
        },
        openSelectSpotDialog() {
            // if still loading data, could be that more subItems are not loaded yet
            if (this.subItems.length === 1 && !this.loadingData) {
                this.openWarningDialog()
            } else {
                // if multiple spots booked
                this.dialog = false
                this.selectSpotDialog = true
            }
        },
        onCloseSelectSpotDialog() {
            this.selectSpotDialog = false
            this.dialog = false
        },
        async fetchLateCancellationStatus() {
            this.loadingData = true
            const item = this.subItems[0]
            const { isLateChargable, lateCancellationFee } =
                await getLateCancellationStatus(item.id)
            this.isLateChargable = isLateChargable
            this.lateCancellationFee = lateCancellationFee
            this.loadingData = false
        },
    },
}
</script>

<style scoped>
.btn-style {
    width: 100%;
    height: 50px !important;
    margin-bottom: 10px;
    margin-top: 10px;
}
</style>
