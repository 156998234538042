import Vue from 'vue'
import Vuex from 'vuex'
import { authenticationModule } from '@/store/modules/authenticationModule'
import { alertModule } from '@/store/modules/alertModule'
import { studioModule } from '@/store/modules/studioModule'
import { customFieldsModule } from '@/store/modules/customFieldsModule'
import {
    AUTHENTICATION_MODULE_NAME,
    ALERT_MODULE_NAME,
    STUDIO_MODULE_NAME,
    CUSTOM_FIELDS_MODULE_NAME,
} from '@/store/moduleNames'

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        [AUTHENTICATION_MODULE_NAME]: authenticationModule,
        [ALERT_MODULE_NAME]: alertModule,
        [STUDIO_MODULE_NAME]: studioModule,
        [CUSTOM_FIELDS_MODULE_NAME]: customFieldsModule,
    },
})
