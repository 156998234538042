<template>
    <v-col>
        <v-row class="justify-center mb-5" no-gutters>
            <h3 class="ml-2">{{ $t('message.purchaseAgreement') }}</h3>
        </v-row>

        <v-col class="purchase-agreement-container">
            <div v-html="html" class="ordinary--text" />
            <v-checkbox
                class="ordinary--text text-center"
                :label="$t('message.agreeCheckbox')"
                v-model="hasAgreed"
            ></v-checkbox>
        </v-col>

        <v-row no-gutters class="pt-3 justify-center">
            <v-btn
                :disabled="!hasAgreed"
                color="primary"
                @click="emitSuccess"
                >{{ $t('message.proceedPayment') }}</v-btn
            >
        </v-row>
    </v-col>
</template>
<script>
export default {
    props: {
        html: String,
    },
    data() {
        return {
            hasAgreed: false,
        }
    },
    methods: {
        emitSuccess() {
            this.$emit('success')
        },
    },
}
</script>
<style scoped>
.purchase-agreement-container {
    max-height: 65vh;
    overflow-y: scroll;
    overflow-x: scroll;

    @media screen and (min-width: 600px) {
        max-height: 35rem;
    }

    /* Custom scrollbar styles */
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
</style>
