import { initializeApp } from 'firebase/app'
import {
    getAuth,
    reauthenticateWithCredential,
    EmailAuthProvider,
    signInWithEmailAndPassword,
    signOut,
    FacebookAuthProvider,
    signInWithPopup,
    createUserWithEmailAndPassword,
    updatePassword,
    sendPasswordResetEmail,
    sendEmailVerification,
    deleteUser,
    fetchSignInMethodsForEmail,
} from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import {
    doc,
    getDoc,
    collection,
    query,
    where,
    getDocs,
    getFirestore,
    serverTimestamp,
    FieldValue,
} from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'

const ENV = process.env

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: ENV.VUE_APP_FIREBASE_API_KEY,
    authDomain: ENV.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: ENV.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: ENV.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: ENV.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: ENV.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: ENV.VUE_APP_FIREBASE_APP_ID,
    measurementId: ENV.VUE_APP_FIREBASE_MEASUREMENT_ID,
}
// Initialize Firebase
const app = initializeApp(firebaseConfig)

const auth = getAuth(app)
const storage = getStorage(app)
const db = getFirestore(app)
const functions = getFunctions()

export {
    auth,
    doc,
    getDoc,
    getDocs,
    collection,
    query,
    where,
    db,
    storage,
    FieldValue,
    signInWithEmailAndPassword,
    signOut,
    FacebookAuthProvider,
    EmailAuthProvider,
    reauthenticateWithCredential,
    signInWithPopup,
    functions,
    httpsCallable,
    serverTimestamp,
    createUserWithEmailAndPassword,
    updatePassword,
    sendPasswordResetEmail,
    sendEmailVerification,
    deleteUser,
    fetchSignInMethodsForEmail,
}
