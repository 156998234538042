import { functions, httpsCallable } from '@/firebase'

async function createCheckoutSessionForPackagePurchase(
    studioId,
    packageName,
    subscriptionId,
    promoCode,
    baseUrl,
    classId,
    spotNumbers
) {
    const inputs = {
        studioId,
        packageName,
        subscriptionId,
        promoCode,
        source: 'website',
        baseUrl,
        classId,
        spotNumbers,
    }
    const createCheckoutSessionForPackagePurchaseFn = httpsCallable(
        functions,
        'createCheckoutSessionForPackagePurchase'
    )
    return createCheckoutSessionForPackagePurchaseFn(inputs)
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

async function retrieveCheckoutSessionStatus(sessionId, stripeAccountId) {
    const inputs = { sessionId, stripeAccountId }
    const retrieveCheckoutSessionStatusFn = httpsCallable(
        functions,
        'retrieveCheckoutSessionStatus'
    )
    return retrieveCheckoutSessionStatusFn(inputs)
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

async function createCheckoutSessionForCoursePurchase(
    courseOfferingId,
    promoCode,
    baseUrl
) {
    const inputs = { courseOfferingId, baseUrl, promoCode, source: 'website' }
    const createCheckoutSessionForCoursePurchaseFn = httpsCallable(
        functions,
        'createCheckoutSessionForCoursePurchase'
    )
    return createCheckoutSessionForCoursePurchaseFn(inputs)
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

async function createCheckoutSessionForProductsPurchase(studioId, baseUrl) {
    const inputs = { studioId, baseUrl, source: 'website' }
    const createCheckoutSessionForProductsPurchaseFn = httpsCallable(
        functions,
        'createCheckoutSessionForProductsPurchase'
    )
    return createCheckoutSessionForProductsPurchaseFn(inputs)
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

export {
    createCheckoutSessionForCoursePurchase,
    createCheckoutSessionForPackagePurchase,
    retrieveCheckoutSessionStatus,
    createCheckoutSessionForProductsPurchase,
}

// Below are the cloud functions for Payment Methods
async function createCheckoutSessionToAddPaymentMethod(
    studioId,
    baseUrl,
    source
) {
    const inputs = { studioId, baseUrl, source }
    const createCheckoutSessionToAddPaymentMethodFn = httpsCallable(
        functions,
        'createSetUpSession'
    )
    return createCheckoutSessionToAddPaymentMethodFn(inputs)
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

export { createCheckoutSessionToAddPaymentMethod }
