<template>
    <v-card :max-width="cardMaxWidth" class="pb-3">
        <v-snackbar
            top
            :color="snackbarColor"
            :value="snackbar"
            :timeout="timeout"
        >
            {{ snackbarMessage }}
        </v-snackbar>

        <v-card-title v-if="isDialog">
            <v-icon color="white" left @click="closeDialog">mdi-close</v-icon>
            Family Members
        </v-card-title>

        <v-card-title v-else> Family Members </v-card-title>

        <div v-if="loading" class="mx-4">
            <v-skeleton-loader
                type="list-item-avatar-two-line"
                v-for="i in 2"
                :key="i"
            ></v-skeleton-loader>
        </div>

        <div
            v-if="!loading && familyUsers.length == 0"
            class="text-center font-weight-light mx-4 my-3"
        >
            No family members setup yet. Add your first family member below!
        </div>

        <div
            v-if="!loading && isSelectable"
            @click="selectFamilyUser()"
            class="text-center text-decoration-underline mx-4 my-3"
            :style="{ cursor: 'pointer' }"
        >
            Assign spot to myself
        </div>

        <v-divider class="mx-4"></v-divider>

        <div
            v-for="familyUser in familyUsers"
            :key="familyUser.id"
            class="mx-4"
        >
            <WarningDialog
                :showDialog="familyUser.deleteDialog"
                title="Are you sure?"
                :content="computeWarningDialogText(familyUser)"
                :confirmCallback="() => deleteFamilyUser(familyUser)"
                :cancelCallback="() => closeDeleteFamilyUserDialog(familyUser)"
                :loading="familyUser.deleteFamilyUserLoading"
                :errorMessage="familyUser.deleteFamilyUserErrorText"
            />

            <div class="d-flex flex-row align-center mx-3">
                <avatar :fullname="familyUser.fullName" />

                <div class="d-flex flex-column">
                    <v-card-title>
                        <div v-if="familyUser.isEditMode">
                            <v-text-field
                                dense
                                label="Full Name"
                                v-model="familyUser.editedFullName"
                            ></v-text-field>
                        </div>
                        <div v-else>
                            {{ familyUser.fullName }}
                        </div>
                    </v-card-title>
                    <v-card-subtitle class="text-start text-capitalize">
                        <div v-if="familyUser.isEditMode">
                            <v-select
                                dense
                                label="Relationship"
                                v-model="familyUser.editedRelationship"
                                :items="relationshipItems"
                                class="text-capitalize"
                            >
                                <template slot="item" slot-scope="data">
                                    <!-- HTML that describe how select should render items when the select is open -->
                                    <div class="text-capitalize">
                                        {{ data.item }}
                                    </div>
                                </template>
                            </v-select>
                        </div>
                        <div v-else>
                            {{ familyUser.relationship }}
                        </div>
                    </v-card-subtitle>
                </div>
                <v-spacer></v-spacer>

                <div v-if="familyUser.editFamilyUserLoading">
                    <v-progress-circular indeterminate />
                </div>

                <div
                    v-if="
                        familyUser.isEditMode &&
                        !familyUser.editFamilyUserLoading
                    "
                >
                    <v-icon @click="editFamilyUser(familyUser)">
                        mdi-check
                    </v-icon>
                    <v-icon class="ml-1" @click="resetEditMode(familyUser)">
                        mdi-close
                    </v-icon>
                </div>

                <div
                    class="d-flex flex-row flex-end"
                    v-if="
                        !familyUser.isEditMode &&
                        !familyUser.editFamilyUserLoading
                    "
                >
                    <v-icon @click="setEditMode(familyUser)">
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        class="ml-1"
                        @click="openDeleteFamilyUserDialog(familyUser)"
                    >
                        mdi-delete
                    </v-icon>
                </div>
            </div>

            <div v-if="isSelectable" class="text-end mb-3">
                <v-btn
                    small
                    class="primary"
                    @click="selectFamilyUser(familyUser)"
                >
                    Select
                </v-btn>
            </div>

            <v-divider></v-divider>
        </div>

        <div class="mx-4">
            <v-divider></v-divider>
            <div class="d-flex flex-row align-center mx-3">
                <avatar :fullname="fullName" />
                <div class="d-flex flex-column">
                    <v-card-title>
                        <v-text-field
                            dense
                            label="Full Name"
                            v-model="fullName"
                        ></v-text-field>
                    </v-card-title>
                    <v-card-subtitle class="text-start text-capitalize">
                        <v-select
                            dense
                            label="Relationship"
                            v-model="relationship"
                            :items="relationshipItems"
                            class="text-capitalize"
                        >
                            <template slot="item" slot-scope="data">
                                <!-- HTML that describe how select should render items when the select is open -->
                                <div class="text-capitalize">
                                    {{ data.item }}
                                </div>
                            </template>
                        </v-select>
                    </v-card-subtitle>
                </div>
                <v-spacer></v-spacer>

                <div class="d-sm-flex d-none flex-row flex-end">
                    <v-btn
                        depressed
                        class="secondary"
                        @click="addFamilyUser"
                        :loading="addFamilyUserLoading"
                    >
                        Add
                    </v-btn>
                </div>
            </div>

            <div class="text-right d-block d-sm-none">
                <v-btn
                    depressed
                    class="secondary"
                    @click="addFamilyUser"
                    :loading="addFamilyUserLoading"
                >
                    Add
                </v-btn>
            </div>
        </div>
    </v-card>
</template>
<script>
import Avatar from 'vue-avatar-component'
import WarningDialog from '@/components/shared/WarningDialog'
import {
    getFamilyUsers,
    createFamilyUser,
    editFamilyUser,
    deleteFamilyUser,
} from '@/util/cloudFunctions/familyUsers'

export default {
    components: {
        Avatar,
        WarningDialog,
    },
    props: {
        userId: String,
        cardMaxWidth: {
            type: String,
            default: '800',
        },
        isSelectable: {
            type: Boolean,
            default: false,
        },
        isDialog: {
            type: Boolean,
            default: false,
        },
    },
    async created() {
        this.loading = true
        await this.setFamilyUsers()
        this.loading = false
    },
    data() {
        return {
            loading: true,
            familyUsers: [],
            addFamilyUserLoading: false,
            fullName: '',
            relationship: '',
            relationshipItems: [
                'son',
                'daughter',
                'father',
                'mother',
                'husband',
                'wife',
                'brother',
                'sister',
                'friend',
            ],

            // Snackbar Utils
            snackbar: false,
            timeout: 4000,
            snackbarColor: 'success',
            snackbarMessage: 'Success!',
        }
    },
    methods: {
        resetAddFamilyUserForm() {
            this.fullName = ''
            this.relationship = ''
        },
        async setFamilyUsers() {
            const result = await getFamilyUsers(this.userId)
            if (!result.success) {
                return
            }
            this.familyUsers = result.familyUsers.map((familyUser) => ({
                ...familyUser,
                // edit utilities
                isEditMode: false,
                editFamilyUserLoading: false,
                editedFullName: familyUser.fullName,
                editedRelationship: familyUser.relationship,
                // delete utilities
                deleteDialog: false,
                deleteFamilyUserLoading: false,
                deleteFamilyUserErrorText: '',
            }))
        },
        async addFamilyUser() {
            this.addFamilyUserLoading = true
            const result = await createFamilyUser(
                this.userId,
                this.fullName,
                this.relationship
            )
            if (!result.success) {
                this.toggleFailedSnackbar(result.error)
                this.addFamilyUserLoading = false
                return
            }
            this.toggleSuccessSnackbar()
            this.resetAddFamilyUserForm()
            this.addFamilyUserLoading = false
            await this.setFamilyUsers()
        },
        async editFamilyUser(familyUser) {
            familyUser.editFamilyUserLoading = true
            const result = await editFamilyUser(
                familyUser.id,
                familyUser.editedFullName,
                familyUser.editedRelationship
            )
            if (!result.success) {
                this.toggleFailedSnackbar(result.error)
                familyUser.editFamilyUserLoading = false
                return
            }
            this.toggleSuccessSnackbar()
            this.resetEditMode(familyUser)
            familyUser.editFamilyUserLoading = false
            await this.setFamilyUsers()
        },
        async deleteFamilyUser(familyUser) {
            familyUser.deleteFamilyUserLoading = true
            const result = await deleteFamilyUser(familyUser.id)
            if (!result.success) {
                familyUser.deleteFamilyUserErrorText = result.error
                familyUser.deleteFamilyUserLoading = false
                return
            }
            familyUser.deleteFamilyUserLoading = false
            this.closeDeleteFamilyUserDialog(familyUser)
            this.toggleSuccessSnackbar()
            await this.setFamilyUsers()
        },
        setEditMode(familyUser) {
            familyUser.isEditMode = true
        },
        resetEditMode(familyUser) {
            familyUser.isEditMode = false
        },
        toggleSuccessSnackbar() {
            this.snackbarMessage = 'Success!'
            this.snackbarColor = 'success'
            this.toggleSnackbar()
        },
        toggleFailedSnackbar(error) {
            this.snackbarMessage = error
            this.snackbarColor = 'error'
            this.toggleSnackbar()
        },
        toggleSnackbar() {
            this.snackbar = true
            setTimeout(() => (this.snackbar = false), this.timeout)
        },
        computeWarningDialogText(familyUser) {
            return `You are about to delete ${familyUser.fullName}. You will not be able to book this family member into future classes.`
        },
        closeDeleteFamilyUserDialog(familyUser) {
            familyUser.deleteDialog = false
            familyUser.deleteFamilyUserErrorText = ''
        },
        openDeleteFamilyUserDialog(familyUser) {
            familyUser.deleteDialog = true
        },
        selectFamilyUser(familyUser) {
            if (!this.isSelectable) {
                return
            }
            this.$emit('selectFamilyUser', familyUser)
        },
        closeDialog() {
            this.$emit('closeDialog')
        },
    },
}
</script>
