export const mutationsAlert = {
    clearAlertState(state) {
        state.isActive = false
    },
    setAlertState(state, payload) {
        state.type = payload.type
        state.message = payload.message
        state.isActive = true
    },
}
