<template>
    <v-dialog
        v-model="showDialog"
        @click:outside="cancelCallback"
        @keydown.esc="cancelCallback"
        width="600"
    >
        <v-card flat max-width="600" min-width="200">
            <v-card-title class="primary white--text">
                {{ $t('message.selectSpotsToCancel') }}</v-card-title
            >
            <v-card-text>
                <v-row justify="center" align="center">
                    <v-card-text v-if="!isSpotsHidden">
                        <div
                            v-for="(item, index) in sortedSubItems"
                            :key="index"
                        >
                            <v-checkbox
                                :value="item.id"
                                v-model="selectedSpots"
                                :on-icon="'mdi-close-circle'"
                                :off-icon="'mdi-circle-outline'"
                            >
                                <template v-slot:label>
                                    <div>
                                        {{
                                            $t('message.spotNumber', {
                                                spotNumber: item.spotNumber,
                                            })
                                        }}

                                        {{
                                            $t('message.assignedToUsername', {
                                                username: item.username,
                                            })
                                        }}
                                    </div>
                                </template>
                            </v-checkbox>
                        </div>
                    </v-card-text>

                    <v-card-text
                        v-if="isSpotsHidden"
                        class="d-flex justify-center my-3"
                    >
                        <v-select
                            :items="
                                Array.from(
                                    { length: sortedSubItems.length },
                                    (_, i) => i + 1
                                )
                            "
                            :label="$t('message.selectNumberOfSpotsToRemove')"
                            v-model="spotsToRemove"
                            :style="{ maxWidth: '80%' }"
                        ></v-select>
                    </v-card-text>

                    <v-card-text
                        v-if="errorMessage"
                        class="error--text text-center"
                    >
                        {{ errorMessage }}
                    </v-card-text>

                    <v-btn
                        @click="cancelCallback"
                        :disabled="loading"
                        class="mx-2"
                        >{{ $t('message.cancel') }}</v-btn
                    >
                    <v-btn
                        color="primary lighten-1"
                        depressed
                        @click="confirm"
                        :loading="loading"
                        >{{ $t('message.confirm') }}</v-btn
                    >
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        showDialog: Boolean,
        title: String,
        subItems: Array,
        cancelCallback: Function,
        confirmCallback: Function,
        loading: Boolean,
        isSpotsHidden: Boolean,
    },
    data() {
        return {
            selectedSpots: [],
            sortedSubItems: [],
            spotsToRemove: 0,
            errorMessage: '',
        }
    },
    methods: {
        confirm() {
            if (this.isSpotsHidden) {
                this.selectedSpots = this.sortedSubItems
                    .slice(0, this.spotsToRemove)
                    .map((item) => item.id)
            }
            if (this.selectedSpots.length === 0) {
                this.errorMessage = this.$t('message.selectAtLeastOneSpot')
                return
            }
            this.errorMessage = ''
            this.confirmCallback(this.selectedSpots)
        },
    },
    watch: {
        subItems: {
            immediate: true,
            handler() {
                this.sortedSubItems = [...this.subItems].sort(
                    (a, b) => a.spotNumber - b.spotNumber
                )
            },
        },
    },
}
</script>
