import { functions, httpsCallable } from '@/firebase'

/**
 * Gets available normal packages.
 */
function getNormalPackages(studioId) {
    const getNormalPackagesFn = httpsCallable(
        functions,
        'getAvailableMemberships'
    )
    return getNormalPackagesFn({ studioId }).then((result) => result.data)
}

/**
 * Gets available recurring packages.
 */
function getRecurringPackages(studioId) {
    const getRecurringPackagesFn = httpsCallable(
        functions,
        'getAvailableRecurringMemberships'
    )
    return getRecurringPackagesFn({ studioId }).then((result) => result.data)
}

/**
 * Gets available user's memberships packages.
 */
function getMyMemberships(studioId) {
    const getMyPackagesFn = httpsCallable(functions, 'getMyMemberships')
    return getMyPackagesFn({ studioId })
        .then((result) => result.data)
        .catch()
}

/**
 * Cancels a recurring membership package.
 */
function cancelRecurringPackage(packageId, paymentMethodId) {
    const payload = { packageId, paymentMethodId, isCalledByMember: true }
    const cancelRecurringPackageFn = httpsCallable(
        functions,
        'cancelRecurringPackage'
    )
    return cancelRecurringPackageFn(payload)
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

function getSubscriptionCancellationStatus(packageId) {
    const payload = { packageId }
    const getPackageCancellationStatusFn = httpsCallable(
        functions,
        'getSubscriptionCancellationStatus'
    )
    return getPackageCancellationStatusFn(payload)
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

/**
 * Gets package categories of a studio
 * @param {String} studioId
 * @returns package categories, sorted by their sortingId
 */
async function getPackageCategories(studioId) {
    const getPackageCategoriesFn = httpsCallable(
        functions,
        'getPackageCategories'
    )
    const categories = await getPackageCategoriesFn({ studioId })
        .then((result) => (result.data.success ? result.data.categories : []))
        .catch(() => [])
    return categories
}

export {
    getNormalPackages,
    getRecurringPackages,
    getMyMemberships,
    cancelRecurringPackage,
    getSubscriptionCancellationStatus,
    getPackageCategories,
}
