<template>
    <div>
        <div class="d-none d-sm-flex">
            <FilterDesktop
                :studio="studio"
                :type="'course'"
                :classFilter="filters.courseNameFilter"
                :instructorFilter="filters.instructorFilter"
                :locationFilter="filters.locationFilter"
                :date="filters.startDate"
                :minDate="todayDate"
                @update:classFilter="updateClassFilter"
                @update:instructorFilter="updateInstructorFilter"
                @update:locationFilter="updateLocationFilter"
                @update:date="updateDateFilter"
            />
        </div>
        <v-row
            min-height="20vh"
            width="100vw"
            class="d-flex d-sm-none px-5 mx-1 py-5 mt-3 rounded-lg"
            :style="{
                backgroundColor: $vuetify.theme.themes.light.dilutedPrimary,
                color: $vuetify.theme.themes.light.primary,
            }"
        >
            {{ $t('message.filterCourses') }}
            <v-row justify="end" @click="toggleDropdown = !toggleDropdown">
                <v-icon v-if="toggleDropdown" right class="my-3 mx-1">
                    mdi-chevron-down
                </v-icon>
                <v-icon v-else right class="my-3 mx-1"
                    >mdi-chevron-right</v-icon
                >
            </v-row>
            <v-slide-y-transition>
                <FilterDesktop
                    v-if="toggleDropdown"
                    :studio="studio"
                    :type="'course'"
                    :classFilter="filters.courseNameFilter"
                    :instructorFilter="filters.instructorFilter"
                    :locationFilter="filters.locationFilter"
                    :date="filters.startDate"
                    :minDate="todayDate"
                    @update:classFilter="updateClassFilter"
                    @update:instructorFilter="updateInstructorFilter"
                    @update:locationFilter="updateLocationFilter"
                    @update:date="updateDateFilter"
                />
            </v-slide-y-transition>
        </v-row>

        <!-- Timetable view -->
        <Timetable
            v-model="filters.startDate"
            @input="onFilterChange(filters)"
            class="mt-3"
            v-if="isTimetableView"
            :courses="courses"
            :studio="studio"
            :isFetchingData="isFetchingData"
            :errorInFetching="errorInFetching"
        />

        <DateBoxes
            v-model="filters.startDate"
            @input="onFilterChange(filters)"
            v-if="!isTimetableView"
        />
        <v-layout row wrap class="d-none d-sm-flex" v-if="!isTimetableView">
            <v-flex>
                <v-card
                    class="pb-2 pt-2"
                    align-self-center="true"
                    flat
                    width="100vw"
                    min-height="5vh"
                >
                    <v-layout
                        class="pb-4 pt-2 pl-2 pr-4"
                        align-content-center="true"
                    >
                        <v-flex xs9>
                            <v-card-text
                                class="py-2 primary--text font-weight-bold"
                            >
                                {{ $t('message.time') }}
                            </v-card-text>
                        </v-flex>
                        <v-flex xs12>
                            <v-card-text
                                class="py-2 primary--text font-weight-bold"
                            >
                                {{ $t('message.course') }}
                            </v-card-text>
                        </v-flex>
                        <v-flex xs9>
                            <v-card-text
                                class="py-2 primary--text font-weight-bold"
                            >
                                {{ $t('message.instructor') }}
                            </v-card-text>
                        </v-flex>
                        <v-flex xs9>
                            <v-card-text
                                class="py-2 primary--text font-weight-bold"
                            >
                                {{ $t('message.location') }}
                            </v-card-text>
                        </v-flex>
                        <v-flex xs6>
                            <div class="py-2"></div>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
            <br /><br />
        </v-layout>
        <div v-if="!isTimetableView">
            <v-layout row wrap class="d-none d-sm-flex">
                <CourseView :courses="courses" :bookBtn="bookBtn" />
            </v-layout>
            <v-layout row wrap class="d-flex d-sm-none">
                <CourseView
                    :courses="courses"
                    :bookBtn="bookBtn"
                    :isMobileView="true"
                />
            </v-layout>
            <!--Add here the vuetify directive -->
            <v-skeleton-loader
                class="mt-10"
                v-if="isFetchingData && !errorInFetching"
                type="card"
            />
            <p
                class="text-center py-8"
                v-if="!isFetchingData && !errorInFetching"
            >
                {{ $t('message.noCourses') }}
            </p>
            <p class="text-center py-5" v-if="errorInFetching">
                {{ $t('message.errorFetchingCourses') }}
            </p>
        </div>
    </div>
</template>
<script>
import DateBoxes from '@/components/browseclasses/DateBoxes.vue'
import FilterDesktop from '@/components/browseclasses/FilterDesktop.vue'
import Timetable from '@/components/browseclasses/timetable/Timetable.vue'
import CourseView from '@/components/browsecourses/CourseView.vue'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import { getCourseSessionsFromStudioId } from '@/util/cloudFunctions/coursesFunctions'
import {
    dateStringFromTimestamp,
    timeStringFromTimestamp,
} from '@/util/dateformat.js'
import { mapGetters } from 'vuex'
import { getFiltersFromUrl } from '@/util/classes/classUrlParsing'

import { dateToString, dateToStringWithTimezone } from '@/util/datetimehelpers'

export default {
    components: {
        FilterDesktop,
        DateBoxes,
        CourseView,
        Timetable,
    },
    beforeMount() {
        this.windowWidth = window.innerWidth
        window.addEventListener('resize', this.onResize)
    },
    async created() {
        this.setTodayDate() // this must be the first function ran
        this.setDefaultFiltersFromURLParams()
        await this.initData()
    },
    data() {
        return {
            courses: {},
            coursesRaw: [],
            todayDate: '',
            filters: {
                studioId: '',
                courseNameFilter: [],
                instructorFilter: [],
                locationFilter: [],
                startDate: '',
                endDate: '',
            },
            toggleDropdown: false,
            isFetchingData: false,
            errorInFetching: false,
            bookBtn: true,
            windowWidth: null,
        }
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        isTimetableView() {
            return this.studio.isWebAppTimetableView && this.windowWidth >= 600
        },
    },
    methods: {
        setTodayDate() {
            this.todayDate = dateToStringWithTimezone(
                new Date(),
                this.studio.timezone
            )
            this.filters.startDate = this.todayDate
        },
        setDefaultFiltersFromURLParams() {
            const { courseFilter, locationFilter, instructorFilter } =
                getFiltersFromUrl(window.location.search)
            this.filters.courseNameFilter = courseFilter
            this.filters.locationFilter = locationFilter
            this.filters.instructorFilter = instructorFilter
        },
        async updateClassFilter(value) {
            this.filters.courseNameFilter = value
            this.onFilterChange()
        },
        async updateInstructorFilter(value) {
            this.filters.instructorFilter = value
            this.onFilterChange()
        },
        async updateLocationFilter(value) {
            this.filters.locationFilter = value
            this.onFilterChange()
        },
        async updateDateFilter(value) {
            this.filters.startDate = value
            this.onFilterChange()
        },
        async fetchCoursesNormalView() {
            if (this.isFetchingData) {
                return []
            }
            this.isFetchingData = true
            // eslint-disable-next-line no-unused-vars
            const { endDate: _endDate, ...filters } = this.filters
            const result = await getCourseSessionsFromStudioId({
                ...filters,
                studioId: this.studio.id,
            })
            if (!result.success) {
                this.errorInFetching = true
                return []
            }

            const docs = result.docs
            return docs
        },
        async fetchCoursesTimeTableView() {
            this.isFetchingData = true
            const endDate = new Date(this.filters.startDate)
            endDate.setDate(endDate.getDate() + 7)
            const result = await getCourseSessionsFromStudioId({
                ...this.filters,
                studioId: this.studio.id,
                endDate: dateToString(endDate),
            })
            // Error in querying
            if (!result.success) {
                this.errorInFetching = true
                return []
            }

            const docs = result.docs
            return docs
        },
        async initData() {
            await this.onFilterChange()
        },
        async onFilterChange() {
            let docs
            if (this.isTimetableView) {
                docs = await this.fetchCoursesTimeTableView()
            } else {
                docs = await this.fetchCoursesNormalView()
            }
            this.coursesRaw = docs
            this.courses = this.formatArrayToObject()
            this.isFetchingData = false
        },
        formatArrayToObject() {
            // helper function to get the month name from an item
            let formattedObject = {}
            for (let item of this.coursesRaw) {
                // let date = formatTimestamp(item.startTimestamp, 'll');
                let date = dateStringFromTimestamp(
                    item.startTimestamp,
                    this.studio.timezone
                )
                if (formattedObject[date] === undefined) {
                    formattedObject[date] = []
                }

                item.timeStart = timeStringFromTimestamp(
                    item.startTimestamp,
                    this.studio.timezone
                )
                item.timeEnd = timeStringFromTimestamp(
                    item.endTimestamp,
                    this.studio.timezone
                )
                formattedObject[date].push(item)
            }
            return formattedObject
        },
        onResize() {
            this.windowWidth = window.innerWidth
        },
    },
    beforeDestroy() {
        if (typeof window === 'undefined') return
        window.removeEventListener('resize', this.onResize)
    },
}
</script>
