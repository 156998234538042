<template>
    <v-col>
        <v-row class="justify-center mb-5">
            <h3 class="ml-2">{{ $t('message.fillMobileText') }}</h3>

            <v-col cols="12">
                <p class="text-center">
                    {{ $t('message.collectPhoneHint', { studioName }) }}
                </p>
            </v-col>
            <v-col cols="12" md="6">
                <vue-phone-number-input
                    v-model="input"
                    :default-country-code="country"
                    :preferred-countries="['SG', 'MY', 'VN', 'ID', 'US']"
                    :error="!this.phoneNumberObj.isValid"
                    :required="true"
                    clearable
                    @update="onPhoneNumberChange"
                    :dark="$vuetify.theme.dark"
                />

                <p class="error--text text-center my-2">{{ error }}</p>
            </v-col>

            <v-col cols="12" align="center">
                <v-btn
                    class="primary depressed"
                    @click="submit()"
                    :loading="loading"
                    :disabled="isSubmitDisabled"
                    >Submit</v-btn
                >
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import { updateMobileNumber } from '@/util/cloudFunctions/userFunctions'
export default {
    props: {
        studioName: String,
        country: String,
    },
    data() {
        return {
            input: '',
            phoneNumberObj: {},
            error: '',
            loading: false,
        }
    },
    mounted() {
        this.updateHTMLTabIndex()
    },
    methods: {
        submit() {
            if (this.loading) {
                return
            }
            if (!this.input) {
                this.error = 'Please key in mobile number'
                return
            }
            if (!this.phoneNumberObj.isValid) {
                this.error = 'Invalid mobile number'
                return
            }
            this.error = ''
            const phoneNumber = this.phoneNumberObj.formattedNumber
            this.loading = true
            return updateMobileNumber(phoneNumber).then((res) => {
                this.loding = false
                if (res.success) {
                    this.$emit('success')
                } else {
                    this.error = res.error
                }
            })
        },
        onPhoneNumberChange(results) {
            this.phoneNumberObj = results
        },
        updateHTMLTabIndex() {
            // This is necessary for the VuePhoneNumberInput scrollbar to work in a dialog
            const countrySelectorList = document.getElementsByClassName(
                'country-selector__list'
            )
            for (const countrySelector of countrySelectorList) {
                countrySelector.setAttribute('tabindex', '1')
            }
        },
    },
    computed: {
        isSubmitDisabled() {
            return (
                !this.input ||
                !this.phoneNumberObj ||
                !this.phoneNumberObj.isValid
            )
        },
    },
}
</script>
