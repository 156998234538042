var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{staticClass:"ordinary--text"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('p',{staticClass:"package-item-title"},[_vm._v(" "+_vm._s(_vm.packageItem.name)+" ")])]),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.packageItem.description)}}),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":_vm.isBuyable ? 12 : 6}},[_c('v-col',[_c('v-col',[_vm._v(" "+_vm._s(_vm.packageDescription)+" ")]),(!_vm.isBuyable)?_c('v-col',[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.expiresOn)),_c('br'),_vm._v(" "+_vm._s(_vm.purchasedOn)+" ")])]):_vm._e(),_vm._l((_vm.packageDetails),function(packageDetail){return _c('PackageInfoFormDetail',{key:packageDetail.title,attrs:{"title":packageDetail.title,"content":packageDetail.content}})}),(_vm.packageItem.isPackageShareable)?_c('v-col',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$t('message.shareLimit', { numShareable: _vm.packageItem.numPackageShareable, }))+" ")]):_vm._e(),(
                            this.packageItem.isPackageShareable &&
                            !this.isBuyable
                        )?_c('v-divider',{staticClass:"hidden-sm-and-up mt-5"}):_vm._e()],2)],1),(
                    this.packageItem.isPackageShareable && !this.isBuyable
                )?_c('v-divider',{staticClass:"hidden-xs-only my-7",attrs:{"vertical":""}}):_vm._e(),(
                    this.packageItem.isPackageShareable && !this.isBuyable
                )?_c('v-col',[_c('v-col',[_c('v-row',{staticClass:"mt-1 ml-1"},[_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v("mdi-account-multiple-outline")]),_c('p',{staticClass:"primary--text mt-3 ml-2"},[_vm._v(" "+_vm._s(_vm.$t('message.sharedWith'))+" ")])],1),(_vm.users.length != 0)?_c('v-col',{staticClass:"pa-0"},_vm._l((_vm.users),function(user,i){return _c('v-row',{key:i,staticClass:"mt-2"},[_c('PackageSharedCard',{attrs:{"username":_vm.users[i].username,"name":_vm.users[i].fullName,"numberClassesUsed":_vm.users[i].classesLeft,"numberClassesShared":_vm.users[i].numberClasses}})],1)}),1):(_vm.isLoading)?_c('v-col',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-progress-circular',{staticClass:"mt-2",attrs:{"color":"primary","indeterminate":""}})],1)],1):_c('v-col',[_c('p',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t('message.shareHint'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.disabled},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"d-inline-block",style:({ width: '100%' })},on),[_c('v-btn',{staticClass:"mt-6",attrs:{"color":"primary","width":"100%","disabled":_vm.disabled},on:{"click":_vm.openDialog}},[_vm._v(_vm._s(_vm.$t('message.sharePackage')))])],1)]}}],null,false,1780157360)},[_c('span',[_vm._v(_vm._s(_vm.reason()))])]),_c('v-dialog',{attrs:{"width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-col',{staticClass:"pb-0"},[_c('v-row',{staticClass:"justify-end"},[_c('v-icon',{on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('PackageShareWithCard',{key:_vm.key,attrs:{"packageItem":_vm.packageItem},on:{"sharePackage":_vm.sharePackage}})],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }