<template>
    <div>
        <v-scroll-y-transition>
            <v-row v-if="loading">
                <v-col cols="12" v-for="i in 8" :key="i" sm="6" lg="4" xl="3">
                    <v-skeleton-loader type="card" />
                </v-col>
            </v-row>

            <v-row v-if="!loading">
                <v-col
                    class="text--secondary"
                    v-if="filteredMerchandise.length === 0"
                >
                    {{ $t('message.productsUnavailable') }}
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    lg="4"
                    xl="3"
                    v-for="product in filteredMerchandise"
                    :key="product.id"
                >
                    <MerchandiseCard
                        :product="product"
                        @addToCart="$emit('addToCart')"
                    />
                </v-col>
            </v-row>
        </v-scroll-y-transition>
    </div>
</template>

<script>
import MerchandiseCard from '@/components/merchandise/MerchandiseCard'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import { mapGetters } from 'vuex'

export default {
    components: {
        MerchandiseCard,
    },

    props: {
        loading: Boolean,
        merchandise: Array,
        searchTerm: String,
        priceFilter: String,
        categoryFilter: String,
    },

    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),

        filteredMerchandise() {
            const categoryFilter = JSON.parse(this.categoryFilter || null)
            const priceFilter = JSON.parse(this.priceFilter || null)
            let result = this.merchandise.filter(({ name, description }) =>
                `${name}${description}`
                    .toLowerCase()
                    .includes(this.searchTerm.toLowerCase())
            )

            if (categoryFilter && categoryFilter.length) {
                result = result.filter(({ categoryId }) =>
                    categoryFilter.includes(categoryId)
                )
            }

            if (priceFilter && priceFilter.length) {
                const [min, max] = priceFilter

                result = result.filter(({ price }) => {
                    let condition = true

                    if (min) condition = condition && price >= min
                    if (max) condition = condition && price <= max

                    return condition
                })
            }

            return result
        },
    },
}
</script>
