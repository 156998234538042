const EVENT_KEY = 'EVENT_KEY'

class EventManager {
    /**
     * Initialize the events in the localStorage
     * @param {Object} events Object where each key is an event, and the value is the parameters
     */
    static initEvents(events) {
        localStorage.setItem(EVENT_KEY, JSON.stringify(events))
    }

    static retrieveEvents() {
        const events = localStorage.getItem(EVENT_KEY)

        if (!events) {
            EventManager.clearEvents()
            return
        }

        try {
            const parsedEvents = JSON.parse(events)
            EventManager.clearEvents()
            return parsedEvents
        } catch {
            EventManager.clearEvents()
            return
        }
    }

    static clearEvents() {
        localStorage.setItem(EVENT_KEY, null)
    }
}

export { EventManager }
