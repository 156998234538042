export const gettersStudio = {
    isLoading(state) {
        return state.loading
    },
    isInvalid(state) {
        return !state.studio && !state.loading
    },
    brandedAppsInReview(state) {
        return state.brandedAppsInReview
    },
    getStudio(state) {
        return state.studio
    },
}
