import getStudioFromWebAppRoute from '@/util/cloudFunctions/getStudioFromWebAppRoute'

export const actionsStudio = {
    async setStudioAction({ commit, state }, webAppRoute) {
        const currentStudio = state.studio
        commit('setLoading', true)
        if (
            currentStudio &&
            currentStudio.webAppRoute &&
            currentStudio.webAppRoute === webAppRoute
        ) {
            commit('setLoading', false)
            return
        }
        const result = await getStudioFromWebAppRoute(webAppRoute)
        if (result.success) {
            const studioDoc = result.doc
            commit('setStudio', studioDoc)
        } else {
            commit('setStudio', null)
        }

        commit('setLoading', false)
    },
}
