<template>
    <div>
        <ClassesTimetable
            v-if="isTimetableView"
            :studio="studio"
            :classFilter="classFilter"
            :instructorFilter="instructorFilter"
            :locationFilter="locationFilter"
            :date="date"
            @update:date="onDateChange"
        />
        <ClassesList
            v-else
            :studio="studio"
            :classFilter="classFilter"
            :instructorFilter="instructorFilter"
            :locationFilter="locationFilter"
            :date="date"
            :todayDate="todayDate"
            @update:date="onDateChange"
        />
    </div>
</template>
<script>
import ClassesTimetable from '@/components/browseclasses/ClassesTimetable.vue'
import ClassesList from '@/components/browseclasses/ClassesList.vue'

export default {
    props: {
        studio: Object,
        classFilter: Array,
        instructorFilter: Array,
        locationFilter: Array,
        date: String,
        todayDate: String,
    },
    components: {
        ClassesTimetable,
        ClassesList,
    },
    beforeMount() {
        this.windowWidth = window.innerWidth
        window.addEventListener('resize', this.onResize)
    },
    data() {
        return {
            windowWidth: null,
        }
    },
    computed: {
        isTimetableView() {
            return this.studio.isWebAppTimetableView && this.windowWidth >= 600
        },
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth
        },
        onDateChange(date) {
            this.$emit('update:date', date)
        },
    },
    beforeDestroy() {
        if (typeof window === 'undefined') return
        window.removeEventListener('resize', this.onResize)
    },
}
</script>
