<template>
    <v-container fill-height fluid>
        <v-row justify="center" align="center">
            <v-col cols="12" md="6">
                <v-row justify="center" align="center">
                    <v-card class="pa-4">
                        <SignUpForm :key="signUpFormKey" @success="onSuccess" />
                    </v-card>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import SignUpForm from '@/components/auth/SignUpForm'
import { mapActions, mapGetters } from 'vuex'
import {
    AUTHENTICATION_MODULE_NAME,
    ALERT_MODULE_NAME,
} from '@/store/moduleNames'
export default {
    props: ['block'],
    components: {
        SignUpForm,
    },
    data() {
        return {
            signUpFormKey: false,
        }
    },
    computed: {
        ...mapGetters({
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
        }),
    },
    methods: {
        ...mapActions({
            setAlertStateAction: `${ALERT_MODULE_NAME}/setAlertStateAction`,
        }),
        onSuccess() {
            const payload = {
                type: 'success',
                message: this.$t('message.signupSuccess'),
            }
            this.setAlertStateAction(payload)
            this.routeToClasses()
        },
        routeToClasses() {
            const hash = this.$route.hash
            const studioRoute = this.$route.params.studioRoute
            const classesPageRoute = `/${studioRoute}/classes${hash}`
            this.$router.push(classesPageRoute)
        },
    },
    watch: {
        isUserAuth: {
            immediate: true,
            handler() {
                if (this.isUserAuth) {
                    this.routeToClasses()
                }
            },
        },
    },
}
</script>
