import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import vuetify from './plugins/vuetify'
import router from './router'
import i18n from './i18n'
import '@/assets/global.scss'
import { store } from './store'
import VueGtag from 'vue-gtag'
import Hotjar from 'vue-hotjar'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import './validationRules'

Vue.component('vue-phone-number-input', VuePhoneNumberInput)
Vue.config.productionTip = false
Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID },
})
Vue.use(Vuex)
Vue.use(router)
Vue.use(Hotjar, {
    id: process.env.VUE_APP_HOTJAR_SITE_ID,
    isProduction: process.env.VUE_APP_SERVER === 'production',
    snippetVersion: 6,
})

new Vue({
    vuetify,
    Vuex,
    store,
    router,
    i18n,
    render: (h) => h(App),
}).$mount('#app')
