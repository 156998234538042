<template>
    <v-card @click="navigateToStudio" height="100%">
        <v-img :src="studio.studioImage" :aspect-ratio="16 / 9"></v-img>

        <v-card-title>
            <div class="headerClass">
                {{ studio.studioName }}
            </div>
        </v-card-title>

        <v-card-subtitle>
            <div class="subtitleClass">
                {{ studio.locations[0].name }}
            </div>
        </v-card-subtitle>
    </v-card>
</template>

<script>
// Analytics
import { CLICK_STUDIO_CARD } from '@/analytics/events'

export default {
    props: {
        studio: Object,
    },
    methods: {
        navigateToStudio() {
            const studioRoute = this.studio.webAppRoute
            this.$gtag.event(CLICK_STUDIO_CARD, {
                studioName: this.studio.studioName,
            })
            this.$router.push(studioRoute)
        },
    },
    data() {
        return {
            webAppRoute: this.studio.webAppRoute,
            studioImage: this.studio.studioImage,
            studioName: this.studio.studioName,
            location: this.studio.locations[0].name,
        }
    },
}
</script>

<style scoped>
.subtitleClass {
    white-space: nowrap;
    word-break: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}
.headerClass {
    white-space: nowrap;
    word-break: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
