<template>
    <v-list>
        <PaymentMethodItem
            v-for="method in paymentMethods"
            :key="method.id"
            :paymentMethod="method"
            :paymentMethodIdForPackage="paymentMethodIdForPackage"
            :statusObj="statusObj"
            :isCharging="isCharging"
            :buttonProps="buttonProps"
            @updatePaymentMethod="
                (mtdId) => $emit('updatePaymentMethod', mtdId)
            "
            @deletePaymentMethod="
                (mtdId) => $emit('deletePaymentMethod', mtdId)
            "
            @onButtonClicked="onButtonClicked"
        />

        <p v-if="noPaymentMethodsAdded" class="text-center mt-10">
            {{ $t('message.noPaymentMethodsAdded') }}
        </p>
    </v-list>
</template>

<script>
import PaymentMethodItem from './PaymentMethodItem.vue'

export default {
    components: {
        PaymentMethodItem,
    },
    emits: ['updatePaymentMethod', 'deletePaymentMethod'],
    props: {
        paymentMethods: {
            type: Array,
            required: true,
        },
        loadingPaymentMethods: {
            type: Boolean,
            required: true,
        },
        paymentMethodIdForPackage: {
            type: String,
            required: false,
        },
        isCharging: {
            type: Boolean,
            required: false,
        },
        statusObj: {
            type: Object,
            required: false,
        },
        buttonProps: {
            type: Object,
        },
    },
    computed: {
        noPaymentMethodsAdded() {
            return (
                !this.loadingPaymentMethods && this.paymentMethods.length === 0
            )
        },
    },
    methods: {
        onButtonClicked(methodId) {
            this.$emit('onButtonClicked', methodId)
        },
    },
}
</script>
