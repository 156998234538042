<template>
    <v-row class="ordinary--text">
        <v-col no-gutters>
            <v-row>
                <p class="ml-4 py-2 font-weight-black text-h5">
                    {{ $t('message.sharePackage') }}
                </p>
            </v-row>

            <v-divider class="my-3" />

            <v-row class="mx-5 my-2 py-4">
                <PackageUserSearch @userSearched="getUsers" />
            </v-row>

            <v-row class="mx-4 mt-0" v-for="user in this.users" :key="user.id">
                <v-col>
                    <v-row>
                        <v-col cols="3" sm="1" class="ml-13 ml-sm-0">
                            <v-icon
                                large
                                class="mt-3 ml-3"
                                color="grey lighten-1"
                                >mdi-account-circle-outline</v-icon
                            >
                        </v-col>

                        <v-col class="ml-0 ml-sm-6" cols="4" sm="6">
                            <v-row class="mt-2">
                                <p class="mb-0">{{ user.username }}</p>
                            </v-row>
                            <v-row>
                                <p class="grey--text" inline>
                                    {{ user.fullName }}
                                </p>
                            </v-row>
                        </v-col>
                        <v-col class="mt-1 ml-9 ml-sm-0" cols="12" sm="3">
                            <vue-numeric-input
                                class="numeric-input-btn-style"
                                v-model="user.value"
                                :min="1"
                                :max="packageItem.classesLeft"
                                align="center"
                            ></vue-numeric-input>

                            <v-btn
                                :disabled="
                                    disabled ||
                                    (loading && user.id !== userClicked)
                                "
                                :loading="loading && user.id === userClicked"
                                @click="
                                    sharePackage(
                                        user.value,
                                        user.id,
                                        user.username
                                    )
                                "
                                text
                                style="text-decoration: underline"
                                class="ml-3 primary--text"
                            >
                                Share {{ user.value }} credits
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-divider class="mt-1" />
                </v-col>
            </v-row>

            <!-- Snackbar -->
            <v-snackbar
                :timeout="3000"
                v-model="this.success"
                top
                color="success"
            >
                Successfully shared {{ classes }} credits with
                {{ userSharedTo }}
            </v-snackbar>

            <v-snackbar
                :timeout="3000"
                v-model="this.failure"
                top
                color="error"
            >
                {{ error }}
            </v-snackbar>

            <v-row class="pt-5 mt-8">
                <v-col cols="12" sm="4">
                    <p class="package-item-title text-center">
                        {{ this.packageItem.name }}
                    </p>
                </v-col>
                <v-col class="mt-sm-2 pt-0 pt-sm-3" cols="6" sm="4">
                    <v-row class="ml-md-4 ml-sm-2 ml-1">
                        <v-icon color="primary">
                            mdi-account-multiple-outline
                        </v-icon>
                        <p class="mt-4 mt-sm-3 ml-3">
                            {{
                                $t('message.peopleLeft', {
                                    numShareable:
                                        packageItem.originalNumPackageShareable,
                                    current: packageItem.numPackageShareable,
                                })
                            }}
                        </p>
                    </v-row>
                </v-col>

                <v-divider vertical class="mt-0 mt-sm-1 mb-3 mb-sm-4" />

                <v-col class="mt-sm-2 pt-0 pt-sm-3" cols="6" sm="4">
                    <v-row class="ml-md-4 ml-sm-2 ml-1">
                        <v-icon color="primary"> mdi-bookmark-multiple </v-icon>
                        <p class="mt-4 mt-sm-3 ml-2">
                            {{
                                $t('message.classesRemainingShort', {
                                    classesLeft: this.packageItem.classesLeft,
                                    numberClasses:
                                        this.packageItem.numberClasses,
                                })
                            }}
                        </p>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import { sharePackages } from '../../util/cloudFunctions/sharePackages'
import PackageUserSearch from '@/components/shared/UserSearch'
import VueNumericInput from 'vue-numeric-input'

export default {
    components: {
        VueNumericInput,
        PackageUserSearch,
    },
    props: {
        packageItem: Object,
    },
    data() {
        return {
            search: '',
            users: [],
            success: false,
            failure: false,
            userClicked: '',
            loading: false,
            disabled:
                this.packageItem.classesLeft === 0 ||
                this.packageItem.numPackageShareable === 0,
            classes: 0,
            userSharedTo: '',
            error: '',
        }
    },
    methods: {
        getUsers: function (arr) {
            this.users = arr
                .map((user) => {
                    const value = 1
                    return { ...user, value }
                })
                .filter(
                    (element) => element.username !== this.packageItem.username
                )
        },
        sharePackage: function (classesShared, userId, username) {
            this.userClicked = userId
            this.loading = true
            sharePackages(
                this.packageItem.id,
                classesShared,
                userId,
                this.packageItem.userId
            ).then((data) => {
                this.loading = false
                if (data.isSuccessful) {
                    this.success = true
                    this.classes = classesShared
                    this.userSharedTo = username
                    this.$emit('sharePackage')
                    this.$emit('shareAfterPurchase')
                } else {
                    this.failure = true
                    this.error = data.errorMessage
                }
            })
        },
    },
}
</script>
<style scoped>
.numeric-input-btn-style.vue-numeric-input .btn-increment {
    background: #eee1ff;
}
.numeric-input-btn-style.vue-numeric-input .btn-decrement {
    background: #eee1ff;
}
</style>
