<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="800"
        @click:outside="closeDialog"
        @keydown.esc="closeDialog"
    >
        <ChargePackageCancellationPaymentMethod
            :subscriptionCancellationFee="subscriptionCancellationFee"
            @chargeLoading="handleChargeLoading"
            @chargeStatus="chargeStatus"
        />
    </v-dialog>
</template>

<script>
import ChargePackageCancellationPaymentMethod from './ChargePackageCancellationPaymentMethod.vue'

export default {
    components: {
        ChargePackageCancellationPaymentMethod,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
        },
        subscriptionCancellationFee: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            chargeLoading: false,
        }
    },
    methods: {
        closeDialog() {
            if (!this.chargeLoading) {
                this.$emit('closeDialog')
            }
        },
        handleChargeLoading(isLoading) {
            this.chargeLoading = isLoading
        },
        chargeStatus(isSuccess, error) {
            this.chargeLoading = false
            if (isSuccess) {
                this.closeDialog()
            }
            this.$emit('chargeStatus', isSuccess, error)
        },
    },
}
</script>
