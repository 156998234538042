<template>
    <div class="py-3">
        <!-- Last/Next week -->
        <div class="week-row">
            <div class="date-nav" @click="changeMonday('decrease')">
                <v-icon left>mdi-arrow-left</v-icon>
                {{ $t('message.lastWeek') }}
            </div>
            <div class="date-nav" @click="changeMonday('increase')">
                {{ $t('message.nextWeek') }}
                <v-icon right>mdi-arrow-right</v-icon>
            </div>
        </div>

        <div class="date-box-container" v-if="!isFetchingData">
            <div class="date-box-column" v-for="(d, i) of listDates" :key="i">
                <!-- Date header -->
                <div class="box">
                    <div>{{ d.dayName }}</div>
                    <div>{{ d.day }}</div>
                    <div class="font-weight-light">{{ d.month }}</div>
                </div>

                <!-- Classes -->
                <div>
                    <div
                        class="box class-box"
                        v-for="cls in d.classes"
                        :key="cls.id"
                        @click="navigateToClass(cls)"
                    >
                        <div class="header-text">
                            {{ courses ? cls.courseName : cls.name }}
                        </div>
                        <div class="info-text info-text-size">
                            <v-icon small left
                                >mdi-clock-time-eight-outline</v-icon
                            >
                            <div>
                                {{
                                    timeStringFromTimestamp(
                                        cls.startTimestamp,
                                        studio.timezone
                                    )
                                }}
                                -
                                {{
                                    timeStringFromTimestamp(
                                        cls.endTimestamp,
                                        studio.timezone
                                    )
                                }}
                            </div>
                        </div>
                        <div class="info-text info-text-size">
                            <v-icon small left>mdi-account-circle</v-icon>
                            <div>
                                {{ cls.instructor.name }}
                            </div>
                        </div>
                        <div class="info-text" v-if="numCredits(cls) != 1">
                            <v-chip
                                class="mt-2 info-text-size"
                                small
                                color="primary"
                                outlined
                            >
                                <v-icon small left>
                                    mdi-circle-multiple-outline
                                </v-icon>
                                {{ numCredits(cls) }} Credits
                            </v-chip>
                        </div>
                        <v-btn
                            v-bind="buttonSize"
                            class="my-1 text-start secondary"
                            v-if="isFull(cls)"
                        >
                            {{ $t('message.addToWaitlist') }}
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <v-skeleton-loader class="pa-3" type="table-row-divider@10" />
        </div>

        <div class="text-center pt-5 pb-3" v-if="isEmpty">
            {{ $t('message.holdTight') }}
        </div>

        <div v-if="errorInFetching" class="text-center pt-5 pb-3">
            {{ $t('message.genericError') }}
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { timeStringFromTimestamp } from '@/util/dateformat.js'
import { dateToString } from '@/util/datetimehelpers'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
export default {
    props: {
        classes: Array,
        courses: Object,
        value: String,
        isFetchingData: Boolean,
        errorInFetching: Boolean,
    },

    data: () => ({
        mondayTheWeek: null,
    }),

    created() {
        this.mondayTheWeek = this.getMondayOfWeek(this.getTodayDate())
    },

    methods: {
        timeStringFromTimestamp,
        getTodayDate() {
            const date = new Date()
            date.setTime(date.getTime() - date.getTimezoneOffset() * 60000)
            return date
        },

        changeMonday(value) {
            const date = new Date(this.mondayTheWeek)
            if (value === 'increase') {
                date.setDate(date.getDate() + 7)
            } else if (value === 'decrease') {
                date.setDate(date.getDate() - 7)
            }
            this.mondayTheWeek = date
            this.chooseDate(date)
        },

        chooseDate(date) {
            this.$emit('input', dateToString(date))
        },

        getMondayOfWeek(weekday) {
            weekday.setTime(
                weekday.getTime() + weekday.getTimezoneOffset() * 60000
            )
            const monday = new Date(weekday)
            monday.setDate(weekday.getDate())
            return monday
        },

        navigateToClass(cls) {
            const hash = this.$route.hash
            let route
            if (this.courses) {
                route = `courses/${cls.courseOfferingId}${hash}`
            } else {
                route = `classes/${cls.id}${hash}`
            }
            this.$router.push(route)
        },

        isFull(item) {
            return item.isFull || item.participantCount === item.capacity
        },

        numCredits(cls) {
            return cls.numCredits ?? 1
        },
    },

    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        coursesFormatted() {
            if (this.courses) {
                const formatted = {}
                for (const [key, value] of Object.entries(this.courses)) {
                    const newKey = dateToString(new Date(key))
                    formatted[newKey] = value
                }
                return formatted
            }
            return []
        },
        isEmpty() {
            return (
                !this.isFetchingData &&
                (!this.classes || this.classes.length == 0) &&
                this.coursesFormatted.length == 0 &&
                !this.errorInFetching
            )
        },
        listDates() {
            const listDates = []
            for (let i = 0; i <= 6; i++) {
                const date = new Date(this.mondayTheWeek)
                date.setDate(date.getDate() + i)
                const dayName = date.toLocaleDateString('en', {
                    weekday: 'short',
                })
                const day = date.getDate()
                const month = date.toLocaleDateString('en', { month: 'short' })
                const value = dateToString(date)
                if (this.classes) {
                    const classes = this.classes.find(
                        (obj) => obj.value == value
                    )
                    listDates.push({
                        dayName,
                        day,
                        month,
                        value,
                        classes: classes ? classes.item : [],
                    })
                } else if (this.courses) {
                    const courses = this.coursesFormatted[value]
                    listDates.push({
                        dayName,
                        day,
                        month,
                        value,
                        classes: courses ? courses : [],
                    })
                }
            }
            return listDates
        },
        buttonSize() {
            const size = {
                xs: 'x-small',
                sm: 'x-small',
                md: 'x-small',
                lg: 'small',
                xl: 'small',
            }[this.$vuetify.breakpoint.name]
            return size ? { [size]: true } : {}
        },
    },

    watch: {
        value(v) {
            this.mondayTheWeek = this.getMondayOfWeek(new Date(v))
        },
    },
}
</script>

<style lang="scss" scoped>
.week-row {
    width: 100%;
    display: flex;
    flex: row;
    justify-content: space-between;
}

.date-box-wrapper {
    background-color: var(--v-background-base);
    padding: 0;
}

.date-box-container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    border: 0.1rem solid #ebedf2;

    @media (min-width: 600px) {
        justify-content: center;
        overflow: auto;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.box {
    width: 100%;
    background-color: var(--v-card-base);
    padding: 10px;
    text-align: center;
    border: 0.1rem solid #ebedf2;

    &.active {
        background-color: var(--v-ordinary-base);
        color: var(--v-ordinaryInverted-base);
        box-shadow: inset 0 0 10px var(--v-ordinaryInverted-base);
    }

    @media (max-width: 600px) {
        width: 100%;
        padding: 0;
        margin: 0px 4px;
    }
}

.class-box {
    text-align: start;
    overflow-wrap: break-word;
    cursor: pointer;

    &:hover {
        background-color: #d3d3d3;
    }
}

.date-nav {
    cursor: pointer;
}

.header-text {
    font-size: 16px;

    @media (max-width: 1263px) {
        font-size: 13px;
    }
}

.info-text {
    display: flex;
    flex-direction: row;
}

.info-text-size {
    font-size: 13px;

    @media (max-width: 1263px) {
        font-size: 11px;
    }
}

.date-box-column {
    width: 14.285%;
    display: block;
}
</style>
