<template>
    <div class="my-5">
        <h3 class="my-5">{{ $t('message.sessions') }}</h3>

        <v-row class="my-5">
            <v-col cols="6" lg="4" v-for="timing in timings" :key="timing.date">
                <h4>{{ timing.date }}</h4>
                <p>{{ timing.start }} - {{ timing.end }}</p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {
    dateStringFromTimestamp,
    timeStringFromTimestamp,
} from '@/util/dateformat.js'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import { mapGetters } from 'vuex'
export default {
    props: {
        courseObject: Object,
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        currency() {
            return this.studio.currency ?? 'SGD'
        },

        timings() {
            const timings = this.courseObject.timings
            const mapped = timings.map((timing) => {
                const { startTimestamp, endTimestamp } = timing
                const date = dateStringFromTimestamp(
                    startTimestamp.toDate(),
                    this.studio.timezone,
                    'D MMM YYYY'
                )
                const start = timeStringFromTimestamp(
                    startTimestamp.toDate(),
                    this.studio.timezone
                )
                const end = timeStringFromTimestamp(
                    endTimestamp.toDate(),
                    this.studio.timezone
                )
                return { date, start, end }
            })

            return mapped
        },
    },
}
</script>
