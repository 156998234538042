<template>
    <div>
        <FilterRow
            :studio="studio"
            :classFilter.sync="classFilter"
            :instructorFilter.sync="instructorFilter"
            :locationFilter.sync="locationFilter"
            :date.sync="date"
            :todayDate="todayDate"
            class="mb-3"
        />
        <ClassesViewWrapper
            :studio="studio"
            :classFilter="classFilter"
            :instructorFilter="instructorFilter"
            :locationFilter="locationFilter"
            :date.sync="date"
            :todayDate="todayDate"
        />
    </div>
</template>
<script>
import FilterRow from '@/components/browseclasses/FilterRow.vue'
import ClassesViewWrapper from '@/components/browseclasses/ClassesViewWrapper.vue'

import { getFiltersFromUrl } from '@/util/classes/classUrlParsing'
import { dateToStringWithTimezone } from '@/util/datetimehelpers'
import { mapGetters } from 'vuex'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'

export default {
    components: {
        FilterRow,
        ClassesViewWrapper,
    },
    async created() {
        this.setTodayDate() // this must be the first function ran
        this.setDefaultFiltersFromURLParams()
    },
    data() {
        return {
            todayDate: '',
            date: '',
            classFilter: [],
            instructorFilter: [],
            locationFilter: [],
        }
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
    },
    methods: {
        setTodayDate() {
            this.todayDate = dateToStringWithTimezone(
                new Date(),
                this.studio.timezone
            )
            this.date = this.todayDate
        },
        setDefaultFiltersFromURLParams() {
            const { classFilter, locationFilter, instructorFilter } =
                getFiltersFromUrl(window.location.search)
            this.classFilter = classFilter
            this.locationFilter = locationFilter
            this.instructorFilter = instructorFilter
        },
    },
}
</script>
