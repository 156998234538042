<template>
    <GuestsTable :spots="spots" :isAssignOnly="false" />
</template>

<script>
import GuestsTable from '@/components/guests/GuestsTable'

import { mapGetters } from 'vuex'

import { AUTHENTICATION_MODULE_NAME } from '@/store/moduleNames'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'

export default {
    components: {
        GuestsTable,
    },
    data() {
        return {
            spots: [],
        }
    },
    watch: {
        $route: {
            handler(to, from) {
                if (to !== from) {
                    this.setRequiredDataWithLoading()
                }
            },
            immediate: true,
        },
        isUserAuth: {
            handler() {
                this.setRequiredData()
            },
        },
    },
    computed: {
        hideSpots() {
            return this.classItem.hideSpots === true
        },
        ...mapGetters({
            user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        enableGuestBooking() {
            return this.classItem.enableGuestBooking === true
        },
    },
    methods: {
        async setRequiredDataWithLoading() {
            this.loading = true
            await this.setRequiredData()
            this.loading = false
        },
        async setRequiredData() {
            await this.setClasses()
        },
        async getUserClassSignups() {
            // TODO Ezekiel: This should be a firebase function
            const spots = [
                {
                    spotNumber: 2,
                    fullName: 'Daughter of Yoga Fab',
                    email: 'Daughterofyogafab@gmail.com',
                    mobileNumber: '+6591234567',
                    isGuestBooking: true,
                },
                {
                    spotNumber: 3,
                    fullName: 'Son of Yoga Fab',
                    email: 'sonofyogafab@gmail.com',
                    mobileNumber: '+6591234567',
                    isGuestBooking: true,
                },
                {
                    spotNumber: 5,
                    isGuestBooking: false,
                },
            ]

            return {
                success: true,
                enableGuestBooking: true,
                spots,
            }
        },
        async setClasses() {
            const classId = this.$route.params.classId
            const result = await this.getUserClassSignups({
                classId,
                userId: this.user.id,
            })
            if (result.success) {
                if (!result.enableGuestBooking) {
                    return
                }
                this.spots = result.spots
            } else {
                this.error = result.error
                this.classExists = false
            }
        },
        async reload() {
            await this.setClasses()
            this.toggleKeys()
        },
    },
}
</script>

<style scoped>
.top-border {
    border-bottom: 0.2px solid #000;
}

.book-spot-header {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    /* font-size: 45px; */
    line-height: 32px;
    /* identical to box height, or 71% */

    display: flex;
    align-items: center;
}

.error-text {
    display: flex;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    color: red;
}

.class-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.class-error-text {
    font-family: Roboto;
    font-size: 24px;
    text-align: center;
}
</style>
