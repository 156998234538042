<template>
    <div>
        <v-btn
            color="primary"
            dark
            @click="navigateToOrderDetailsPage"
            depressed
        >
            {{ $t('message.viewOrderItems') }}
        </v-btn>
    </div>
</template>
<script>
export default {
    props: {
        order: Object,
    },
    data() {
        return {
            dialog: false,
        }
    },
    methods: {
        navigateToOrderDetailsPage() {
            const studioRoute = this.$route.params.studioRoute
            const hash = this.$route.hash
            this.$router.push(
                `/${studioRoute}/myorders/${this.order.id}${hash}`
            )
        },
    },
}
</script>
