<template>
    <div>
        <!-- Date boxes -->
        <DateBoxes :value="date" @input="onDateChange" />

        <!-- Table headers -->
        <v-layout row wrap class="d-none d-sm-flex">
            <v-flex>
                <v-card
                    class="pb-2 pt-2"
                    align-self-center="true"
                    flat
                    width="100vw"
                    min-height="5vh"
                >
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-card-text
                                    class="py-2 primary--text font-weight-bold"
                                >
                                    {{ $t('message.time') }}
                                </v-card-text>
                            </v-col>
                            <v-col>
                                <v-card-text
                                    class="py-2 primary--text font-weight-bold"
                                >
                                    {{ $t('message.class') }}
                                </v-card-text>
                            </v-col>
                            <v-col>
                                <v-card-text
                                    class="py-2 primary--text font-weight-bold"
                                >
                                    {{ $t('message.instructor') }}
                                </v-card-text>
                            </v-col>
                            <v-col>
                                <v-card-text
                                    class="py-2 primary--text font-weight-bold"
                                >
                                    {{ $t('message.location') }}
                                </v-card-text>
                            </v-col>
                            <!-- Dummy column for aesthetics purposes -->
                            <v-col></v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-flex>
            <br /><br />
        </v-layout>

        <!-- Table rows -->
        <div>
            <v-skeleton-loader
                type="card"
                v-if="isInitData"
            ></v-skeleton-loader>

            <v-layout row wrap class="d-none d-sm-flex">
                <class-view
                    :studio="studio"
                    :classes="filteredClasses"
                    :bookBtn="true"
                />
            </v-layout>
            <v-layout row wrap class="d-flex d-sm-none">
                <class-view
                    :studio="studio"
                    :classes="filteredClasses"
                    :bookBtn="true"
                    :isMobileView="true"
                />
            </v-layout>

            <v-skeleton-loader type="card" v-if="isFetchingData" />

            <p class="text-center py-5" v-if="errorInFetching">
                {{ $t('message.errorFetchingClasses') }}
            </p>
        </div>
    </div>
</template>
<script>
import ClassView from '@/components/browseclasses/ClassView.vue'
import DateBoxes from '@/components/browseclasses/DateBoxes.vue'
import { getClassesFromStudioWithoutFilters } from '@/util/cloudFunctions/classesFunctions'
import { getStudioLastPublicClass } from '@/util/classes/classes'
import {
    timeStringFromTimestamp,
    convertDateFormat,
} from '@/util/dateformat.js'
import {
    dateToStringWithTimezone,
    getNextDay,
    isBeforeNow,
    isDate1BeforeOrEqualDate2,
} from '@/util/datetimehelpers'

export default {
    props: {
        studio: Object,
        classFilter: Array,
        instructorFilter: Array,
        locationFilter: Array,
        date: String,
        todayDate: String,
    },
    components: {
        DateBoxes,
        ClassView,
    },
    beforeMount() {
        this.windowWidth = window.innerWidth
        window.addEventListener('scroll', this.handleScroll)
    },
    async created() {
        await this.setLastClassDate()
        await this.initClasses()
    },
    data() {
        return {
            classes: [], // { date: '7 January 2024,' item: [ {class1}, {class2} ], value: '2024-01-07' }

            lastClassDate: '', // to signify the end of the infinite scroll

            // Loading Data
            isInitData: true,
            isFetchingData: false,
            errorInFetching: false,

            // Listener for window width
            windowWidth: null,
        }
    },
    computed: {
        filteredClasses() {
            return this.classes.map((classItem) => {
                return {
                    date: classItem.date,
                    value: classItem.value,
                    item: classItem.item.filter((item) => {
                        if (
                            this.classFilter.length === 0 ||
                            this.classFilter.includes(item.name)
                        ) {
                            if (
                                this.instructorFilter.length === 0 ||
                                this.instructorFilter.includes(
                                    item.instructor.name
                                )
                            ) {
                                if (
                                    this.locationFilter.length === 0 ||
                                    this.locationFilter.includes(item.location)
                                ) {
                                    return true
                                }
                            }
                        }
                        return false
                    }),
                }
            })
        },
    },
    watch: {
        date: {
            handler: async function () {
                await this.initClasses()
            },
        },
    },
    methods: {
        async setLastClassDate() {
            const lastClass = await getStudioLastPublicClass(this.studio.id)
            if (!lastClass) {
                // this means the studio has no public classes, set to today
                this.lastClassDate = this.todayDate
                return
            }

            // if last class is before now, then set to today
            const lastClassDate = lastClass.startTimestamp.toDate()
            if (isBeforeNow(lastClassDate)) {
                this.lastClassDate = this.todayDate
                return
            }

            // set to last class date
            this.lastClassDate = dateToStringWithTimezone(
                lastClass.startTimestamp.toDate(),
                this.studio.timezone
            )
        },
        setTodayDate() {
            this.todayDate = dateToStringWithTimezone(
                new Date(),
                this.studio.timezone
            )
            this.date = this.todayDate
        },
        async onDateChange(event) {
            this.$emit('update:date', event)
        },
        async initClasses() {
            this.isInitData = true
            this.classes = []
            const result = await getClassesFromStudioWithoutFilters(
                this.studio.id,
                this.date,
                this.date
            )

            if (!result.success) {
                this.errorInFetching = true
                this.isInitData = false
                return
            }

            const formattedClasses = this.formatClasses(result.docs)
            this.classes = [
                {
                    date: convertDateFormat(this.date),
                    item: formattedClasses,
                    value: this.date,
                },
            ]
            this.isInitData = false
            await this.checkIfAtBottom()
        },
        async onEndOfPage() {
            if (this.isFetchingData || this.isInitData) {
                return
            }

            const lastDate = this.classes[this.classes.length - 1].value
            const newDate = getNextDay(lastDate)

            if (isDate1BeforeOrEqualDate2(this.lastClassDate, lastDate)) {
                return
            }

            this.isFetchingData = true
            const result = await getClassesFromStudioWithoutFilters(
                this.studio.id,
                newDate,
                newDate
            )
            if (!result.success) {
                this.errorInFetching = true
                this.isFetchingData = false
                return
            }

            const formattedClasses = this.formatClasses(result.docs)
            const newDateClasses = {
                date: convertDateFormat(newDate),
                item: formattedClasses,
                value: newDate,
            }
            this.classes.push(newDateClasses)
            this.isFetchingData = false

            await this.checkIfAtBottom()
        },
        formatClasses(classes) {
            return classes.map((classItem) => {
                classItem.timeStart = timeStringFromTimestamp(
                    classItem.startTimestamp,
                    this.studio.timezone
                )
                classItem.timeEnd = timeStringFromTimestamp(
                    classItem.endTimestamp,
                    this.studio.timezone
                )
                return classItem
            })
        },
        async handleScroll() {
            await this.checkIfAtBottom()
        },
        async checkIfAtBottom() {
            const scrollTop =
                window.scrollY || document.documentElement.scrollTop
            const windowHeight = window.innerHeight
            const documentHeight = document.documentElement.scrollHeight

            // Don't have to reach the actual bottom to start loading more classes
            const isAtBottom = scrollTop + windowHeight >= documentHeight - 200

            if (isAtBottom) {
                await this.onEndOfPage()
            }
        },
    },
    beforeDestroy() {
        if (typeof window === 'undefined') return
        window.removeEventListener('scroll', this.handleScroll)
    },
}
</script>
