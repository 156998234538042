<template>
    <v-col style="height: 100%" class="px-0">
        <h2 class="choose-spot-header mb-3">1. {{ $t('message.pickSpot') }}</h2>
        <v-chip class="mb-3" v-if="maxBookings > 1">
            You can book a maximum of {{ maxBookings }} spots
        </v-chip>
        <v-card :height="cardHeight">
            <v-container
                ref="SpotsContainer"
                style="position: relative"
                fill-height
            >
                <div
                    v-for="(spot, index) in spots"
                    :key="index"
                    :style="spotsStyle[index]"
                    class="d-flex flex-column align-start"
                >
                    <v-tooltip
                        bottom
                        :disabled="
                            !(
                                spot.isClasspassActive &&
                                hasAvailableNonClasspassSpot
                            )
                        "
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                x-small
                                v-on="on"
                                fab
                                @click="onSelectSpot(parseInt(index))"
                                :color="spotsColor[index]"
                                :class="getSpotClass(spot)"
                                :depressed="isSpotDisabled(spot)"
                            >
                                <div v-if="!spot.isInstructor">
                                    {{ index }}
                                </div>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.spotsWillOpen') }}</span>
                    </v-tooltip>
                    <p
                        class="text-caption spotsText"
                        style="z-index: 200; align-self: center"
                    >
                        {{ spotsText[index] }}
                    </p>
                </div>
            </v-container>
        </v-card>
    </v-col>
</template>
<script>
import { mapGetters } from 'vuex'
import { AUTHENTICATION_MODULE_NAME } from '@/store/moduleNames'

// Analytics
import { SELECT_SPOT } from '@/analytics/events'

export default {
    props: {
        spots: Array,
        height: Number,
        width: Number,
        maxBookings: Number,
    },
    mounted() {
        this.mountSpots()
        window.addEventListener('resize', this.mountSpots, { passive: true })
    },
    data() {
        return {
            spotsStyle: this.spots.map(() => ({})),
            selectedSpotIndexes: [],
            spotSize: 32,
        }
    },
    computed: {
        cardHeight() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 450
                case 'sm':
                case 'md':
                case 'lg':
                case 'xl':
                    return 550
            }
            return 550
        },
        halfSpotSize() {
            return this.spotSize / 2
        },
        ...mapGetters({
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
            user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
        }),
        spotsColor() {
            return this.spots.map((spot) => {
                if (spot.isInstructor) {
                    return '#00008b'
                }

                if (spot.isBlocked || spot.isOccupied) {
                    return 'primary'
                }

                if (this.isSpotSelected(spot)) {
                    return 'yellow'
                }

                if (
                    spot.isClasspassActive &&
                    this.hasAvailableNonClasspassSpot
                ) {
                    return '#cacaca'
                }

                return ''
            })
        },
        spotsText() {
            return this.spots.map((spot) => {
                if (spot.isInstructor) {
                    return this.$t('message.instructor')
                }

                if (spot.isBlocked) {
                    return this.$t('message.reserved')
                }

                if (spot.isOccupied) {
                    if (spot.cp_reservationId) {
                        return this.$t('message.reserved')
                    }

                    if (spot.familyUserId) {
                        return spot.familyUserFullName
                    }

                    if (this.$vuetify.breakpoint.name === 'xs') {
                        const breakPointChars = 6
                        if (spot.username.length > breakPointChars) {
                            return (
                                spot.username.substring(0, breakPointChars) +
                                '...'
                            )
                        }
                    }

                    return spot.username
                }

                if (this.isSpotSelected(spot)) {
                    if (this.isUserAuth) {
                        return this.user.username
                    }
                    return this.$t('message.selected')
                }

                return ''
            })
        },
        hasAvailableNonClasspassSpot() {
            const availableNonClasspassSpot = this.spots.find(
                (spot) =>
                    !spot.isInstructor &&
                    !spot.isBlocked &&
                    !spot.isOccupied &&
                    !spot.isClasspassActive
            )
            if (availableNonClasspassSpot) {
                return true
            }
            return false
        },
    },
    methods: {
        mountSpots() {
            const scaleFactor = 0.95 // Arbitrary scaling factor to prevent words from spilling out

            const containerHeight = this.$refs.SpotsContainer.clientHeight
            const containerWidth = this.$refs.SpotsContainer.clientWidth

            const spotsContainerHeight = containerHeight * scaleFactor
            const spotsContainerWidth = containerWidth * scaleFactor

            const heightDisplaced = (containerHeight - spotsContainerHeight) / 2
            const widthDisplaced = (containerWidth - spotsContainerWidth) / 2

            this.spotsStyle = this.spots.map((spot) => {
                const xAxis =
                    widthDisplaced +
                    (spot.x / this.width) * spotsContainerWidth -
                    this.halfSpotSize
                const yAxis =
                    heightDisplaced +
                    (spot.y / this.height) * spotsContainerHeight -
                    this.halfSpotSize
                return {
                    position: 'absolute',
                    left: `${xAxis}px`,
                    top: `${yAxis}px`,
                }
            })
        },
        isSpotSelected(spot) {
            const spotIndex = parseInt(this.spots.indexOf(spot))
            const isSelected =
                this.selectedSpotIndexes.indexOf(spotIndex) !== -1
            return isSelected
        },
        onSelectSpot(spotNumber) {
            if (
                this.spots[spotNumber].isClasspassActive &&
                this.hasAvailableNonClasspassSpot
            ) {
                this.selectedSpotIndex = null
                this.$emit('input', null)
                return
            }

            if (this.isSpotSelected(this.spots[spotNumber])) {
                this.selectedSpotIndexes = this.selectedSpotIndexes.filter(
                    (selectedNumber) => selectedNumber !== spotNumber
                )
            } else if (this.selectedSpotIndexes.length < this.maxBookings) {
                this.selectedSpotIndexes.push(spotNumber)
            } else {
                this.selectedSpotIndexes = [
                    ...this.selectedSpotIndexes.slice(1),
                    spotNumber,
                ]
            }
            this.$gtag.event(SELECT_SPOT)
            this.$emit('input', this.selectedSpotIndexes)
        },
        isSpotDisabled(spot) {
            if (spot.isInstructor || spot.isBlocked || spot.isOccupied) {
                return true
            }
            return false
        },
        getSpotClass(spot) {
            return this.isSpotDisabled(spot) ? 'disable-events' : ''
        },
    },
    beforeDestroy() {
        if (typeof window === 'undefined') return
        window.removeEventListener('resize', this.onResize, { passive: true })
    },
}
</script>
<style scoped>
.disable-events {
    pointer-events: none;
}

.choose-spot-header {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    /* font-size: 45px; */
    line-height: 32px;
    /* identical to box height, or 71% */

    display: flex;
    align-items: center;
}

.spot-index {
    margin: auto;
    font-weight: 700;
    font-size: 16px;
}
</style>
